import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'focus-languages',
    styleUrls: ['languages.component.scss'],
    template: `
    <div style="display: flex; flex-direction: row; padding-right: 10px;" class="downArrow" placement="top" container="body" [ngbPopover]="popContent">    
        <div *ngFor="let lang of langs">
            <img *ngIf="lang.active" src="{{lang.icon}}" 
                width="20px" height="14px" style="margin: 5px; cursor: pointer;"/>
        </div>
    </div>

    <ng-template #popContent>
        <div style="display: flex;">
            <div *ngFor="let lang of langs">
                <img *ngIf="!lang.active" (click)="changeLanguage(lang.value);langs = verifySelected(langs)" 
                    src="{{lang.icon}}" width="20px" height="14px" style="margin: 5px; cursor: pointer;"/>
            </div>
        </div>
    </ng-template>
    `,
  })
  export class LanguagesComponent {
    directoryFlags = "assets/images/flags/";

    langs = [
        { "value" : "ptbr", "icon": this.directoryFlags+"flag-br.png", "active": false },
        { "value" : "en", "icon": this.directoryFlags+"flag-en.png", "active": false },
        { "value" : "es", "icon": this.directoryFlags+"flag-es.png", "active": false }
    ];

    constructor(
        protected translateService: TranslateService
    ){
        this.verifySelected(this.langs);
    }

    changeLanguage(langSelected : string) {
        this.translateService.use(langSelected);
        localStorage.setItem('lang', langSelected);
        location.reload();
    }

    verifySelected(langs) {
        const localLang = localStorage.getItem('lang');         
        langs.forEach((lang) => { if(lang.value === localLang){ lang.active = true; }else{ lang.active = false; } });
        return langs;
    }
  }