import { Pipe, PipeTransform } from '@angular/core';
import { DataService }  from '../data/data.service';
import {AlarmType} from '../models';
@Pipe({
    name: 'alarmTypePicture',
    pure: true
})

export class AlarmTypePicturePipe implements PipeTransform {

  constructor(private dataService: DataService){
  }

  transform(id:number): string {
    let _result: string = "" 
    , _alarmType: AlarmType = this.dataService.getAlarmTypeById(id);

    if (_alarmType) _result = _alarmType.picture;

    return _result;
  }

}