import { Component } from '@angular/core';
import { AuthenticationService } from 'app/pages/login/authentication.service';
import { environment } from './../../../../environments/environment';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by" title="Token Expira em {{token}}">

      {{ companyName }} v24.07.11 - <b><a *ngIf="isGoawake" href="{{ urlCreareWebsite }}" target="_blank" title="{{date | date: 'd/M/yyyy HH:mm:ss'}}">{{ creareName }}</a></b> {{ currentYear }} |


     

    <a *ngIf="isGoawake" href="https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grkocmhf7oyf/b/GoAwakePublicObjects/o/manual-focus.pdf" target="_blank" style="color: #007bff">{{ 'GLOBAL.focus_manual' | translate }}</a>
    </span>
    <span class="d-flex flex-direction-row" style="float: right; position: relative;">
      <span style="font-size: 0.75em; color: #666; padding: 0.4em;">{{ 'GLOBAL.lang' | translate }}: </span>
      <focus-languages></focus-languages>
    </span>
  `,
})
export class FooterComponent {
  token: String;

  date: Date;
  currentYear: number;
  companyName: string;
  creareName: string;
  creareWebsite: string = environment.urlCreareWebsite;

  isGoawake = environment.isGoawake;

  constructor(protected authService: AuthenticationService) {
    this.date = new Date();
    this.currentYear = new Date().getFullYear();
    this.token = this.authService.getExpiration().format("DD/MM/YYYY HH:mm:ss");
    this.creareName = environment.creareName;

    this.loadCompanyName();
  }

  loadCompanyName() {
    const workspace = JSON.parse(window.localStorage.getItem('userWorkspace'));

    if(workspace) {
      this.companyName = (workspace.dealerName) ? workspace.dealerName : environment.companyName;
    }else{
      setTimeout(() => this.loadCompanyName(), 1000);
    }
  }
}
