import { Component, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'app/@core/data/users.service';
import { environment } from 'environments/environment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { NbAuthService } from './auth.service';
import { AuthenticationService } from './authentication.service';

@Component({
  selector: 'nb-login',
  styleUrls: ['./login.component.scss'],
  templateUrl: './login.component.html',
})
export class NbLoginComponent2 {

  langs = ['en', 'ptbr'];

  translation = {
    'ptbr': {
      "authentication": `Autenticação ${environment.companyName}`,
      "inform_your_username": `Informe o seu Usuário cadastrado no ${environment.companyName} ${environment.orFrotalog}`,
      "username": "Usuário",
      "user_required": "É necessário informar um Usuário",
      "password": "Senha",
      "password_required": "Senha requerida!",
      "authenticate": "Autenticar",
      "forgot_password": "Esqueci minha Senha",
      "lang": "Idioma:",
      "success":"Sucesso!",
      "error" : "Algo deu errado. Tente novamente!",
      "enter_your_username": "Informe seu usuário",
      "sent_password" : "Sua nova senha foi enviada para seu email",
      "couldnt_change_password" : "Sua senha não pode ser alterada"

    },
    'en': {
      "authentication": `${environment.companyName}`,
      "inform_your_username": `Enter your ${environment.companyName} User`,
      "username": "Username",
      "user_required": "Username required!",
      "password": "Password",
      "password_required": "Password required!",
      "authenticate": "Sign-in",
      "forgot_password": "Forgot your password?",
      "lang": "Language:",
      "success":"Success!",
      "error" : "Something wrong happened. Try again!",
      "enter_your_username": "Enter your username",
      "sent_password" : "Your new password has been sent to your email",
      "couldnt_change_password" : "Your password could not be changed"
    },
  }

  @ViewChild('resetPasswordModal') resetPasswordModal: ModalDirective;

  public subAuth: Subscription;
  redirectDelay: number = 0;
  showMessages: any = {};
  provider: string = '';

  errors: string[] = [];
  messages: string[] = [];
  user: any = {};
  submitted: boolean = false;
  disabledButtom: boolean = false;

  msgResetPassword: string;
  msgSuccess: string;
  lang;

  recaptchaToken: string;

  recaptchaSiteKey = environment.recaptchaSitekey;

protected config = {};

  constructor(
    protected authService: AuthenticationService,        
    protected service: NbAuthService,             
    protected router: Router,
    protected userService: UserService,
    private _renderer: Renderer2
  ){
    this.verifySelected(this.langs);

    window.location.href = `${environment.loginUrl}${this.authService.getOrigin()}?cc=true`;
  }

  //reCAPTCHA


  ngOnInit(){
    let script = this._renderer.createElement('script');
    script.defer = true;
    script.async = true;
    script.src = "https://www.google.com/recaptcha/api.js";
    this._renderer.appendChild(document.body, script);
  }

  resolved(token) {
    this.recaptchaToken = token;
  }

  ngOnDestroy(){
    if(this.subAuth) this.subAuth.unsubscribe();
  }

  verifySelected(langs) {
    let localLang; 
    try {
      localLang = localStorage.getItem('lang');
      } catch(e) {}
    this.lang = localLang && langs.indexOf(localLang) > -1 ? localLang : 'en';
}

  authenticationFocus() {
    this.errors = this.messages = [];
    this.submitted = true;

    this.authService.login(this.user.email, this.user.password, this.recaptchaToken).subscribe((result : any) => {
      this.submitted = false;

      if(result){
        this.messages = [this.translation[this.lang].success];       

        if(result.headers.get('Authorization')){
          this.authService.setSession(result);
          window.localStorage.setItem('notificatedAlerts', JSON.stringify([]));

          let urlRedirect = "/";

          if (result.headers.get('User-Must-Change-Password')) {
            urlRedirect = "/pages/user-change-password"
            window.localStorage.setItem('userMustChangePassword', 'true');
          }

          setTimeout(() => {
            return this.router.navigateByUrl(urlRedirect);
          }, 1000);
        }         
      }else{
        this.errors = [this.translation[this.lang].error];
      }    
    }, (error: any) => {
      this.submitted = false;
      this.user.email = null;
      this.user.password = null;
      this.recaptchaToken = null;
      this.errors = [error.message];            
    });
  }
  
  login(): void {
    this.errors = this.messages = [];
    this.submitted = true;

    let userLogin = {"login": this.user.email, "password": this.user.password};

    this.subAuth = this.service.authenticate(this.provider, userLogin).subscribe((result: any) => {          
          this.submitted = false;

          if(result){
            this.messages = [this.translation[this.lang].success];
            const redirect = result.getRedirect();

            if(redirect){
              console.log(redirect);
              setTimeout(() => {
                return this.router.navigateByUrl(redirect);
              }, this.redirectDelay);  
            }

          }else{
            this.errors = [this.translation[this.lang].error];
          }          
    }, (error: any) => {
      this.errors = [error];
      this.submitted = false;
    });
  }

  getConfigValue(key: string): any {
    return '';
  }

  showResetPassword() {
    this.resetPasswordModal.show();
    console.log('Solicitação de Resetar o Password');
  }

  sendResetPassword() {
    if(!this.user.email) {
      this.msgResetPassword = this.translation[this.lang].enter_your_username;
      setTimeout(() => this.msgResetPassword = null, 5000);
    }else{
      this.disabledButtom = true;
      this.userService
        .resetPasswordByLogin(this.user.email)
        .then(() => {
          this.disabledButtom = false;
          
          this.msgSuccess = this.translation[this.lang].sent_password;

          setTimeout(() => this.resetPasswordModal.hide(), 2000);
        })
        .catch(err => {
          this.disabledButtom = false;
          this.msgResetPassword = this.translation[this.lang].couldnt_change_password;
        });
    }
  }

}

/*
, (error)=> {

      this.notificationService.printErrorMessage('Falha na autenticação com o Frotalog. Verificar usuario/senha e se o Usuário esta ativo. ' );
      setTimeout(() => {
          this.submitted = false; 
          return this.router.navigateByUrl('/auth/login'); 
        }, 1000);
    } */
