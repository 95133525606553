import { Injectable } from '@angular/core';
import { Observable, fromEvent, merge, Subject, timer, Subscription } from 'rxjs';
import { environment } from "environments/environment";

@Injectable({
  providedIn: 'root'
})
export class IdleService {

  private idle$: Observable<Event>;
  private timer$: Subscription;
  private timeOutMilliSeconds: number;
  private idleSubscription: Subscription;

  public expired$: Subject<boolean> = new Subject<boolean>();

  constructor() { }

  public startWatching(timeOutMinutes: number = 30): Observable<boolean> {
    this.idle$ = merge(
      fromEvent(document, 'mousemove'),
      fromEvent(document, 'click'),
      fromEvent(document, 'mousedown'),
      fromEvent(document, 'keypress'),
      fromEvent(document, 'DOMMouseScroll'),
      fromEvent(document, 'mousewheel'),
      fromEvent(document, 'touchmove'),
      fromEvent(document, 'MSPointerMove'),
      fromEvent(window, 'mousemove'),
      fromEvent(window, 'resize'),
    );

    this.timeOutMilliSeconds = timeOutMinutes * 60 * 1000;

    this.idleSubscription = this.idle$.subscribe((res) => {
      this.resetTimer();
    });

    this.startTimer();

    return this.expired$;
  }

  private startTimer() {
    this.timer$ = timer(this.timeOutMilliSeconds, this.timeOutMilliSeconds).subscribe((res) => {
      this.expired$.next(true);
    });
  }

  public resetTimer() {
    this.timer$.unsubscribe();
    this.startTimer();
  }

  public stopTimer() {
    this.timer$.unsubscribe();
    this.idleSubscription.unsubscribe();
  }

  public isSkipPath(currentPath: string): boolean {

    const isSkip = environment.idleSkipPaths.some(path => path === currentPath);

    return isSkip;
  }
}