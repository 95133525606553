import { EventEmitter, Injectable } from "@angular/core";
import { Response, Headers, Http } from "@angular/http";
import { ConfigService } from "../config/config.service";
import { catchError, map } from "rxjs/operators";
import { AuthenticationService } from "app/pages/login/authentication.service";
import UserFilter from "app/@core/interfaces/UserFilter";
import { BehaviorSubject } from "rxjs";
import { FilterAlarms } from "app/@core/models";

@Injectable()
export class UserFilterService {
  private baseUrl: string;

  public event: BehaviorSubject<number[]> = new BehaviorSubject<number[]>(null);
  public defaultFilter: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);

  constructor(
    private http: Http,
    private configService: ConfigService,
    private auth: AuthenticationService
  ) {
    this.baseUrl = configService.getApiURI();
    this.getDefaultFilter()
  }



  async listUserFilters(): Promise<UserFilter[]> {
    const HEADERS = this.auth.getHttpHeaders();

    const URL_TO_FETCH =
      this.configService.webServices.getOrSaveListUserCustomerFilters.url(
        this.baseUrl
      );
    try {
      const res = await this.http
        .get(URL_TO_FETCH, { headers: HEADERS })
        .toPromise();
      return res.json();
    } catch (err) {
      return await Promise.reject(
        err.message ? 
          err.json() : 
          err.text() || "Server error"
      );
    }
  }

  async getDefaultFilter(): Promise<number[]> {
    const HEADERS = this.auth.getHttpHeaders();
    const URL_TO_FETCH =
      this.configService.webServices.getDefaultListUserFilterCustomerIdList.url(
        this.baseUrl
      );
    try {
      const res = await this.http
        .get(URL_TO_FETCH, { headers: HEADERS })
        .toPromise();
      const customersIds = res.json();
      this.defaultFilter.next(customersIds)

      return res.json();
    } catch (err) {
      return await Promise.reject(
        err.message ? err.json() : err.text() || "Server error"
      );
    }
  }

  async saveUserCustomerFilters(
    name: string,
    customerChildIds: number[],
    isDefault: boolean
  ): Promise<void> {
    const HEADERS = this.auth.getHttpHeaders();
    const URL_TO_FETCH =
      this.configService.webServices.getOrSaveListUserCustomerFilters.url(
        this.baseUrl
      );
    try {
      await this.http
        .post(
          URL_TO_FETCH,
          { name, customerChildIds, isDefault },
          { headers: HEADERS }
        )
        .toPromise();
        return await Promise.resolve()
    } catch (err) {
      return await Promise.reject(
        err.message ? err.json() : err.text() || "Server error"
      );
    }
  }

  async setUserFilterAsDefault(filterId: number): Promise<void> {
    const HEADERS = this.auth.getHttpHeaders();
    const URL_TO_FETCH = this.configService.webServices.userFilterById.url(
      this.baseUrl,
      filterId
    );
    try {
      await this.http.post(URL_TO_FETCH, {}, { headers: HEADERS }).toPromise();
    } catch (err) {
      return await Promise.reject(
        err.message ? err.json() : err.text() || "Server error"
      );
    }
  }

  async deleteUserFilter(filterId: number): Promise<void> {
    const HEADERS = this.auth.getHttpHeaders();
    const URL_TO_FETCH = this.configService.webServices.userFilterById.url(
      this.baseUrl,
      filterId
    );
    try {
      await this.http.delete(URL_TO_FETCH, { headers: HEADERS }).toPromise();
      return await Promise.resolve()
    } catch (err) {
      return await Promise.reject(
        err.message ? err.json() : err.text() || "Server error"
      );
    }
  }

  async setUserFilterCustomerIdsList(filterId: number): Promise<void> {
    const HEADERS = this.auth.getHttpHeaders();
    const URL_TO_FETCH = this.configService.webServices.userFilterById.url(
      this.baseUrl,
      filterId
    );
    try {
      const data = await this.http.get(URL_TO_FETCH, { headers: HEADERS }).toPromise();
      const customersIds = data.json();
      this.event.next(customersIds)
    } catch (err) {
      return await Promise.reject(
        err.message ? err.json() : err.text() || "Server error"
      );
    }
  }

  getLastUsedFilter(): FilterAlarms {
    const filterInStorage = JSON.parse(window.localStorage.getItem('filterLastUsed'));
    if (!filterInStorage) {
      return null  
    }
    const filter = new FilterAlarms();
    filter.selectedAlarmTypes = filterInStorage.selectedAlarmTypes
    filter.selectedCustomers = filterInStorage.selectedCustomers
    filter.selectedDrivers = filterInStorage.selectedDrivers
    filter.selectedMessages = filterInStorage.selectedMessages
    filter.selectedVehicles = filterInStorage.selectedVehicles
    filter.selectedDateEnd = new Date(filterInStorage.selectedDateEnd)
    filter.selectedDateStart = new Date(filterInStorage.selectedDateStart)
    return filter
  }

  saveLastUsedFilter(filter: FilterAlarms): FilterAlarms {
    window.localStorage.setItem('filterLastUsed', JSON.stringify(filter))
    return filter;
  }


  deleteLastUsedFilter() {
    window.localStorage.removeItem('filterLastUsed')
  }
}
