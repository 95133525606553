import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Title } from "@angular/platform-browser";
import {
  NbMenuService,
  NbSidebarService,
  NbMenuItem,
  NbThemeService,
} from "@nebular/theme";
import { AssetService } from "app/@core/data/asset.service";
import { TranslateJsonService } from "app/@core/data/translate-json.service";
import { AssetType } from "app/@core/enum/asset-type.enum";
import { EquipamentType } from "app/@core/enum/equipament.type.enum";
import { TelemetryService } from "app/pages/ui-alarms/telemetry/telemetry.service";
import { TelemetryState } from "app/pages/ui-alarms/telemetry/telemetry.state";
import { environment } from "environments/environment";
import { Subscription, forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import { DataService } from "../../../@core/data/data.service";
import { UserService } from "../../../@core/data/users.service";
import { FilterAlarms } from "../../../@core/models";
import { AnalyticsService } from "../../../@core/utils/analytics.service";

@Component({
  selector: "ngx-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit {
  @Input() position: string = "normal";
  @Output() changeToFullScreenOnTopbar: EventEmitter<any> = new EventEmitter()
  public subMenuService: Subscription;
  loadingEvents: boolean = false;
  currentTheme: string;
  themeSubscription: any;
  logoPicture: string;
  public filterSelectedData: FilterAlarms = new FilterAlarms();

  private intervalId;
  public eventsCount = 0;
  public hidePopupsEvents: boolean = false;

  user: any;

  loadedMenuUser: boolean = false;

  isFatigueVersion2: boolean = false;
  isGoawake = environment.isGoawake;

  userMenu: NbMenuItem[] = [];
  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private userService: UserService,
    private analyticsService: AnalyticsService,
    private themeService: NbThemeService,
    private dataService: DataService,
    private titleService: Title,
    private assetService: AssetService,
    private telemetryService: TelemetryService,
    private telemetryState: TelemetryState,
    protected translateJsonService: TranslateJsonService
  ) {
    this.themeSubscription = this.themeService
      .getJsTheme()
      .subscribe((theme) => {
        this.currentTheme = theme.name;
        this.userProfile();
      });

    this.isFatigueVersion2 =
      window.localStorage.getItem("version-fatigue-page") == "2" ? true : false;

    if (this.isGoawake) {
      setTimeout(() => {
        this.loadEvents();
      }, 5000);

      this.intervalId = setInterval(() => {
        this.loadEvents();
      }, 30000);
    }
  }

  ngOnDestroy() {
    this.themeSubscription.unsubscribe();
    if (this.subMenuService) this.subMenuService.unsubscribe();

    clearInterval(this.intervalId);
  }

  ngOnInit() {
    this.setLoginName();
  }

  private setLoginName() {
    this.userService.getUsers().subscribe(async (users: any) => {
      const menuItems = [
        {
          index: 0,
          key: "HEADER.change_password",
          props: {
            data: {
              action: 'header-change-password'
            }
          }
        },
        {
          index: 1,
          key: "HEADER.logout",
          props: {
            data: {
              action: 'header-logout'
            }
          }
        }
      ];
      
      const observables = menuItems.map(item =>
        this.translateJsonService.subGetTranslateSubs(item.key).pipe(
          map(text => {
            const menuItem = {
              title: text
            };
      
            Object.assign(menuItem, item.props);
      
            return menuItem;
          })
        )
      );
      
      forkJoin(observables).subscribe(menuItems => {
        menuItems.forEach((menuItem, index) => {
          this.userMenu[index] = menuItem;
        });
        
        this.loadedMenuUser = true;
      });
      
      this.user = users.creare;

      if (this.user.name == environment.userNameCreare) {
        setTimeout(() => {
          this.setLoginName();
        }, 1000);
      }
    });
  }

  sendFullScreenModeOn(){
    this.changeToFullScreenOnTopbar.emit(true)
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, "menu-sidebar");
    return false;
  }

  toggleSettings(): boolean {
    this.sidebarService.toggle(false, "settings-sidebar");
    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  startSearch() {
    this.analyticsService.trackEvent("startSearch");
  }

  public userProfile() {
    const workspace = JSON.parse(window.localStorage.getItem("userWorkspace"));

    if (workspace) {
      this.logoPicture = workspace.dealerLogo
        ? workspace.dealerLogo
        : environment.logo;

      workspace.dealerName
        ? this.titleService.setTitle(`Painel | ${workspace.dealerName}`)
        : "";
    } else {
      setTimeout(() => this.userProfile(), 1000);
    }
  }

  load() {
    this.hidePopupsEvents = !this.hidePopupsEvents;
  }

  private loadEvents() {
    this.loadingEvents = true;
    const sevenDaysInMilisSeconds = 604800000;
    const dateNow = new Date();
    const dateStart = dateNow.getTime() - sevenDaysInMilisSeconds;
    const customersChildId = this.dataService
      .getCustomersIdFromPermissions();
    const displayReaded = false;
    const displayAllTypes = false;

            this.telemetryService
          .getEvents(
            customersChildId.map(id => id.toString()),
            [],
            [],
            new Date(dateStart),
            dateNow,
            displayReaded,
            displayAllTypes
          )
          .then((result) => {
            this.telemetryState.updateEvents(result);
            this.eventsCount = result.length;
            this.loadingEvents = false;
          });
     }
}
