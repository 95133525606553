/**
 * Reviewed By Rafael
 * 03-12-18
 */
import {throwError as observableThrowError, Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Response, Http, Headers } from '@angular/http';

import { ConfigService } from '../config/config.service';
import { Equipament } from '../models';
import { AuthenticationService } from 'app/pages/login/authentication.service';
import { EquipmentStreamingDto } from 'app/pages/ui-alarms/streaming-page/EquipmentStreaming.dto';

@Injectable()
export class EquipmentService {
    private baseUrl;

    constructor(
        private http: Http,
        private configService: ConfigService,
        protected auth: AuthenticationService
    ) {
      this.baseUrl = configService.getApiURI();
    }

    /**
     * Salva um novo Equipamento
     * @param equip Objeto equipamento a ser criado
     */
    public saveNewEquipment(equip: Equipament): Observable<any> {
        let _ws = this.configService.webServices['saveNewEquipment'];

        let string = JSON.stringify({
            "name": equip.name,
            "identification": equip.identification,
            "integration": equip.integration,
            "assetId": equip.assetId,
            "type": equip.type,
            "customerChildId": equip.customerChildId,
            'firmware': equip.firmware,
            "devices": equip.devices.map((device)=>{return device.id})
        });

        return this.http.post(_ws.url(this.baseUrl), string, {headers: this.getHttpHeaders()}).pipe(
            map((res: Response) => {
                res.text();
            })
        );
    }

    /**
     * Salva as edições de um Equipamento
     * @param equip Objeto equipamento a ser Salvo
     */
    public saveEquipment(equip: Equipament): Observable<any> {
        let _ws = this.configService.webServices['saveEquipment'];

        let string = JSON.stringify({
            "name": equip.name,
            "identification": equip.identification,
            "integration": equip.integration,
            "assetId": equip.assetId,
            "type": equip.type,
            "customerChildId": equip.customerChildId,
            'firmware': equip.firmware,
            "devices": equip.devices.map((device)=>{return device.id})
        });

        return this.http.put(_ws.url(this.baseUrl, equip.id), string, {headers: this.getHttpHeaders()}).pipe(
            map((res: Response) => {
                return res.text();
            }),
            catchError(this.handleError),
        );
    }

    /**
     * Desabilita um Equipamento
     * @param equipId Id do equipamento a ser desabilitado
     */
    public disableEquipment(equipId: number): Observable<any>{
        let _ws = this.configService.webServices['disableEquipmentById'];

        return this.http.get(_ws.url(this.baseUrl, equipId), {headers: this.getHttpHeaders()}).pipe(
            map((res: Response) => {
                return res.text();
            }),
            catchError(this.handleError),
        );
    }

    /**
     * Retorna os Equipamentos ativos das Filiais informadas
     * @param customerChilds Filiais
     */
    public getAllEquipmentsByCustomerChilds(customerChilds : number[]): Observable<Equipament[]> {
        let _ws = this.configService.webServices['equipmentsByCustomersChildIds'];

        return this.http.get(_ws.url(this.baseUrl, customerChilds.join(',')), {headers: this.getHttpHeaders()}).pipe(
            map((res: Response) => {
                return res.json();
            }),
            catchError(this.handleError),
        );
    }

    public getAllEquipmentsByCustomersChildV2(customersChild: number[]): Observable<Equipament[]> {
        let _ws = this.configService.webServices['equipmentsByCustomersChildId'];

        return this.http.get(_ws.url(this.baseUrl, customersChild.join(',')), {headers: this.getHttpHeaders()}).pipe(
            map((res: Response) => {
                return res.json();
            }),
            catchError(this.handleError),
        );
    }
    public getEquipmentsByAsset(assetId : number): Observable<Equipament[]> {
        let _ws = this.configService.webServices['equipmentsByAsset'];

        return this.http.get(_ws.url(this.baseUrl, assetId), {headers: this.getHttpHeaders()}).pipe(
            map((res: Response) => {
                return res.json();
            }),
            catchError(this.handleError),
        );
    }

    private handleError(error: any) {
        console.log(error.message)

        let errorMsg = (error.message) ? error.json().message : error.text();
        return observableThrowError(errorMsg);
    }

    getHttpHeaders() : Headers {
        let headers = new Headers();

        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', this.auth.getToken());

        return headers;
    }

    // Listar Todos Equipamentos de uma lista de Customers
    public getAllEquipment(): Observable<Equipament[]> {
        let _ws = this.configService.webServices['equipments'];

        return this.http.get(_ws.url(this.baseUrl)).pipe(
            map((res: Response) => {
                return res.json();
            }),
            catchError(this.handleError),
        );
    }


    fetchEquipmentsByCustomersChild(customersChild: number[]): Promise<Equipament[]> {
        const URL_TO_FETCH = this.configService.webServices['equipmentsByCustomersChildIds'].url(this.baseUrl, customersChild.join(','));
        const HEADERS = this.auth.getHttpHeaders();

        return this.http.get(URL_TO_FETCH, {headers : HEADERS})
            .toPromise()
            .then(res => res.json())
            .catch(err => {
                return Promise.reject((err.message) ? err.json() : err.text()  || 'Server error');
            })
    }

    deleteEquipment(equipId: number): Promise<string> {
        const URL_TO_FETCH = this.configService.webServicesV2['deleteEquipment'].url(this.baseUrl, equipId);
        const HEADERS = this.auth.getHttpHeaders();

        return this.http.delete(URL_TO_FETCH, {headers : HEADERS})
            .toPromise()
            .then(res => res.text())
            .catch(err => {
                return Promise.reject(err.text());
            })
    }

    getStreamingsByVehicleId(vehiclesId: number[]): Promise<EquipmentStreamingDto[]> {
        const URL_TO_FETCH = this.configService.webServicesV2['getStreamingsByVehicleId'].url(this.baseUrl, vehiclesId);
        const HEADERS = this.auth.getHttpHeaders();

        return this.http.get(URL_TO_FETCH, { headers: HEADERS })
            .toPromise()
            .then(res => res.json())
            .catch(err => {
                return Promise.reject((err.message) ? err.json() : err.text()  || 'Server error');
            })
    }
}