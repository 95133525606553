import { Pipe, PipeTransform } from '@angular/core';
import { DataService }  from '../data/data.service';
import {Driver} from '../models';
@Pipe({
    name: 'driverPicture',
    pure: true
})

export class DriverPicturePipe implements PipeTransform {

  constructor(private dataService: DataService){
  }

  transform(id:number): string {
    let _result: string = "" 
    , _driver: Driver = this.dataService.getDriverById(id);

    if (_driver) {
      _result = _driver.picture;
      if (!_result) _result = _driver.icon;
    }
      

    return _result;
  }




}