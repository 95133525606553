import {throwError as observableThrowError,  Observable, BehaviorSubject } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs-compat/Subject';
import { Response, Http, Headers } from '@angular/http';

import { ConfigService } from '../config/config.service';
import { WorkspaceProfileData, User } from '../models';
import { Driver } from '../models/driver.entity';
import { Workspace } from '../models/workspace.data.entity';
import { AuthenticationService } from 'app/pages/login/authentication.service';

@Injectable()
export class WorkspaceService {

  private workspace: Workspace;
  private workspaceProfile: WorkspaceProfileData;
  private baseUrl;
  currentUserId
  
  public botaoFiltrar = new Subject<boolean>();
  public filtroCarregado = new Subject<boolean>();
  public botaoOnline = new BehaviorSubject<boolean>(false);

  constructor(
    private http: Http, 
    private configService: ConfigService,
    protected auth: AuthenticationService
  ) { 
      this.baseUrl = configService.getApiURI();      
  }

  getWorkspaceByLogin(userLogin : string):  Observable<Workspace> {
    let _ws = this.configService.webServices['workspaceByLogin'];

    return this.http.get(_ws.url(this.baseUrl, userLogin), {headers: this.getHttpHeaders()}).pipe(
      map((res: Response) => {                       
        return res.json();
      }),
      catchError(this.handleError),); 
  }

  // Caso seja TRUE, chama a função Filtrar da rota atual
  public filtrarData(valor: boolean){
    this.botaoFiltrar.next(valor);
  }

  public setFilterLoaded(value: boolean){
    this.filtroCarregado.next(value);
  }

  public setBotaoOnline(value: boolean){
    this.botaoOnline.next(value);
  }

  public getBotaoOnline$(): Observable<boolean> {
    return this.botaoOnline.asObservable();
  }

  public getBotaoOnline(): boolean {
    return this.botaoOnline.getValue();
  }

  public currentUser(): User {
    let user: User = JSON.parse(window.localStorage.getItem('currentUser'));  
    if (user) this.currentUserId = user.id;
    return user;
  }

  public getCurrentWorkspace():  Workspace  {
    if (!this.workspaceProfile) {
      this.getWorkspace().subscribe((workspace: Workspace) => {
          this.workspace = workspace;
          this.workspace = workspace;
      });
    }    
    return this.workspace;
  }

  public getWorkspace():  Observable<Workspace>  {          
    let _ws = this.configService.webServices['workspace'];    

    return this.http.get(_ws.url(this.baseUrl, window.localStorage.getItem('currentUser')), {headers: this.getHttpHeaders()}).pipe(
      map((res: Response) => {
          return res.json();
      }),
      catchError(this.handleError),); 
  }
  
  private handleError(error: any) {
    let errMsg = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error('Erro:', error);
    return observableThrowError(errMsg);
  }

  public setSelectedDrivers(selectedDrivers: Driver[]){
    this.workspaceProfile.selectedDrivers = selectedDrivers;
  }

  public getSelectedDrivers() {
    return this.workspaceProfile.selectedDrivers;
  }

  getHttpHeaders() : Headers {
    let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', this.auth.getToken());

    return headers;
  }
}
