import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { LoadingAnimateService } from './ngx-loading-animate.service';

@Component({
    selector: 'loading-animate',
    template: `
        <div [@loadingState]="loadingState">
        <div class="animated yt-loader"></div>
        </div>
    `,
    styles: [`
    
    .animated {
        -webkit-animation-duration: 20s;
        animation-duration: 20s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -moz-user-select: none;
        -ms-user-select: none;
        -webkit-user-select: none;
    }
    
    .yt-loader {
        -webkit-animation-name: horizontalProgressBar;
        animation-name: horizontalProgressBar;
        -webkit-animation-timing-function: ease;
        animation-timing-function: ease;
        background: #2e57a5;
        height: 3px;
        left: 0;
        position: fixed;
        top: 0;
        width: 0%;
        z-index: 9999;
    }
    
    @keyframes horizontalProgressBar
    {
        0%   {width: 0%;}
        25%  {width: 22%;}
        50%  {width: 55%;}
        75%  {width: 83%;}
        100% {width:100%;}
    }
    
    @-webkit-keyframes horizontalProgressBar /* Safari and Chrome */
    {
        0%   {width: 0%;}
        25%  {width: 22%;}
        50%  {width: 55%;}
        75%  {width: 83%;}
        100% {width:100%;}
    }


        .loaders {
            display: block;
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;            
            background-color:rgba(0, 0, 0, 0.5);            
            text-align: center;
            z-index: 500;
        }
        .loader .loading {
            position: relative;
            height: 100%;
        }
        .loader .loading-inner {
            position: relative;
            top: 50%;
            margin-top: -40px;
        }
        .loader .loading small {
            font-size: 10px;
            font-weight: bold;
        }
        .sk-cube-grid {
            width: 150px;
            height: 150px;
            margin: 0 auto 10px;
        }
        .sk-cube-grid .sk-cube {
            width: 33%;
            height: 33%;
            background-color: #40dc7e;
            float: left;
            -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
            animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
        }
        .sk-cube-grid .sk-cube1 {
            -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
        }
        .sk-cube-grid .sk-cube2 {
            -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s;
        }
        .sk-cube-grid .sk-cube3 {
            -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
        }
        .sk-cube-grid .sk-cube4 {
            -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s;
        }
        .sk-cube-grid .sk-cube5 {
            -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
        }
        .sk-cube-grid .sk-cube6 {
            -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s;
        }
        .sk-cube-grid .sk-cube7 {
            -webkit-animation-delay: 0s;
            animation-delay: 0s;
        }
        .sk-cube-grid .sk-cube8 {
            -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s;
        }
        .sk-cube-grid .sk-cube9 {
            -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
        }
        @-webkit-keyframes sk-cubeGridScaleDelay {
            0%, 70%, 100% {
                -webkit-transform: scale3D(1, 1, 1);
                transform: scale3D(1, 1, 1);
            }
            35% {
                -webkit-transform: scale3D(0, 0, 1);
                transform: scale3D(0, 0, 1);
            }
        }
        @keyframes sk-cubeGridScaleDelay {
            0%, 70%, 100% {
                -webkit-transform: scale3D(1, 1, 1);
                transform: scale3D(1, 1, 1);
            }
            35% {
                -webkit-transform: scale3D(0, 0, 1);
                transform: scale3D(0, 0, 1);
            }
        }
    `],
    animations: [
        trigger('loadingState', [
            state( 'inactive', style({
                opacity: 0,
                display: 'none'
            })),
            state('active',   style({
                opacity: 1,
            })),
            transition('active => inactive', animate('250ms linear')),
            //transition('active => inactive', animate('200ms ease-out')),
            //transition('inactive => active', animate('0ms ease-in'))
        ])
    ]
})
//Use the easing value in the timing: animate(250, ...) -> animate('250ms linear', ...)
export class LoadingAnimateComponent implements OnInit {

    loadingState: string = 'inactive';

    constructor(private _loadingSvc: LoadingAnimateService) {}

    ngOnInit(): void {
        this._loadingSvc.getValue().subscribe( (status: boolean) => {
            this.loadingState = status ? 'active' : 'inactive';
        });
    }
}
