
import {throwError as observableThrowError, of as observableOf,  Observable } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Response, Headers, Http } from '@angular/http';

import { ConfigService } from '../config/config.service';
import { AuthenticationService } from 'app/pages/login/authentication.service';
import { AssetCustomerContact } from '../models/assetCustomerContact.entity';

@Injectable()
export class CustomerContactService {

  private baseUrl;

  constructor(private http: Http,
    private configService: ConfigService,
    private auth: AuthenticationService
    ) {
      this.baseUrl = configService.getApiURI();
  }

  public getCustomerContactsByAssetId(assetId: number):  Observable<AssetCustomerContact[]> {
    let _ws = this.configService.webServices['customerContactsByAssetId'];
    return this.http.get(_ws.url(this.baseUrl, assetId), {headers: this.getHttpHeaders()}).pipe(
      map((res: Response) => {
          return res.json();
      }),
      catchError(this.handleError),);
  }

  public getCustomerContactsByCustomerChildId(customerChildId: number):  Observable<AssetCustomerContact[]> {
    let _ws = this.configService.webServices['customerContactsByCustomerChildId'];
    return this.http.get(_ws.url(this.baseUrl, customerChildId), {headers: this.getHttpHeaders()}).pipe(
      map((res: Response) => {
          return res.json();
      }),
      catchError(this.handleError),);
  }

  private handleError(error: any) {
    let errMsg = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error('Erro:', error);
    return observableThrowError(errMsg);
  }

  getHttpHeaders() : Headers {
    let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', this.auth.getToken());

    return headers;
  }
}
