import { Injectable } from "@angular/core";
import { Alarm } from "../models";
import { ModelMarkers } from "app/pages/components/leaflet-map/marker-model";

@Injectable()
export class MapUtilService {
    
    /**
     * Recebe lista de alarmes e converte para o formato Model Makers
     * @param alarmList Lista de Alarmes
     */
    public alarmToModelMakerList(alarmList : any) : ModelMarkers[] {
        let modelMakerList: ModelMarkers[] = [];


        alarmList.forEach((alarm : any) => {

            if(alarm.infractionData) {
                let mrkr: ModelMarkers = new ModelMarkers;
                mrkr.id = alarm.id;
                mrkr.name = alarm.infractionData.driver? alarm.infractionData.driver.driverFirstName : null;
                mrkr.category = this.convertInfractionCategoryType(alarm.infractionTypeId);
                mrkr.enabled = true;
                mrkr.latitude = parseFloat(alarm.latitude);
                mrkr.longitude = parseFloat(alarm.longitude);
    
                modelMakerList.push(mrkr);
            } else {
                let mrkr: ModelMarkers = new ModelMarkers;
                mrkr.id = alarm.id;
                mrkr.name = alarm.driver_firstname;
                mrkr.category = alarm.typeAlarm;
                mrkr.enabled = true;
                mrkr.latitude = alarm.latitude;
                mrkr.longitude = alarm.longitude;
    
                modelMakerList.push(mrkr);
            }
        })

        return modelMakerList;
    }

    convertInfractionCategoryType(infractionTypeId) {
        const SPEED_INFRACTION = 1
        const NO_SEATBELT_INFRACTION = 4
        const ABRUPT_BREAK_INFRACTION = 6
        const ABRUPT_SPEED_INFRACTION = 7
        if(infractionTypeId === SPEED_INFRACTION) return 3001
        if(infractionTypeId === NO_SEATBELT_INFRACTION) return 3004
        if(infractionTypeId === ABRUPT_BREAK_INFRACTION) return 3006
        if(infractionTypeId === ABRUPT_SPEED_INFRACTION) return 3007
        
    }
}