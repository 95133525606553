export enum EquipamentType {
    FATIGUE = 1,
    STONKAM = 2,
    JIMI = 3,
    JIMI_G7 = 4,
    SPOTX = 6,
    WEARABLE = 5,
    DRIVER_HERO = 9,
    JIMI_V6 = 11,
    STONKAM_K4 = 12,	
    STONKAM_K1_PLUS = 13,
    HIKVISION = 10,
    JIMI_G5_PLUS=14
}
export const EquipmentTypeLabels = {
    [EquipamentType.FATIGUE]: 'G3',
    [EquipamentType.STONKAM]: 'K1',
    [EquipamentType.JIMI]: 'G5',
    [EquipamentType.JIMI_G7]: 'G7',
    [EquipamentType.SPOTX]: 'SPOTX',
    [EquipamentType.WEARABLE]: 'WEARABLE',
    [EquipamentType.DRIVER_HERO]: 'DRIVER HERO',
    [EquipamentType.JIMI_V6]: 'G5',
    [EquipamentType.STONKAM_K4]: 'K4',
    [EquipamentType.STONKAM_K1_PLUS]: 'K1 PLUS',
    [EquipamentType.HIKVISION]: 'HIKVISION',
    [EquipamentType.JIMI_G5_PLUS]: 'G5 PLUS'
}
export const FNVEquipmentTypeLabels = {
    [EquipamentType.FATIGUE]: 'G3',
    [EquipamentType.STONKAM]: 'STONKAM',
    [EquipamentType.JIMI]: 'JC400',
    [EquipamentType.JIMI_G7]: 'JC450',
    [EquipamentType.SPOTX]: 'SPOTX',
    [EquipamentType.WEARABLE]: 'WEARABLE',
    [EquipamentType.DRIVER_HERO]: 'DRIVER HERO',
    [EquipamentType.JIMI_V6]: 'JC400',
    [EquipamentType.STONKAM_K4]: 'K4',
    [EquipamentType.STONKAM_K1_PLUS]: 'K1 PLUS',
    [EquipamentType.HIKVISION]: 'HIKVISION',
    [EquipamentType.JIMI_G5_PLUS]: 'JC400AD'
};
