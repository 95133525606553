import { Pipe, PipeTransform } from '@angular/core';
import { DataService }  from '../data/data.service';
import { Customer } from '../models';
@Pipe({
    name: 'customerPicture',
    pure: true
})

export class CustomerPicturePipe implements PipeTransform {

  constructor(private dataService: DataService){
  }

  transform(id:number): string {    
    let _result: string = "" 
    , _customer: Customer = this.dataService.getCustomerById(id);

    if (_customer) {
      _result = _customer.picture;

      if (!_result) _result = _customer.icon;
    }

    

    return _result;
  }




}