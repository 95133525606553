import { Component, OnDestroy } from '@angular/core';
import {User} from '../../../@core/models';
import {
  NbMediaBreakpoint,
  NbMediaBreakpointsService,
  NbMenuItem,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from '@nebular/theme';
import { Router } from '@angular/router';

import { StateService } from '../../../@core/data/state.service';

import { Subscription } from 'rxjs';

import { delay, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'ngx-sample-layout',
  styleUrls: ['./sample.layout.scss'],
  template: `
    <nb-layout [center]="layout.id === 'center-column'" windowMode>
      <nb-layout-header style="background: #f0ad4e;">
          <ngx-header (changeToFullScreenOnTopbar)="setFullScreenMode($event)" *ngIf="!isMaximized" [position]="sidebar.id === 'left' ? 'normal': 'inverse'"></ngx-header>
      </nb-layout-header>

      <nb-sidebar *ngIf="!isMaximized" class="menu-sidebar"
                   tag="menu-sidebar"
                   responsive
                   state="expanded"
                   [right]="sidebar.id === 'right'">
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>
      
      <nb-layout-column class="main-content" >
        <div *ngIf="isMaximized" class="full-screen-collapsed-menu d-flex justify-content-center" [ngClass]="{'full-screen-expanded-menu' : !isCollapsed}">
          <div class="toggle-full-screen-menu d-flex justify-content-center align-items-start"  (click)="expandMenu(isCollapsed)">
            <div *ngIf="!isCollapsed" class="option-control">
              <button class="minimize-screen-button mt-3" (click)="maximizeScreen()" pTooltip="{{ 'GLOBAL.restore' | translate }}" tooltipPosition="bottom">
                <img src="./assets/minimize_full_screen_icon.svg">
              </button>
              <img src="./assets/collapse_fullscreen_menu_icon.svg">
            </div>
              <img *ngIf="isCollapsed" src="./assets/expand_fullscreen_menu_icon.svg">
          </div>
        </div>
        
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-column left class="small" *ngIf="layout.id === 'two-column' || layout.id === 'three-column'">
        <nb-menu [items]="subMenu"></nb-menu>
      </nb-layout-column>

      <nb-layout-column right class="small" *ngIf="layout.id === 'three-column'">
        <nb-menu [items]="subMenu"></nb-menu>
      </nb-layout-column>

      <nb-layout-footer *ngIf="!isMaximized" fixed>
        <ngx-footer></ngx-footer>
      </nb-layout-footer>

      <nb-sidebar class="settings-sidebar"
                   tag="settings-sidebar"
                   state="collapsed"
                   fixed
                   [right]="sidebar.id !== 'right'">
        <ngx-theme-switcher></ngx-theme-switcher>
        <ngx-theme-settings></ngx-theme-settings>
      </nb-sidebar>
    </nb-layout>
  `,
})
export class SampleLayoutComponent  implements OnDestroy {

  subMenu: NbMenuItem[] = [
    {
      title: 'PAGE LEVEL MENU',
      group: true,
    },
    {
      title: 'Buttons',
      icon: 'ion ion-android-radio-button-off',
      link: '/pages/ui-features/buttons',
    },
    {
      title: 'Grid',
      icon: 'ion ion-android-radio-button-off',
      link: '/pages/ui-features/grid',
    },
    {
      title: 'Icons',
      icon: 'ion ion-android-radio-button-off',
      link: '/pages/ui-features/icons',
    },
    {
      title: 'Modals',
      icon: 'ion ion-android-radio-button-off',
      link: '/pages/ui-features/modals',
    },
    {
      title: 'Typography',
      icon: 'ion ion-android-radio-button-off',
      link: '/pages/ui-features/typography',
    },
    {
      title: 'Animated Searches',
      icon: 'ion ion-android-radio-button-off',
      link: '/pages/ui-features/search-fields',
    },
    {
      title: 'Tabs',
      icon: 'ion ion-android-radio-button-off',
      link: '/pages/ui-features/tabs',
    },
  ];
  layout: any = {};
  sidebar: any = {};
  logoPicture: string;
  isMaximized: boolean = false
  isCollapsed: boolean = true

  protected layoutState$: Subscription;
  protected sidebarState$: Subscription;
  protected menuClick$: Subscription;

  constructor(private router: Router,
              protected stateService: StateService,
              protected menuService: NbMenuService,
              protected themeService: NbThemeService,
              protected bpService: NbMediaBreakpointsService,
              protected sidebarService: NbSidebarService) {
    this.layoutState$ = this.stateService.onLayoutState()
      .subscribe((layout: string) => this.layout = layout);

    this.sidebarState$ = this.stateService.onSidebarState()
      .subscribe((sidebar: string) => {
        this.sidebar = sidebar
      });

    const isBp = this.bpService.getByName('is');
    this.menuClick$ = this.menuService.onItemSelect()
      .pipe(
        withLatestFrom(this.themeService.onMediaQueryChange()),
        delay(20),
      )
      .subscribe(([item, [bpFrom, bpTo]]: [any, [NbMediaBreakpoint, NbMediaBreakpoint]]) => {
        if(JSON.parse(window.localStorage.getItem('currentUser')).isMustChangePassword){
          this.router.navigateByUrl('/pages/user-change-password');
        }
        if (bpTo.width <= isBp.width) {
          this.sidebarService.collapse('menu-sidebar');
        }
      });
  }

  ngOnInit() {
    if(JSON.parse(window.localStorage.getItem('currentUser')).isMustChangePassword){
      this.router.navigateByUrl('/pages/user-change-password');
    }
  }
  ngOnDestroy() {
    this.layoutState$.unsubscribe();
    this.sidebarState$.unsubscribe();
    this.menuClick$.unsubscribe();
  }

  setFullScreenMode(fullScreen) {
    if(fullScreen) this.maximizeScreen()
  }

  expandMenu(isCollapsed) {
    this.isCollapsed = !isCollapsed
  }

  maximizeScreen() {
    this.isMaximized = !this.isMaximized
    if(this.isMaximized) this.isCollapsed = true
  }

  public userProfile() {
    let user: User = JSON.parse(window.localStorage.getItem('currentUser'));     
    if (user) {
      this.logoPicture = user.picture;  
    }
  }
}
