import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslateJsonService } from "app/@core/data/translate-json.service";
import { UserFilterService } from "app/@core/data/user-filter.service";
import { AssetStatus } from "app/@core/models/assetStatus";
import { Subscription } from "rxjs";
import { AssetService } from "../../../@core/data/asset.service";
import { CustomerService } from "../../../@core/data/customer.service";
import { DataService } from "../../../@core/data/data.service";
import { DriverService } from "../../../@core/data/driver.service";
import { WorkspaceService } from "../../../@core/data/workspace.service";
import { Asset, Driver, FilterAlarms } from "../../../@core/models";
import { CustomerChild } from "../../../@core/models/customerChild.entity";
import { TreeInputModel } from "../../../@core/models/treeInputModel.entity";
import { customerChildsPermissions, Workspace } from "../../../@core/models/workspace.data.entity";
import { NotificationUtilService } from "../../../@core/utils/notification.util.service";
import { environment } from 'environments/environment';

@Component({
    selector: 'focus-filter-v2',
    styleUrls: ['./filter-v2.component.scss'],
    templateUrl: './filter-v2.component.html',
})

export class FilterV2Component implements OnInit {

    // Subscriptions
    private subsworkspace: Subscription;
    private subsonline: Subscription;
    private subscustomers: Subscription;
    private subsdrivers: Subscription;
    private subsvehicles: Subscription;

    // Filters e Workspace
    public filterSelectedData: FilterAlarms = new FilterAlarms();
    private workspace: Workspace;
    ptBr;
    en;
    es;

    // Selecteds
    public selectedRangeDates: Date[];

    // Customers
    public selectedCustomers: number[];
    public customers : TreeInputModel[] = [];
    public loadedCustomers : boolean;
    public changingSelectedCustomers: number = 0;

    public customerIdByWorkspace: any;
    public childIdByWorkspace: any;
    public userFilterCustomByCustomer: boolean;
    public userFilterCustomByChild: boolean;
    public isOnlineChecked: boolean;
    
    // Drivers
    public selectedDrivers: number[];
    public drivers: TreeInputModel[] = [];
    public loadedDrivers: boolean = false;
    public pendingDrivers: boolean = true;

    // Veiculos
    public selectedVehicles: number[];
    public vehicles: TreeInputModel[] = [];
    public loadedVehicles: boolean = false;
    public pendingVehicles: boolean = true;

    // Periodo
    public dateMax: Date = new Date();

    public pendingBtnFilter: boolean = true;

    public backupDateStart: Date;
    public backupDateEnd: Date;
    public defaultLimitDateIntervalFilter = 86400000 * 2;
    public userOnlineTimeInterval: number;

    private subscription: Subscription;

    @Input()
    milisecondsLimitDateIntervalFilter: number;

    @Output() isSubmitted = new EventEmitter();

    // Events
    @Output('submitFilter') submitFilter: EventEmitter<boolean> = new EventEmitter();
    @Output('loadedFilter') loadedFilter: EventEmitter<boolean> = new EventEmitter();
    @Input('requestInProgress') requestInProgress: boolean;
    @Input('periodFilter') periodFilter: boolean = true;

    constructor(
        private dataService: DataService,
        private workspaceService: WorkspaceService,
        private notificationService: NotificationUtilService,
        private customerService: CustomerService,
        private driverService: DriverService,
        private assetService: AssetService,
        public translateJsonService: TranslateJsonService,
        private userFilterService: UserFilterService
    ){
        this.traducaoCalendario();
    }

    ngOnInit(){
        this.subscribeOnline();
        this.getCustomerIdUser();
        this.getFilterAlarms();
        this.subscription = this.userFilterService.event
            .subscribe(next => {
                if (next !== null) {
                    this.changedCustomers(next, true)
                }
        })
    }

    ngOnDestroy(){
        if(this.subsonline) this.subsonline.unsubscribe();
        if(this.subsworkspace) this.subsworkspace.unsubscribe();
        if(this.subscustomers) this.subscustomers.unsubscribe();
        if(this.subsdrivers) this.subsdrivers.unsubscribe();
        if(this.subsvehicles) this.subsvehicles.unsubscribe();
        if(this.subscription) this.subscription.unsubscribe();
    }

    public traducaoCalendario(){
        this.ptBr = {
          firstDayOfWeek: 0,
          dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
          dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
          dayNamesMin: ["Do","Sg","Te","Qa","Qi","Sx","Sa"],
          monthNames: [ "Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro","Novembro","Dezembro" ],
          monthNamesShort: [ "Jan", "Fev", "Mar", "Abr", "Mai", "Jun","Jul", "Ago", "Set", "Out", "Nov", "Dez" ],
          today: 'Hoje',
          clear: 'Limpar'
        };

        this.en = {
            firstDayOfWeek: 0,
            dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
            dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            dayNamesMin: ["Su","Mo","Tu","We","Th","Fr","Sa"],
            monthNames: [ "January","February","March","April","May","June","July","August","September","October","November","December" ],
            monthNamesShort: [ "Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ],
            today: 'Today',
            clear: 'Clear'
        };

        this.es = {
            firstDayOfWeek: 0,
            dayNames: [ "Domingo","Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
            dayNamesShort: ["Dom","Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
            dayNamesMin: ["D", "L", "Ma", "Mi", "J", "V", "S"],
            monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
            monthNamesShort: [ "En", "Feb", "Mar", "Abr", "May", "Jun","Jul", "Ago", "Sep", "Oct", "Nov", "Dic" ],
            today: 'Hot',
            clear: 'Claro'
        }
      }

    /**
     * Verifica se existe um Workspace, caso exista chama os filtros
     * caso não exista, busca o workspace, registra e chama os filtros.
     */
    public loadUserWorkspace(): void {
        if (!this.dataService.getWorkspace() || !this.dataService.getWorkspace().currentUserId) {
            this.subsworkspace = this.workspaceService.getWorkspaceByLogin(localStorage.getItem('userLogin'))
            .subscribe((_workspace: Workspace) => {
                window.localStorage.setItem('currentUser', JSON.stringify(_workspace.currentUser));
                this.dataService.registerWorkspace(_workspace);
                this.getFilterAlarms();
                this.dataService.registerWorkspace(_workspace);
                this.userOnlineTimeInterval = _workspace.onlineTimeInterval;
            },
            error => {
                this.notificationService.printErrorMessage(this.translateJsonService.getTranslateSubs('FILTER-GLOBAL.msg_notresult') + error);
            });
        } else {
            this.getFilterAlarms();
        }
    }

    /**
     * Busca os filtros selecionados, caso existam, utiliza-os.
     */
    private getFilterAlarms(): void {
        let kp: Workspace  = this.dataService.getWorkspace(),
        reloadFilter = false,
        customerFilterIsEmpty = false;
        this.workspace = kp;

        if (kp){
            if (kp.selectedFilterAlams) {
                this.filterSelectedData = kp.selectedFilterAlams;

                const time = (this.filterSelectedData.selectedDateEnd.getTime() - this.filterSelectedData.selectedDateStart.getTime());

                if (time > 172800000) {
                    this.changeOnlineInterval();    
                }                
            } else {  
                const lastUsedFilter = this.userFilterService.getLastUsedFilter()
                const defaultFilter = this.userFilterService.defaultFilter.getValue();

                if (lastUsedFilter) {
                    this.filterSelectedData = lastUsedFilter
                    reloadFilter = true
                } else if (defaultFilter) {
                    this.filterSelectedData = new FilterAlarms()
                    this.filterSelectedData.selectedCustomers = defaultFilter
                }         
                this.changeOnlineInterval();
            }

            if(this.filterSelectedData.selectedCustomers.length > 0 && kp.userCustomerChildIds.filter(item => this.filterSelectedData.selectedCustomers.indexOf(item.customer) < 0).length != 0){
                this.selectedCustomers = this.filterSelectedData.selectedCustomers;
            }

            if(this.filterSelectedData.selectedVehicles && this.filterSelectedData.selectedVehicles.length > 0){
                this.selectedVehicles = this.filterSelectedData.selectedVehicles;
            }

            if(this.filterSelectedData.selectedDrivers && this.filterSelectedData.selectedDrivers.length > 0){
                this.selectedDrivers = this.filterSelectedData.selectedDrivers;
            }

            if(this.filterSelectedData.selectedCustomers.length == 0){
                customerFilterIsEmpty = true
            }

            if(this.filterSelectedData.selectedAlarmTypes.length == 0){
                if(kp.customerProfileId == 3){
                    this.filterSelectedData.selectedAlarmTypes = [1,2,3,4,6,7,8,9,10,11,12,13,14,15,16,17,22,23,24,25,26];
                }else{
                    this.filterSelectedData.selectedAlarmTypes = [1,2,3,4,6,7,8,9,10,11,12,13,14,15,16,17,22,23,24,25,26];
                }
            }
        }

        this.registerFilterInWorkspace(customerFilterIsEmpty);
        this.loadedFilter.emit(true); 
        this.loadCustomers(reloadFilter && !customerFilterIsEmpty);
    }

    /**
     * Verifica o Check Online caso seja marcado altera as Datas do Filtro.
     */
    public subscribeOnline() {
        this.subsonline = this.workspaceService.botaoOnline.subscribe((online) => {
            this.isOnlineChecked = online;
            if(online){
                this.changeOnlineInterval();
            }
        })
    }

    /*
     * Registra no Workspace as opções no Filtro.
     */
    public registerFilterInWorkspace(customerFilterIsEmpty: boolean = false) {
        let kp: Workspace  = this.dataService.getWorkspace();
        if (kp) {
            let _filter:FilterAlarms = kp.selectedFilterAlams;
            if (!_filter) {
                kp.selectedFilterAlams = this.filterSelectedData;
            }
            if (customerFilterIsEmpty) {
                this.userFilterService.deleteLastUsedFilter()
            } else {
                this.userFilterService.saveLastUsedFilter(this.filterSelectedData)
            }
        }
    }

    /**
     * Carrega as Filiais para o Filtro
     */
    private loadCustomers(reloadDriversAndVehicles: boolean = false): void {
        let customerChildsUser: number[] = [];
        this.workspace.userCustomerChildIds.forEach((customerChildsPermissions : customerChildsPermissions ) => {
            customerChildsUser.push(customerChildsPermissions.customer);
        })
            
        if (!this.dataService.getAllCustomerChilds() || this.dataService.getAllCustomerChilds().length < 1) {
          if(customerChildsUser.length > 0){
            this.subscustomers = this.customerService.getCustomerChildsByIds(customerChildsUser).subscribe((_customers: CustomerChild[]) => {
                _customers = _customers.filter(customer => customer.status === 1)
                this.customers = this.customerService.convertCustomerChildsToTreeInputModel(_customers);
                this.loadedCustomers = true;
                this.dataService.registerCustomerChilds(_customers);
                if (reloadDriversAndVehicles) {
                    this.loadDrivers([], true);
                    this.loadDrivers([], false);
                    this.loadVehicles([], true);        
                    this.loadVehicles([], false);
                }
            })
          }

        }else{
            this.customers = this.customerService.convertCustomerChildsToTreeInputModel(this.dataService.getAllCustomerChilds());
            this.loadedCustomers = true;
            this.loadDrivers([], false);
            this.loadVehicles([], false);
        }
    }

    /**
     * Busca todos Condutores das filiais selecionadas, e insere no menu.
     */
    private loadDrivers(selectedNumbers : number[], reload: boolean): void {
        if(this.filterSelectedData && this.filterSelectedData.selectedCustomers.length > 0) {
            if(reload){
                this.subsdrivers = this.driverService.getDriversByManyCustomerIds(this.filterSelectedData.selectedCustomers)
                    .subscribe((driversFromCustomers: Driver[]) => {
                        const activeDrivers = driversFromCustomers.filter(driver => driver.status == AssetStatus.ACTIVE);
                        this.drivers = this.driverService.convertDriversToTreeInputModel(activeDrivers, this.filterSelectedData.selectedCustomers, this.dataService.getAllCustomerChilds());
                        this.dataService.registerDrivers(activeDrivers);
                        this.loadedDrivers = true;
                    },
                error => {
                    console.info('Falha na leitura do cadastro de Drivers.', error);
                })
            }else{
                this.drivers = this.driverService.convertDriversToTreeInputModel(this.dataService.getAllDrivers(), this.filterSelectedData.selectedCustomers, this.dataService.getAllCustomerChilds());
                this.loadedDrivers = true;

                if(this.filterSelectedData.selectedVehicles && this.filterSelectedData.selectedVehicles.length > 0){
                    this.pendingDrivers = true;
                }else{
                    this.pendingDrivers = false;
                }
            }
        }else{
            this.loadedDrivers = false;
        }
    }

    /**
     * Busca todos Veiculos das filiais selecionadas, e insere no menu.
     */
    private loadVehicles(selectedNumbers : number[], reload: boolean): void {
        if(this.filterSelectedData && this.filterSelectedData.selectedCustomers.length > 0) {
            if(reload){
                this.subsvehicles = this.assetService.getAssetsByManyCustomerChildAndAssetType(this.filterSelectedData.selectedCustomers, 3)
                    .subscribe((assetFromCustomers: Asset[]) => {
                        const activeAssets = assetFromCustomers.filter(asset => asset.status == AssetStatus.ACTIVE);
                        this.vehicles = this.assetService.convertAssetToTreeInputModel(activeAssets);
                        this.dataService.registerVehicles(activeAssets);
                        this.loadedVehicles = true;
                },
                error => {
                    console.info("Falha na leitura do cadastro de Vehicles.", error);
                })
            }else{
                this.vehicles = this.assetService.convertAssetToTreeInputModel(this.dataService.getAllVehicles());
                this.loadedVehicles = true;

                if(this.filterSelectedData.selectedDrivers && this.filterSelectedData.selectedDrivers.length > 0){
                    this.pendingVehicles = true;
                }else{
                    this.pendingVehicles = false;
                }
            }
        }
    }

    /**
     * Altera as Datas para as últimas 24 Horas
     */
    /* public changeOnlineInterval() {
        let today: Date = new Date(),
            today24hLast: Date = new Date(),
            today24hForward: Date = new Date();

        today24hLast.setHours(today.getHours() - 1*24);
        today24hForward.setHours(today.getHours());
        this.filterSelectedData.selectedDateStart = today24hLast;
        this.filterSelectedData.selectedDateEnd = today24hForward;
    } */

    public changeOnlineInterval(interval?: number) {
        if(!interval){
            interval = this.dataService.getWorkspace().onlineTimeInterval;
        }

        let today: Date = new Date(),
            todayLast: Date = new Date(),
            todayForward: Date = new Date();

            todayLast.setHours(today.getHours() - 1*interval);
            todayForward.setHours(today.getHours());

        this.filterSelectedData.selectedDateStart = todayLast;
        this.filterSelectedData.selectedDateEnd = todayForward;
    }

    /**
     * Resposta sempre que um Customer é selecionado
     * na Árvore
     * @param event Lista de IDs
     */
    public changedCustomers(event: number[], reset = false){ 
        if (event.length == 0) {
            this.changingSelectedCustomers = 0;
            this.selectedCustomers = event;

            this.selectedDrivers = [];
            this.selectedVehicles = [];

            this.lockingDrivers();
            this.lockingVehicles();
            this.lockingFilterBtn();

            this.applySelectedCustomers();
        } else if (reset) {
            this.selectedCustomers = event;
            this.applySelectedCustomers();

            this.loadDrivers(event, true);
            this.loadVehicles(event, true);

            this.changingSelectedCustomers = 0;

            this.lockingDrivers();
            this.lockingVehicles();
            this.lockingFilterBtn();
        } else {
            let csc: number = ++this.changingSelectedCustomers;

            setTimeout(() => {
                if(this.changingSelectedCustomers == csc){
                    this.selectedCustomers = event;
                    this.applySelectedCustomers();

                    this.loadDrivers(event, true);
                    this.loadVehicles(event, true);

                    this.changingSelectedCustomers = 0;

                    this.lockingDrivers();
                    this.lockingVehicles();
                    this.lockingFilterBtn();
                }
            },2000);
        }
    }

    /**
     * Aplica os Filtros no FilterSelectedData e registra.
     */
    private applySelectedCustomers(){
        this.filterSelectedData.selectedCustomers = this.selectedCustomers;
        this.filterSelectedData.selectedDrivers = this.selectedDrivers;
        this.filterSelectedData.selectedVehicles = this.selectedVehicles;
        this.registerFilterInWorkspace();
    }

    /**
     * Tranca e Destranca o Input de Drivers
     */
    private lockingDrivers(){
        if(this.selectedCustomers.length > 0){
            this.pendingDrivers = false;
        }else{
            this.pendingDrivers = true;
        }
    }

    private lockingFilterBtn(){
        if(this.selectedCustomers.length > 0){
            this.pendingBtnFilter = false;
        }else{
            this.pendingBtnFilter = true;
        }
    }

    /**
     * Tranca e Destranca o Input de Vehicles
     */
    private lockingVehicles(){
        if(this.selectedCustomers.length > 0){
            this.pendingVehicles = false;
        }else{
            this.pendingVehicles = true;
        }
    }

    /**
     * Resposta sempre que um Driver é selecionado
     * na Árvore
     * @param event Lista de IDs
     */
    public changedDrivers(event){
        if(event.length > 0){
            this.pendingVehicles = true;
        }else{
            this.pendingVehicles = false;
        }
        this.selectedDrivers = event;
        this.applySelectedCustomers();
    }

    /**
     * Resposta sempre que um Vehicle é selecionado
     * na Árvore
     * @param event Lista de IDs
     */
    public changedVehicles(event){
        if(event.length > 0){
            this.pendingDrivers = true;
        }else{
            this.pendingDrivers = false;
        }
        this.selectedVehicles = event;
        this.applySelectedCustomers();
    }

    /**
     * Acionado quando alguma data é alterada
     */
    public onChangeDates(){
        this.pendingBtnFilter = false;
        this.workspaceService.setBotaoOnline(false);
        this.registerFilterInWorkspace();
    }

    public search() {
        this.isSubmitted.emit(true)
        if(this.filterSelectedData.selectedDateStart && this.filterSelectedData.selectedDateEnd) {
            const dateIntervalInMiliseconds = this.filterSelectedData.selectedDateEnd.getTime() - this.filterSelectedData.selectedDateStart.getTime();

            let milisecondsLimitDateIntervalFilter = (this.milisecondsLimitDateIntervalFilter) ? this.milisecondsLimitDateIntervalFilter : this.defaultLimitDateIntervalFilter

            if (dateIntervalInMiliseconds > milisecondsLimitDateIntervalFilter) {
                const limitInDays = milisecondsLimitDateIntervalFilter / 1000 / 86400;
                const limitMessage = this.translateJsonService.getTranslateSubs('FILTER-GLOBAL.msg_max_range_days').replace('#NUMBER_OF_DAYS#', limitInDays);
                this.notificationService.printErrorMessage(limitMessage);
            }else{
                this.submitFilter.emit(true);   
            }
        } else {
            if(this.filterSelectedData.selectedDateStart == null) {
                this.filterSelectedData.selectedDateStart = this.backupDateStart;
            } else if (this.filterSelectedData.selectedDateEnd == null) {
                this.filterSelectedData.selectedDateEnd = this.backupDateEnd;
            }
            this.notificationService.printErrorMessage(
                `${this.translateJsonService.getTranslateSubs('FILTER-GLOBAL.err_datepicker_input_data')}: 
                "${this.translateJsonService.getDataFormat()} hh:mm"`
                );            
        }
        
    }

    saveDateStart(dateStart) {
        this.pendingBtnFilter = false;
        this.backupDateStart = dateStart.model;
    }

    saveDateEnd(dateEnd) {
        this.pendingBtnFilter = false;
        this.backupDateEnd = dateEnd.model;
    }

    private getCustomerIdUser() {
        this.customerIdByWorkspace = this.dataService.getWorkspace().customerProfileId;

        this.childIdByWorkspace = this.dataService.getWorkspace().currentUser.customerChild;
    }

}
