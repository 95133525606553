import {Alarm} from './alarm.entity';
import {Audit, User} from '.';

export class AlarmDto extends Alarm {
	// extra asset
     public assetIdentification: string;
     public assetPicture: string;
     public assetPrefix: string;

     // extra driver
     public driverName: string;
     public driverPicture: string;

     // extra info audit
     public auditData: Audit;
     public auditUser: User;

     // leitura
     public leitura: string;

     // total nivel
     public totalLevel: number;

     // total category
     public totalCategory: number;
     // total geral
     public totalCount: number;

     public timeReady: number;
     public alarmTypeIcon: String;
     public groupRisk?: number;


}
