import { Component, ElementRef, HostListener, OnInit, ViewChild } from "@angular/core";
import { DialogConfig } from "../dialog/dialog-config";
import { DialogRef } from "../dialog/dialog-ref";
import { DefaultFilter } from 'app/@core/models/defaultFilter.entity';
import { UserService } from "app/@core/data/users.service";
import { NotificationUtilService } from "app/@core/utils/notification.util.service";
import { MediaDownloadService } from "app/@core/data/media-download.service";
import { TranslateJsonService } from "app/@core/data/translate-json.service";

@Component({
    templateUrl: 'zoom-medias.component.html',
    styleUrls: ['zoom-medias.component.scss']
})
export class ZoomMediasComponent implements OnInit{
  @ViewChild('videoWrapper') public videoWrapper: ElementRef;
  @ViewChild('myImage') myImage: ElementRef<HTMLImageElement>;
    public imageOpacity = 1;
    public isPicture: boolean = true;
    public urls: string[] = [];
    public user: any = {};
    public userVideoDataEmail: string = "";
    public isDownloading: boolean = false;
    
    constructor(
        private notificationService: NotificationUtilService,
        private translateJsonService: TranslateJsonService,
        public config: DialogConfig,
        public dialog: DialogRef,
        private userService: UserService,
        private mediaDownloadService: MediaDownloadService
    ) {
      if (!config.data.urlMovies || config.data.urlMovies.every(url => url === "")) {
            this.isPicture = true;
            this.urls = config.data.urlPictures !== undefined ? config.data.urlPictures : [];
    } else {
            this.isPicture = false;
            this.urls = config.data.urlMovies;
          }
          
          this.urls = this.urls.filter(url => url != "")
  }

    onClose() {
        this.dialog.close('some value')
    }

    data1 = new Date();

    public userEmail(){
    }

    public isFullscreenVideo = false;

    public ngOnInit(): void {
  
      const userId = this.userService.currentUser().id;
  
      this.userService.getUserVideoDataById(userId).subscribe((result) => {
  
        this.userVideoDataEmail = result.email;
    }, error => { 
        this.notificationService.printErrorMessage(error);
    });
  }



  
  onVideoPlay() {
    this.imageOpacity = 0;
    this.myImage.nativeElement.classList.add('hide-image');
  }

  handleDownload(url) {
    if(this.isDownloading)return;
    this.isDownloading=true
    const filename = this.mediaDownloadService.extractFileNameFromUrl(url);
    this.mediaDownloadService.downloadFile(this.mediaDownloadService.buildVideoTelemetryWatermarkBody(url)).subscribe(blob => {
      this.mediaDownloadService.saveFileLocally(blob, filename);
      this.isDownloading = false;
    }, err=>{
      if (err.status === 503) {
        this.notificationService.printErrorMessage(this.translateJsonService.getTranslateSubs("GLOBAL.service_unavailable"))
      } else {
        this.notificationService.printErrorMessage(this.translateJsonService.getTranslateSubs("GLOBAL.failed_operation"))
      }
      this.isDownloading = false;
    });
  }
  
  onVideoPause() {
    this.imageOpacity = 1;
    this.myImage.nativeElement.classList.remove('hide-image');
  }
  
    @HostListener('fullscreenchange', ['$event'])
    @HostListener('webkitfullscreenchange', ['$event'])
    @HostListener('mozfullscreenchange', ['$event'])
    @HostListener('MSFullscreenChange', ['$event'])
    @HostListener('fullscreenchange', ['$event'])
  
    onFullScreenChange($event) {
      console.log(document.fullscreenElement);
      console.log(this.videoWrapper);
      if(document.fullscreenElement) {
        if(this.videoWrapper.nativeElement.getAttribute('data-wrapper-type') === 'video-wrapper') {
          this.isFullscreenVideo = true;
          
        }
      }else {
        if(this.isFullscreenVideo === true) {
          this.isFullscreenVideo = false;
        }
      }
    }
    
    
    public toggleFullscreen($event: MouseEvent) {
      if ($event.target instanceof Element) {
        const target = ($event.target as Element);
        const zoomElement = target.closest('.video-wrapper');
        if (!document.fullscreenElement) {  
          zoomElement.requestFullscreen();
          
        } else if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    }  
  }