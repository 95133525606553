import { Injectable } from "@angular/core";
import {throwError as observableThrowError, of as observableOf,  Observable } from 'rxjs';
import { Http, Response, Headers } from '@angular/http';
import { ConfigService } from "app/@core/config/config.service";
import { map, catchError } from "rxjs/operators";

import * as jwtDecode from 'jwt-decode';
import * as moment from 'moment';
import { environment } from "environments/environment";

@Injectable()
export class AuthenticationService {
    baseUrl: string;
    
    constructor(
        protected http: Http,
        protected configService: ConfigService
    ) {
        this.baseUrl = configService.getApiURI();
    }

    /**
     * Verifica a existência do usuário e suas credenciais, retorna o Token caso autenticado com sucesso.
     * @param username Login do Usuário
     * @param password Senha do Usuário
     * @param recaptchaToken Token desafio reCAPTCHA
     */
    login(username:string, password:string, recaptchaToken: string ) {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');

        return this.http.post(this.baseUrl+'login', {username, password, recaptchaToken}, {headers: headers}).pipe(
            map((res: Response) => {                             
                return res;
        }),
        catchError(this.handleError),); 
    }

    private handleError(error: any) {
        console.log(error);
        return observableThrowError(error.json());
    }  

    setSession(result) {
        const token = result.headers.get('Authorization');
        const payload = <JWTPayload> jwtDecode(token);
        const user = payload.sub;
        const expiresAt = moment.unix(payload.exp);

        window.localStorage.setItem('access_token', token);
        window.localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));
        window.localStorage.setItem('userLogin', user);
        document.cookie = `access_token=${token}`;
    }

    setOrigin(origin: string) {
        window.localStorage.setItem('origin', origin);
    }

    getOrigin() {
        const origin = window.localStorage.getItem('origin');

        return (origin) ? origin : environment.loginRoute;
    }

    getLang() {
        const lang = localStorage.getItem('lang');

        if(lang) {
            if(lang == 'ptbr') {
                return 'pt-br'
            }else{
                return lang;
            }
        }else{
            return 'pt-br'
        }
    }

    setExternalToken(token) {
        const payload = <JWTPayload> jwtDecode(token);
        const user = payload.sub;
        const expiresAt = moment.unix(payload.exp);

        window.localStorage.setItem('access_token', token);
        window.localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));
        window.localStorage.setItem('userLogin', user);
        document.cookie = `access_token=${token}`;
    }

    getExpiration() {
        const expiration = localStorage.getItem('expires_at');
        const expiresAt = JSON.parse(expiration);

        return moment(expiresAt);
    }

    isLoggedIn() {
        return moment().isBefore(this.getExpiration());
    }

    getToken() {
        return localStorage.getItem('access_token');
    }
    
    async destroySession(params: String = 'cc=true') {
        const userLogin = window.localStorage.getItem('userLogin');

        try {
            await this.logout(userLogin);
        } finally {
            this.cleanCache();
            window.location.href = `${environment.loginUrl}${this.getLang()}/${this.getOrigin()}?${params}`
        }    
    }

    cleanCache() {
        localStorage.removeItem('access_token');
        localStorage.removeItem('expires_at');
        localStorage.removeItem('userLogin');
        localStorage.removeItem('currentUser');
        localStorage.removeItem('userWorkspace');
        localStorage.removeItem('filterLastUsed');
    }

    getHttpHeaders() : Headers {
        let headers = new Headers();

        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', this.getToken());

        return headers;
    }

    private logout(userLogin: string) {
        return new Promise((resolve, reject) => {
            const requestDate = this.configService.convertDateToIsoString(new Date());
            const _ws = this.configService.webServicesV2['logout'];

            this.http.put(_ws.url(this.baseUrl), { requestDate, userLogin }, { headers: this.getHttpHeaders() })
                .subscribe(result => resolve(true), error => resolve(true));
        })
    }

    // Retirar quando o CORE Application for implementado!
    public getFrotaJSessionId(): Promise<any> {
        return new Promise((resolve, reject) => {
            const _ws = this.configService.webServicesV2['getFrotaJSessionId']

            this.http.get(
                _ws.url(this.baseUrl), { headers: this.getHttpHeaders() }
            ).subscribe(
                result => resolve(result),
                error => reject(error)
            )
        })
    }
    public loginAndGetFrotaJSessionId(password: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const _ws = this.configService.webServicesV2['getFrotaJSessionId']

            this.http.post(
                _ws.url(this.baseUrl), {password: password}, {headers: this.getHttpHeaders()}
            ).subscribe(
                result => resolve(result),
                error => reject(error)
            )
        })
    }

}

interface JWTPayload {
    sub: string;
    exp: number;
}