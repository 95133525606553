import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class AuthInterceptor {

    /* intercept(req: HttpRequest<any>,
              next: HttpHandler): Observable<HttpEvent<any>> {

        const idToken = localStorage.getItem("access_token");

        if(idToken){
            const cloned = req.clone({
                headers: req.headers.set("Authorization",
                    "Bearer " + idToken)
            });

            return next.handle(cloned);
        }else{
            return next.handle(req).do((event: HttpEvent<any>) => {
                console.log("sei la");
              }, (err: any) => {
                if (err instanceof HttpErrorResponse) {
                  if (err.status === 500) {
                    console.log("token expirado");
                  }
                }
              });
        }
    } */

    /* intercept(request: HttpRequest<any>,
            next: HttpHandler): Observable<HttpEvent<any>> {
        
        const idToken = localStorage.getItem("access_token");
        
        const cloned = request.clone({
            headers: request.headers.set("Authorization",
                "Bearer " + idToken)
        });      
    
        return next.handle(cloned).do((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            console.log("Autorizou com Sucesso o header");
          }
        }, (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 500) {
                console.log("O token ta expirado");
            }
          }
        });
      } */
}