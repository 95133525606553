/**
 * Reviewed By Rafael
 * 03-12-18
 */
import {throwError as observableThrowError,  Observable } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';

import {SelectItem} from 'primeng/primeng';

import {Asset, Equipament} from '../models';
import { ConfigService } from '../config/config.service';
import { IntegrationDevice } from '../models/integrationDevice.entity';
import { TreeInputModel } from '../models/treeInputModel.entity';
import { Http, Response, Headers } from '@angular/http';
import { AuthenticationService } from 'app/pages/login/authentication.service';
import { asTextData } from '@angular/core/src/view';
import { EquipamentType } from '../enum/equipament.type.enum';
import { Equipment } from '../models/equipment.entity';
import { CustomerEmail } from '../interfaces/customerEmail';


@Injectable()
export class AssetService {
  private baseUrl;

  constructor(
    private http: Http,     
    private configService: ConfigService,
    protected auth: AuthenticationService
  ) { 
    this.baseUrl = configService.getApiURI();
  }

  /**
   * Busca Assets por Lista de IDs
   * @param assetsIds Asset IDs
   */
  public getAssetsByIds(assetsIds: number[]): Observable<IntegrationDevice[]> {
    let _ws = this.configService.webServices['assetsByIds'];

    return this.http.get(_ws.url(this.baseUrl, assetsIds.join(',')), {headers: this.getHttpHeaders()}).pipe(
      map((res: Response) => {
        return res.json();
      }),
      catchError(this.handleError),);
  }

  /**
   * Busca Assets por Filiais e Tipo
   * @param customerChildsId Lista de Filiais
   * @param assetType Tipo de Asset
   */
  public getAssetsByManyCustomerChildAndAssetType(customerChildsId : number[], assetType : number, filterInactive?: boolean, filterWithoutEquipment?: boolean, vehicleTypeIdList?: number[]): Observable<Asset[]> {
    let _ws = this.configService.webServices['getAssetsByManyCustomerChildAndAssetType'];

    type Body = {
      assetType: number
      customerChildIdList: number[]
      filterInactive?: boolean
      filterWithoutEquipment?: boolean
      vehicleTypeIdList?: number[]
    }

    let body: Body = {
      assetType: assetType,
      customerChildIdList: customerChildsId,
      vehicleTypeIdList: vehicleTypeIdList,
    }
    
    if(filterInactive) body.filterInactive = filterInactive
    if(filterWithoutEquipment) body.filterWithoutEquipment = filterWithoutEquipment
    if(vehicleTypeIdList) body.vehicleTypeIdList = vehicleTypeIdList

    return this.http.post(_ws.url(this.baseUrl),  body, {headers: this.getHttpHeaders()}).pipe(
      map((res: Response) => {
          return res.json();
      }),
      catchError(this.handleError),);
  }

  public getRecursiveAssetListFromCustomerChildId(customerChildId: number, assetType: number): Observable<Asset[]> {
    let _ws = this.configService.webServices['getRecursiveAssetListFromCustomerChildId'];

    return this.http.get(_ws.url(this.baseUrl, customerChildId, assetType), {headers: this.getHttpHeaders()}).pipe(
      map((res: Response) => {
        return res.json()
      }),
      catchError(this.handleError)
    )
  }

  public getAssetListByCustomerChildCustomerProfile(customerChildId: number, assetType: number): Observable<Asset[]> {
    let _ws = this.configService.webServices['getAssetListByCustomerChildCustomerProfile'];

    return this.http.get(_ws.url(this.baseUrl, customerChildId, assetType), {headers: this.getHttpHeaders()}).pipe(
      map((res: Response) => {
        return res.json()
      }),
      catchError(this.handleError)
    )
  }

  

  public getAssetsByCustomerChildAndAssetType(customerChildsId : number, assetType : number): Observable<Asset[]> {
    let _ws = this.configService.webServices['assetsByCustomerIdAndType'];

    return this.http.get(_ws.url(this.baseUrl,  customerChildsId, assetType), {headers: this.getHttpHeaders()}).pipe(
      map((res: Response) => {
          return res.json();
      }),
      catchError(this.handleError),);
  }

  public checkOnlineStatusByCustomersChildAndAssetType(customersChildId : number[], assetType : number): Observable<Asset[]> {
    let _ws = this.configService.webServicesV2['checkOnlineStatusByCustomersChildAndAssetType'];

    return this.http.get(_ws.url(this.baseUrl,  customersChildId, assetType), {headers: this.getHttpHeaders()}).pipe(
      map((res: Response) => {
          return res.json();
      }),
      catchError(this.handleError),);
  }

  public getVehiclesWithTypesByCustomerChildIds(customerChildIdList: number[], vehicleTypeIdList: number[]) {
    let _ws = this.configService.webServicesV2['getVehiclesWithTypesByCustomerChildIds']
    return this.http.get(_ws.url(this.baseUrl, customerChildIdList, vehicleTypeIdList), {headers: this.getHttpHeaders()}).pipe(
      map((res: Response) => {
        return res.json()
      }),
      catchError(this.handleError)
    )
  }

  public checkOnlineStatusByAssetId(assetId: number): Observable<Asset> {
    let _ws = this.configService.webServicesV2['checkOnlineStatusByAssetId'];

    return this.http.get(_ws.url(this.baseUrl, assetId), { headers: this.getHttpHeaders() }).pipe(
      map((res: Response) => {
        const assetList= res.json();
        return assetList[0]
      }),
      catchError(this.handleError),);
  }

  /**
   * Converte Lista de Assets no Formato Tree
   * @param assets Assets
   */
  public convertAssetToTreeInputModel(assets: Asset[]) {
    let result: TreeInputModel[] = [];
    
    assets.forEach((asset : Asset) => {
      result.push({id: asset.id, name: asset.name + ' - ' + asset.identification, parentId: null, online: false, imei: asset.imei, equipmentType: asset.equipmentType, vehicleTypeId: asset.vehicleTypeId });
    })
    
    return result;
  }

  public convertAssetToTreeInputModelPlayback(assets: Asset[]) {
    let result: TreeInputModel[] = [];
    
    assets.forEach((asset : Asset) => {
      result.push({id: asset.id, name: asset.name + ' - ' + asset.identification + this.getEquipmentType(asset.equipmentType), parentId: null, online: false, imei: asset.imei, equipmentType: asset.equipmentType });
    })
    
    return result;
  }

  private getEquipmentType(equipmentTypeId: any): string {
    let equipmentTypeDescription: string = "";

    if (equipmentTypeId === EquipamentType.JIMI) {
      equipmentTypeDescription = " (G5)";
    } else if (equipmentTypeId === EquipamentType.STONKAM) {
      equipmentTypeDescription = " (K1)";
    } else if (equipmentTypeId === EquipamentType.STONKAM_K1_PLUS) {
      equipmentTypeDescription = " (K1 PLUS)";
    } else if (equipmentTypeId === EquipamentType.STONKAM_K4) {
      equipmentTypeDescription = " (K4)";
    } else {
      equipmentTypeDescription = "";
    }

    return equipmentTypeDescription;
  }

  public convertEquipmentToTreeInputModel(equipments: Equipament[]) {
    let result: TreeInputModel[] = [];

    equipments.forEach((equipment: Equipament) => {
      result.push({id: equipment.id, name: equipment.identification, parentId: null, label: this.getAssetName(equipment.asset)});
    });

    return result;
  }

  getAssetName(assetName: string): string{
    if(assetName === null){
      return null;
    }
    let nameSplited :string[] = assetName.split(" - ");
    return nameSplited[1];
  }

  public syncDrivers(customersChild: number): Observable<Asset[]> {
    let _ws = this.configService.webServices['syncDriver'];

    return this.http.get(_ws.url(this.baseUrl,  customersChild), {headers: this.getHttpHeaders()}).pipe(
      map((res: Response) => {
          return res.json();
      }),
      catchError(this.handleError),);
  }

  public getAssetsIdByCustomerChildAndEquipmentType(customersChildId: number[], equipmentType: number[]): Observable<{ id: number, assetType: number }[]> {
    let _ws = this.configService.webServices['assetsIdByCustomersChildAndEquipType'];

    return this.http.post(_ws.url(this.baseUrl, equipmentType), { custIds: customersChildId }, {headers: this.getHttpHeaders()}).pipe(
      map((res: Response) => {
        return res.json();
      }),
      catchError(this.handleError),);
  }

  public getEmailListByAssetId(assetId: number): Observable<CustomerEmail>{
    let _ws = this.configService.webServices['getEmailListByAssetId']
    return this.http.get(_ws.url(this.baseUrl, assetId), {headers: this.getHttpHeaders()}).pipe(
      map((res: Response) => {
        return res.json();
      }),
      catchError(this.handleError)
    )
  }

  public syncVehicle(customersChild: number): Observable<Asset[]> {
    let _ws = this.configService.webServices['syncVehicle'];

    return this.http.get(_ws.url(this.baseUrl,  customersChild), {headers: this.getHttpHeaders()}).pipe(
      map((res: Response) => {
          return res.json();
      }),
      catchError(this.handleError),);
  }

  public saveNewAsset(asset : Asset): Observable<any> {
    let _ws = this.configService.webServices['saveAsset'];

    let string = {      
      "customerChildId": asset.customerChildId,
      "customerId": asset.customerId,
      "identification": asset.identification,
      "name": asset.name,
      "status": asset.status,
      "assetType": asset.assetType
    }

    return this.http.post(_ws.url(this.baseUrl), string, {headers: this.getHttpHeaders()}).pipe(
      map((res: Response) => {
          return res;
      }), catchError(this.handleError)
    )
  }

  /**
   * Converte Lista de Assets no Formato Select Item Menu
   * @param _assets Assets
   */
  public getAssetsSelectItem(_assets: Asset[]): SelectItem[] {
    let _result: SelectItem[] = [];
    _assets.forEach((asset: Asset) => {
      _result.push({label: asset.identification + "/" + asset.name, value: asset.id});
    });
    return _result;
  }
  
  private handleError(error: any) {
    let errorMsg = (error.message) ? error.json() : error.text();

    return observableThrowError(errorMsg);
  }   

  getHttpHeaders() : Headers {
    let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', this.auth.getToken());

    return headers;
  }
}
