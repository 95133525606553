import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';

// Components
import { FilterV2Component } from "./filter-v2.component";

// Modules
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { ThemeModule } from "../../theme.module";

@NgModule({
    imports: [
        CommonModule,
        CalendarModule,
        FormsModule,
        ThemeModule
    ],
    declarations: [
        FilterV2Component
    ],
    providers: [    
    ],
    entryComponents: [],
    exports: [
        FilterV2Component
    ]
})
export class FilterV2Module { }