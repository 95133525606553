import { Injectable } from '@angular/core';
import { Predicate } from '../interfaces/interfacePredicate'

import * as _ from 'lodash';

@Injectable()
export class ItemsUtilService {

    constructor() { }


    /*
        Remove um item de um array
    */
    removeItemFromArray<T>(array: Array<T>, item: any) {
        _.remove(array, function (current) {
            //console.log(current);
            return JSON.stringify(current) === JSON.stringify(item);
        });
    }

    removeItems<T>(array: Array<T>, predicate: Predicate<T>) {
        _.remove(array, predicate);
    }

    /*
        Pesquisa um item especifico no array utilizando um predicado e substituindo
    */
    setItem<T>(array: Array<T>, predicate: Predicate<T>, item: T) {
        var _oldItem = _.find(array, predicate);
        if(_oldItem){
            var index = _.indexOf(array, _oldItem);
            array.splice(index, 1, item);
        } else {
            array.push(item);
        }
    }

    /*
        Adiciona um item para indice inicial: 0
    */
    addItemToStart<T>(array: Array<T>, item: any) {
        array.splice(0, 0, item);
    }

    /*
        A partir de um array do type generico T, seleciona todos os valores do tipo R para a propriedade
    */
    getPropertyValues<T, R>(array: Array<T>, property : string) : R
    {
        var result = _.map(array, property);
        return <R><any>result;
    }

    /*
        metodo para serializar uma string para um tipo especifico
    */
    getSerialized<T>(arg: any): T {
        let dateReviver = (key, value) => {
          var a;
          if (typeof value === 'string') {
              // exemplo: 2017-01-02T12:10:10.000Z
              //          2017-02-16T01:29:05.810Z
              a = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)Z$/.exec(value);
              if (a) {
                  return new Date(Date.UTC(+a[1], +a[2] - 1, +a[3], +a[4],
                                  +a[5], +a[6]));
              }
          }
          return value;
        };
        

        return <T>JSON.parse(JSON.stringify(arg), dateReviver);
    }


}
