import { Injectable } from "@angular/core";
import { ConfigService } from "../config/config.service";
import { Observable, throwError as observableThrowError } from "rxjs";
import { map } from "rxjs/operators";
import { Http, Headers, ResponseContentType } from "@angular/http";
import { AuthenticationService } from "app/pages/login/authentication.service";
import * as moment from "moment";
import { MediaProcessType } from "../enum/media-process-type.enum";

@Injectable()
export class MediaDownloadService {
    public baseUrl;

    constructor(
        private configService: ConfigService,
        private http: Http,
        private auth: AuthenticationService
    ) {
        this.baseUrl = configService.getApiURI();
     }

    public extractFileNameFromUrl(url: string): string {
        const regex = /.*\/([^/?]+).*/;
        const match = url.match(regex);
        return match ? match[1] : null;
    }

    public downloadFile(requestData): Observable<any> {
        let ws = this.configService.webServicesV2["mediaDownload"];

        const options = {
            headers: this.getHttpHeaders(),
            responseType: ResponseContentType.Blob
        };
        return this.http.post(ws.url(this.baseUrl), requestData, options).pipe(
            map((res: Response) => {
                return res.blob();
            })
        );
    }

    public saveFileLocally(data: Blob, filename: string) {
        const url = window.URL.createObjectURL(data);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }


    private getFormattedDateForWatermark(dateformat?: string, date?: moment.Moment): string {
        const now = date ? date : moment();
        let formattedDate;
        if (dateformat != null) {
            formattedDate = now.format(dateformat)
        } else {
            formattedDate = now.format('YYYY-MM-DD HH:mm:ss Z')
        }
        return formattedDate.replace(/:/g, '\\:');
    }

    public buildVideoTelemetryWatermarkBody(url:string, date?:moment.Moment,dateFormat?:string):any{
        const body = {
            "url": url,
            "mediaProcessType": MediaProcessType.VIDEOTELEMETRY_WATERMARK,
            "extraData": {
                "timestamp": this.getFormattedDateForWatermark(dateFormat,date)
            }
        }
        return body;
    }


    private getHttpHeaders(): Headers {
        let headers = new Headers();

        headers.append("Content-Type", "application/json");
        headers.append("Authorization", this.auth.getToken());

        return headers;
    }
}
