import { Injectable } from "@angular/core";
import { AccessUserLog } from "../models/accessUserLog.entity";
import { Response, Http, Headers } from '@angular/http';
import { ConfigService } from "../config/config.service";
import { AuthenticationService } from "app/pages/login/authentication.service";

@Injectable()
export class AccessLogUserService {
    private baseUrl: String;
    
    constructor(
        private http: Http,
        private config: ConfigService,
        private auth: AuthenticationService
    ) {
        this.baseUrl = config.getApiURI();
    }

    getAccessLogByUserIdAndDateInterval(userId: number, dateStart, dateEnd): Promise<AccessUserLog[]> {
        const URL_ENDPOINT = this.config.webServicesV2['getAccessLogByUserIdAndDateInterval'].url(this.baseUrl, userId, dateStart, dateEnd);
        const HEADERS = this.auth.getHttpHeaders();

        return this.http.get(URL_ENDPOINT, { headers : HEADERS })
            .toPromise()
            .then(res => res.json())
            .catch(err => {
                return Promise.reject((err.message) ? err.json() : err.text()  || 'Server error');
            })
    }

    getAllAccessLogByCustomerProfileId(customerProfileId: number, dateStart: Date, dateEnd: Date): Promise<AccessUserLog[]> {
        const URL_ENDPOINT = this.config.webServicesV2['getAllAccessControlByCustomerProfileId'].url(this.baseUrl, customerProfileId, this.config.convertDateToIsoString(dateStart), this.config.convertDateToIsoString(dateEnd));
        const HEADERS = this.auth.getHttpHeaders();

        return this.http.get(URL_ENDPOINT, { headers : HEADERS })
            .toPromise()
            .then(res => res.json())
            .catch(err => {
                return Promise.reject((err.message) ? err.json() : err.text()  || 'Server error');
            })
    }

}