import { Injectable } from "@angular/core";

@Injectable()
export class GlobalUtilsService {

    regexInput(keyup): string {
        let text = keyup.target.value;
        text = text.replace( /[^a-z0-9.]/gi , "");        
        return text;
    }
}