import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DropdownModule } from 'primeng/dropdown';
import {TooltipModule} from 'primeng/tooltip';

import {
  NbActionsModule,
  NbCardModule,
  NbLayoutModule,
  NbMenuModule,
  NbRouteTabsetModule,
  NbSearchModule,
  NbSidebarModule,
  NbTabsetModule,
  NbThemeModule,
  NbUserModule,
  NbCheckboxModule,
  NbPopoverModule,
  NbContextMenuModule,
} from '@nebular/theme';

import {
  NotificationCenterComponent,
  FilterComponent,
  LoadingAnimateComponent,
  FooterComponent,
  HeaderComponent,
  SearchInputComponent,
  ThemeSettingsComponent,
  ThemeSwitcherComponent,
  SpinnerLoaderComponent,
  TreeInputComponent,
  LanguagesComponent,
  IsUserCreareComponent,
  VoiceCommandComponent,
} from './components';

import { CapitalizePipe, PluralPipe, RoundPipe, TimingPipe } from './pipes';
import { DriverPicturePipe } from '../@core/pipes/driver.picture.pipe'
import { CustomerPicturePipe } from '../@core/pipes/customer.picture.pipe'
import { AlarmTypePicturePipe } from '../@core/pipes/alarm.type.picture.pipe'
import { Truncate } from '../@core/pipes/truncate.pipe';
import {
  OneColumnLayoutComponent,
  SampleLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
} from './layouts';
import { DEFAULT_THEME } from './styles/theme.default';
import { COSMIC_THEME } from './styles/theme.cosmic';
import { AMBIPAR_THEME } from './styles/theme.ambipar';

import {MultiSelectModule, CheckboxModule, InputTextModule, CalendarModule, OverlayPanelModule, ScrollPanelModule, TreeTableModule} from 'primeng/primeng';
import { TableModule } from 'primeng/table';
import { TreeModule } from "angular-tree-component";
import { TranslateModule } from '@ngx-translate/core';
import { ZoomMediasComponent } from './components/zoom-medias/zoom-medias.component';
import { DialogModule } from './components/dialog/dialog.module';
import { PopUpsTelemetryComponent } from 'app/pages/ui-alarms/telemetry/src/popups-events.component';
import { ModalModule } from 'ngx-bootstrap';
import { environment } from 'environments/environment';
import { DownloadButtonComponent } from './components/download-button/download-button.component';

const BASE_MODULES = [CommonModule, FormsModule, ReactiveFormsModule];

const NB_MODULES = [
  NbCardModule,
  NbLayoutModule,
  NbTabsetModule,
  NbRouteTabsetModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbCheckboxModule,
  NbPopoverModule,
  NgbModule,
  NbContextMenuModule,
  CheckboxModule,
  MultiSelectModule,
  InputTextModule,
  CalendarModule,
  OverlayPanelModule,
  TreeTableModule,
  TableModule,
  ScrollPanelModule,
  TranslateModule,
  DropdownModule,
  DialogModule,
  TooltipModule
];

const COMPONENTS = [
  ThemeSwitcherComponent,
  HeaderComponent,
  FooterComponent,
  LoadingAnimateComponent,
  NotificationCenterComponent,
  FilterComponent,
  SearchInputComponent,
  ThemeSettingsComponent,
  OneColumnLayoutComponent,
  SampleLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
  SpinnerLoaderComponent,
  FilterComponent,
  TreeInputComponent,
  LanguagesComponent,
  IsUserCreareComponent,
  VoiceCommandComponent,
  ZoomMediasComponent,
  PopUpsTelemetryComponent,
  DownloadButtonComponent
];

const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  DriverPicturePipe,
  CustomerPicturePipe,
  AlarmTypePicturePipe,
  Truncate
];

const NB_THEME_PROVIDERS = [
  ...NbThemeModule.forRoot(
    {
      name: 'default',
    },
    [ DEFAULT_THEME, COSMIC_THEME, AMBIPAR_THEME ],
  ).providers,
  ...NbSidebarModule.forRoot().providers,
  ...NbMenuModule.forRoot().providers,
];

@NgModule({
  imports: [...BASE_MODULES, ...NB_MODULES, TreeModule.forRoot(), ModalModule.forRoot()],
  exports: [...BASE_MODULES, ...NB_MODULES, ...COMPONENTS, ...PIPES, ModalModule],
  declarations: [...COMPONENTS, ...PIPES],
  entryComponents: [
    ZoomMediasComponent,
  ]
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: ThemeModule,
      providers: [...NB_THEME_PROVIDERS],
    };
  }
}
