export const mockDriversData = [
  {
    id: 1,
    name: "Creare Azul Ltda",
    customerChildId: 1,
    identification: "123.321.432-12",    
    integration: "FROTALOG-DRIVERS-0001",
    status: 1,
    "picture": null,  },
  {
    id: 2,
    name: "Nick Branco",
    customerChildId: 2,
    identification: "123.321.432-12",    
    integration: "FROTALOG-DRIVERS-0002",
    status: 1,
    "picture": null,  },
  {
    id: 3,
    name: "Jack Preto",
    customerChildId: 3,
    identification: "123.321.432-12",    
    integration: "FROTALOG-DRIVERS-0003",
    status: 1,
    "picture": null,  },
  {
    id: 4,
    name: "Senna Williams",
    customerChildId: 4,
    identification: "123.321.432-12",    
    integration: "FROTALOG-DRIVERS-0004",
    status: 1,
    "picture": null,  },
  {
    id: 5,
    name: "Lee Vermelho",
    customerChildId: 5,
    identification: "123.321.432-12",        
    integration: "FROTALOG-DRIVERS-0005",
    status: 1,
    "picture": null,  }
];

