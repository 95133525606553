import {Status} from '../enum/status.enum';
import {AssetType} from '../enum/asset-type.enum';
import { AssetTelemetryStatus } from '../enum/asset-telemetry-status.enum';
import { TelemetryPositionsModel } from 'app/pages/ui-alarms/telemetry/models/telemetry-positions.model';
import { Alarm } from './alarm.entity';
import { EventModel } from 'app/pages/ui-alarms/telemetry/models/event.model';
import { Infraction } from './infraction.entity';

export class Asset {
  	public id: number;
     public name: string;
     public customerName?: string;
     public customerChildName?: string;
     public customerChildId?: number;
     public customerId?: number;
     public identification: string;     
     public integration?: string;
     public picture?: string;
     public icon?: string;
     public status?: Status;
     public type?: AssetType;
     public assetType?: number;
     public imei?: string;
     public equipmentTypeId?: number;
     public equipmentType?: any;
     public vehicleTypeId?: number;

     public telemetryStatus?: AssetTelemetryStatus;
     public telemetry?: TelemetryPositionsModel;
     public alerts?: Alarm[];
     public infractions?: Infraction[];
     public events?: EventModel[];
     public riskGroup?: string; // 0 Baixo Risco / 1 Médio Risco / 2 Alto Risco
     public driverName?: string;
     public isOnline?: boolean;
     public lastHeartbeat?: Date;
}