
import {throwError as observableThrowError, of as observableOf,  Observable } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Response, Headers, Http } from '@angular/http';

import { SelectItem, TreeNode} from 'primeng/primeng';

import { ConfigService } from '../config/config.service';
import { WorkspaceService } from './workspace.service';
import { DataService } from './data.service';
import { MockService } from './mock.service';
import { Customer} from '../models';
import { customerChildsPermissions, Workspace } from '../models/workspace.data.entity';
import { CustomerChild } from '../models/customerChild.entity';
import { TreeInputModel } from '../models/treeInputModel.entity';
import { AuthenticationService } from 'app/pages/login/authentication.service';
import { CustomerProfile } from '../models/customerProfile.entity';
import { treeNodeEdit } from '../interfaces/interfaceTreeNodeEdit';
import { TranslateJsonService } from './translate-json.service';
import { DefaultFilter } from '../models/defaultFilter.entity';
import { CustomerParentList } from '../models/customerParentList.dto.entity';

@Injectable()
export class CustomerService {

  private alarmArray: any[];
  private baseUrl;
  private treeCustomerChilds: TreeNode[] = [];

  statusOptionsSelect = [
    {label: this.translateJsonService.getTranslateSubs('GLOBAL.1_status'), value: 1},
    {label: this.translateJsonService.getTranslateSubs('GLOBAL.2_status'), value: 2}
  ]

  constructor(private http: Http,
    private configService: ConfigService,
    private dataService: DataService,
    private auth: AuthenticationService,
    protected translateJsonService: TranslateJsonService
    ) {
      this.baseUrl = configService.getApiURI();

      this.statusOptionsSelect.forEach((option) => {
        option.label = this.translateJsonService.getTranslateSubs('GLOBAL.'+option.value+'_status');
      })
  }

  /**
   * Busca Empresas (CustomerProfile)
   */
  getCustomerProfiles(v2: boolean = false): Observable<CustomerProfile[]> {
    let _ws = this.configService.webServices['allCustomerProfiles'];
    let _url = _ws.url(this.baseUrl);
    if (v2) _url+=`&v2=${v2}`;

    return this.http.get(_url, {headers: this.getHttpHeaders()}).pipe(
      map((res : Response) => {
        return res.json();
      }),catchError(this.handleError)
    )
  }

  getCustomerProfilesAntigo(): Observable<CustomerProfile[]> {
    let _ws = this.configService.webServices['allCustomerProfilesAntigo'];

    return this.http.get(_ws.url(this.baseUrl), {headers: this.getHttpHeaders()}).pipe(
      map((res : Response) => {
        return res.json();
      }),catchError(this.handleError)
    )
  }

  getCustomerProfilesByIds(cusIds : number[]): Observable<CustomerProfile[]> {
    let _ws = this.configService.webServices['getCustomerProfilesByIds'];

    return this.http.get(_ws.url(this.baseUrl, cusIds.join(',')), {headers: this.getHttpHeaders()}).pipe(
      map((res : Response) => {
        return res.json();
      }),catchError(this.handleError)
    )
  }

  /**
   * Salva Empresas (CustomerProfile)
   * @param customerProfile Objeto a ser Salvo
   */
  saveNewCustomerProfile(customerProfile : CustomerProfile): Observable<any> {
    let _ws = this.configService.webServices['saveCustomerProfile'];

    return this.http.post(_ws.url(this.baseUrl), customerProfile, {headers: this.getHttpHeaders()}).pipe(
      map((res : Response) => {
        return res.text();
      }),catchError(this.handleError)
    )
  }

  updateGasStatus(customerProfile : CustomerProfile) {
    let _ws = this.configService.webServices['gas'];
    let body = customerProfile
    
    return this.http.put(_ws.url(this.baseUrl, customerProfile.id), body, {headers: this.getHttpHeaders()}).pipe(
      map((res: Response) => {
        return res.json();
      }),
      catchError(this.handleError)
    )
  }

  /**
   * Salva edição de Empresas (CustomerProfile)
   * @param customerProfile Objeto Empresa
   */
  updateCustomerProfile(customerProfile : CustomerProfile): Observable<any> {
    let _ws = this.configService.webServices['updateCustomerProfile'];

    return this.http.put(_ws.url(this.baseUrl), customerProfile, {headers: this.getHttpHeaders()}).pipe(
      map((res : Response) => {
        return res.text();
      }),catchError(this.handleError)
    )
  }

  /**
   * Salva uma Nova Filia
   */
  saveNewCustomerChild(customerChild : CustomerChild): Observable<any> {
    let _ws = this.configService.webServices['saveNewCustomerChild'];

    let string = JSON.stringify({
      "customerProfileId": customerChild.customerId,
      "identification": customerChild.identification,
      "name": customerChild.identification,
      "parentId": customerChild.parentId,
      "status": customerChild.status,
      "focus": true
    });


    return this.http.post(_ws.url(this.baseUrl), string, {headers: this.getHttpHeaders()}).pipe(
      map((res : Response) => {
        return res.text();
      }),catchError(this.handleError)
    )
  }

  updateCustomerChild(customerChild : CustomerChild): Observable<any> {
    let _ws = this.configService.webServices['updateCustomerChild'];

    let string = JSON.stringify({
      "id": customerChild.id,
      "customerProfileId": customerChild.customerId,
      "identification": customerChild.identification,
      "name": customerChild.identification,
      "parentId": customerChild.parentId,
      "status": customerChild.status
    });

    return this.http.put(_ws.url(this.baseUrl), string, {headers: this.getHttpHeaders()}).pipe(
      map((res : Response) => {
        return res.text();
      }),catchError(this.handleError)
    )
  }

  /**
   * Busca os CustomerChilds por ID
   */
  public getCustomerChildsByIds(customerChildIds: number[]): Observable<CustomerChild[]> {
    let _ws = this.configService.webServices['customerChildsByIds'];

    return this.http.post(_ws.url(this.baseUrl), this.buildDefaultFilter(customerChildIds), {headers: this.getHttpHeaders()}).pipe(
      map((res : Response) => {
        return res.json();
      }),catchError(this.handleError)
    )
  }
  
  /**
   * Busca os CustomerParents por IDs
   */
  public getCustomerParentsByIds(filter: DefaultFilter): Observable<CustomerParentList[]> {
    let _ws = this.configService.webServices['customersParents'];

    return this.http.post(_ws.url(this.baseUrl), filter, {headers: this.getHttpHeaders()}).pipe(
      map((res : Response) => {
        return res.json();
      }),catchError(this.handleError)
    )
  }

  private buildDefaultFilter(custIds: number[]): DefaultFilter {
    let defaultFilter = new DefaultFilter();
    defaultFilter.custIds = custIds;
    return defaultFilter;
  }

  getCustomerChildsByCustomerProfile(customerProfileId : number, activeCustomersOnly: boolean = false): Observable<CustomerChild[]> {
    let _ws = this.configService.webServices['customerChildsByCustomerProfile'];

    return this.http.get(_ws.url(this.baseUrl, customerProfileId, activeCustomersOnly), {headers: this.getHttpHeaders()}).pipe(
      map((res : Response) => {
        return res.json();
      }),catchError(this.handleError)
    )
  }

  public getUserAllCustomers():  Observable<CustomerChild> {
    let _ws = this.configService.webServices['allCustomersByCustomerIds']
      , wk: Workspace = this.dataService.getWorkspace();

    return this.http.get(_ws.url(this.baseUrl, wk.currentUser.customerChild), {headers: this.getHttpHeaders()}).pipe(
      map((res: Response) => {
          return res.json();
      }),
      catchError(this.handleError),);
  }

  public getCustomersSelectItem(_customers: Customer[]): SelectItem[] {
    let _result: SelectItem[] = [];
    _customers.forEach((customer:Customer) => {
      _result.push({label: customer.customerChildName, value: customer.id, icon: customer.icon});
    });
    return _result;
  }

  public getUserCustomers():  Observable<CustomerChild>  {
    let _ws = this.configService.webServices['customersByCustomerIds'], wk: Workspace = this.dataService.getWorkspace();
    let _userCustomerChildIds: number[];
    _userCustomerChildIds = wk.userCustomerChildIds.map((customerChildId : customerChildsPermissions) => customerChildId.customer);

    return this.http.get(_ws.url(this.baseUrl, wk.currentUser.customerChild), {headers: this.getHttpHeaders()}).pipe(
      map((res: Response) => {
          return res.json();
      }),
      catchError(this.handleError),);
  }

  private handleError(error: any) {
    let errMsg = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error('Erro:', error);
    return observableThrowError(errMsg);
  }

  private handleErrorV2(error) {
    const err = error.json();

    if(err.message){
      return observableThrowError(err.message);
    }else{
      return observableThrowError(err);
    }
  }

  /**
   * Converte os CustomerChilds para o formato de TreeInputModel
   * @param _customer Lista de filiais no formato CustomerChilds
   */
  convertCustomerChildsToTreeInputModel(_customer : CustomerChild[]): TreeInputModel[] {
    let result: TreeInputModel[] = [];

    _customer.forEach((customerChild : CustomerChild) => {
      if(!customerChild.parentId){
        customerChild.parentId = null;
      }
      result.push({id: customerChild.id, name: customerChild.identification, parentId: customerChild.parentId});
    })

    return result;
  }

  /**
   * Recebe todos os Customer Childs do usuário. Verifica os que são Folha Raiz
   * e procura todos as folhas do mesmo.
  */
  convertTreeCustomerChilds(_customer: CustomerChild[]): TreeNode[] {
      let result: TreeNode[] = [];

      _customer.forEach((customerChild : CustomerChild) => {
        if(customerChild.parentId == null){
          result.push(this.buildNode(customerChild, _customer));
        }else{
          let parent = _customer.find((parentChild : CustomerChild) => {if(parentChild.id == customerChild.parentId){return true}else{return false}});
          if(!parent){
            result.push(this.buildNode(customerChild, _customer));
          }
        }
      })

      return result;
  }

  public convertTreeEditCustomerChilds(_customer: CustomerChild[]): treeNodeEdit[] {
    let result: treeNodeEdit[] = [];

    _customer.forEach((customerChild : CustomerChild) => {
      if(customerChild.parentId == null){
        result.push(this.reBuildNode(customerChild, _customer));
      }else{
        let parent = _customer.find((parentChild : CustomerChild) => {if(parentChild.id == customerChild.parentId){return true}else{return false}});
        if(!parent){
          result.push(this.reBuildNode(customerChild, _customer));
        }
      }
    })

    return result;
  }

  public reBuildNode(customerChild : CustomerChild, _customer : CustomerChild[]){
    return {id: customerChild.id, name: customerChild.identification, status: customerChild.status, integration: customerChild.integration, children: this.searchChilds(customerChild, _customer), selected: false};
  }

  public buildNode(customerChild : CustomerChild, _customer : CustomerChild[]){
    return {id: customerChild.id, name: customerChild.identification, status: customerChild.status, integration: customerChild.integration, children: this.searchChilds(customerChild, _customer)};
  }

  /**
   * Recebe um CustomerChild e verifica dentro da lista todos os filhos do mesmo.
   * Função Recursiva
  */
  public searchChilds(customerChild : CustomerChild, _customer : CustomerChild[]){
    let tree: any[] = [];
    let parentList: CustomerChild[] = _customer.filter((_customer_ : CustomerChild) => _customer_.parentId == customerChild.id);

    if(parentList.length > 0){
      parentList.forEach((parentCustomerChild : CustomerChild) => {
        tree.push(this.buildNode(parentCustomerChild, _customer));
      })
    }
    return tree;
  }

  getHttpHeaders() : Headers {
    let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', this.auth.getToken());

    return headers;
  }

  getCustomersChildInTreeInputFormat(customersChildIds : number[]): Promise<TreeInputModel[]> {
    return new Promise((resolve, reject) => {

      const dataCustomersChild = this.dataService.getAllCustomerChilds();

      if((!dataCustomersChild || dataCustomersChild.length < 1) && customersChildIds.length > 0) {
        this.getCustomerChildsByIds(customersChildIds)
          .subscribe((_customers: CustomerChild[]) => {
            this.dataService.registerCustomerChilds(_customers);

            resolve(this.convertCustomerChildsToTreeInputModel(_customers));
          });
      }else{
        resolve(this.convertCustomerChildsToTreeInputModel(this.dataService.getAllCustomerChilds()));
      }
    })
  }

  enableFaceRecog(customerProfileId: number, hasFaceRecog: boolean): Observable<{ message: string }> {
    let _ws = this.configService.webServices['enableCustomerProfileFaceRecog'];
    let createCollections;

    if(hasFaceRecog){
      createCollections = true;
    }

    return this.http.post(_ws.url(this.baseUrl, customerProfileId, hasFaceRecog, createCollections), null, {headers: this.getHttpHeaders()}).pipe(
      map((res : Response) => {
        return res.json();
      }),catchError(this.handleErrorV2)
    )
  }
}
