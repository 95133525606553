import { Injectable, OnDestroy } from '@angular/core';
import { Subject }    from 'rxjs-compat/Subject';

@Injectable()
export class GlobalState {

  private _data = new Subject<Object>();
  private _dataStream$ = this._data.asObservable();
  private _subscriptions: Map<string, Array<Function>> = new Map<string, Array<Function>>();
  private _eventFired : any = {};

  private _acoes = new Subject<Object>();

  constructor() {
    this._dataStream$.subscribe((data) => this._onEvent(data));
  }

  subscribeAcoes(event: string, callback: Function) {

  }

  notifyDataChanged(event, value) {
      this._data[event] = value;

      this._data.next({
        event: event,
        data: this._data[event]
      });
  }

  subscribe(event: string, callback: Function) {
    let subscribers = this._subscriptions.get(event) || [];
        subscribers.push(callback);

        //console.log("SUBSCRIBE");
    this._subscriptions.set(event, subscribers);
  }

  _onEvent(data: any) {
    //console.log("ON EVENT");
    let subscribers = this._subscriptions.get(data['event']) || [];

    subscribers.forEach((callback) => {
      callback.call(null, data['data']);
    });
  }

  public notifyEventFired(event, value): void {
  	let current = this._eventFired[event];
    if (current !== value) {
      this._eventFired[event] = value;
    }
  }

  public getNotifyEventFired(event): any {
  	return this._eventFired[event];
  }

  notifyEventChanged(event, value) {
    let current = this._data[event];
    if (current !== value) {
      this._data[event] = value;

      this._data.next({
        event: event,
        data: this._data[event]
      });
    }
  }
}
