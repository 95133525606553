import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, throwError as observableThrowError } from "rxjs";
import { CommandVoice } from "../models/command-voice.entity";
import { ConfigService } from "../config/config.service";
import { retry, catchError, map } from "rxjs/operators";
import { AuthenticationService } from "app/pages/login/authentication.service";
import { Http, Headers } from "@angular/http";
import { environment } from "environments/environment";
import { TreeInputModel } from "../models/treeInputModel.entity";

@Injectable()
export class CommandVoiceService {

  public commandVoices$: BehaviorSubject<CommandVoice[]> = new BehaviorSubject<CommandVoice[]>([]);
  public isUpdating$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private URL_PATH: string = 'v2/commands-voice';

  constructor(
    private http: Http,
    private config: ConfigService,
    private auth: AuthenticationService,
  ){}

  public setCommandsVoice(commandVoices: CommandVoice[]): void {
    this.commandVoices$.next(commandVoices);
  }

  public getCommandsVoice$(): Observable<CommandVoice[]> {
    return this.commandVoices$.asObservable();
  }

  public addCommandVoice(commandVoice: CommandVoice): void {
    const values = this.commandVoices$.getValue();

    this.commandVoices$.next([...values, commandVoice]);
  }

  public loadCommandsVoice(customersChild?: number[], active?: boolean): Observable<CommandVoice[]> {
    this.isUpdating$.next(true);

    let params = [];

    if(customersChild && customersChild.length > 0) {
      params.push(`customersChild=${customersChild.join(',')}`);
    }

    if(typeof active !== 'undefined'){
      params.push(`active=${active}`);
    }

    const URL = `${this.config.getApiURI()}/${this.URL_PATH}/${params.length>0 ? '?'+params.join('&') : ''}`;

    return this.http.get(URL, { headers: this.getHttpHeaders() }).pipe(
      map((res: Response) => {
        return res.json();
      }),
      catchError(this.handleError)
    )
  }

  public loadCommandsVoiceFiltered(customersChild?: number[], active?: boolean): Observable<CommandVoice[]> {
    this.isUpdating$.next(true);

    let params = [];

    if(customersChild && customersChild.length > 0) {
      params.push(`customersChild=${customersChild.join(',')}`);
    }

    if(typeof active !== 'undefined'){
      params.push(`active=${active}`);
    }

    const URL = `${this.config.getApiURI()}/${this.URL_PATH}/filtered/${params.length>0 ? '?'+params.join('&') : ''}`;

    return this.http.get(URL, { headers: this.getHttpHeaders() }).pipe(
      map((res: Response) => {
        return res.json();
      }),
      catchError(this.handleError)
    )
  }

  public createCommandVoice(commandVoice: CommandVoice): Observable<CommandVoice> {
    return this.http.post(`${this.config.getApiURI()}/${this.URL_PATH}/`, JSON.stringify(commandVoice), { headers: this.getHttpHeaders() }).pipe(
      map((res: Response) => {
        this.addCommandVoice(commandVoice);
        return res.json();
      }),
      catchError(this.handleError)
    )
  }

  public sendCommandVoice(commandVoice: CommandVoice, imei: String) {
    return this.http.post(`${environment.urlFocusRedis}/wav`, JSON.stringify({ "urlAudio": commandVoice.urlAudio, "imei": imei })).pipe(
      map((res: Response) => {
        return res;
      }),
      catchError(this.handleError)
    )
  }

  public convertMessageToTreeInputModel(messages: CommandVoice[]) {
    let result: TreeInputModel[] = [];
    
    messages.forEach((msg : CommandVoice) => {
      result.push({id: msg.id, name: msg.message, urlAudio: msg.urlAudio, parentId: null });
    })
    
    return result;
  }

  private handleError(err: any) {
    let error = err.json();

    return observableThrowError( (error.message) ? error.message : error );
  }

  private getHttpHeaders() : Headers {
    let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', this.auth.getToken());

    return headers;
  }
}