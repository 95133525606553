export enum AlarmTypeEnum {
	"TYPES-ALERT.unknown" = 0,
    "TYPES-ALERT.face_missing" = 1,
    "TYPES-ALERT.atten_warning" = 2,
    "TYPES-ALERT.look_around_warning" = 3,
    "TYPES-ALERT.phone_warning" = 4,
    "TYPES-ALERT.change_warning" = 5,
    "TYPES-ALERT.tired" = 6,
    "TYPES-ALERT.yawm_warning" = 7,
    "TYPES-ALERT.cam_coverd_warning" = 8,
    "TYPES-ALERT.smoke_warning" = 9,
    "TYPES-ALERT.impact" = 10,
    "TYPES-ALERT.pedestrian" = 11,
    "TYPES-ALERT.tail_gating" = 12,
    "TYPES-ALERT.swerving" = 13,
    "TYPES-ALERT.over_speed" = 14,
    "ALERTS-INFRACTIONS.infraction_type_over_speed" = 3001,
    "ALERTS-INFRACTIONS.infraction_type_no_seat_belt" = 3004,
    "ALERTS-INFRACTIONS.infraction_type_abrupt_break" = 3006,
    "ALERTS-INFRACTIONS.infraction_type_abrupt_speed" = 3007,
    "TYPES-ALERT.calibration_error" = 162,
    "TYPES-ALERT.no_seatbelt" = 15,
    "TYPES-ALERT.fall" = 16,
    "TYPES-ALERT.low_spo2" = 17,
    "TYPES-ALERT.bradycardia" = 22,
    "TYPES-ALERT.tachycardia" = 23,
    "TYPES-ALERT.rider_detection" = 24,
    "TYPES-ALERT.drowsiness_l1_confirmation" = 25,
    "TYPES-ALERT.drowsiness_l2_confirmation" = 26,
}

export enum AlarmTypeEnumEN {
	"TYPES-ALERT.unknown" = 0,
    "TYPES-ALERT.face_missing" = 1,
    "TYPES-ALERT.atten_warning" = 2,
    "TYPES-ALERT.look_around_warning" = 3,
    "TYPES-ALERT.phone_warning" = 4,
    "TYPES-ALERT.change_warning" = 5,
    "TYPES-ALERT.tired" = 6,
    "TYPES-ALERT.yawm_warning" = 7,
    "TYPES-ALERT.cam_coverd_warning" = 8,
    "TYPES-ALERT.smoke_warning" = 9,
    "TYPES-ALERT.impact" = 10,
    "TYPES-ALERT.pedestrian" = 11,
    "TYPES-ALERT.tail_gating" = 12,
    "TYPES-ALERT.swerving" = 13,
    "TYPES-ALERT.over_speed" = 14,
    "ALERTS-INFRACTIONS.infraction_type_over_speed" = 3001,
    "ALERTS-INFRACTIONS.infraction_type_no_seat_belt" = 3004,
    "ALERTS-INFRACTIONS.infraction_type_abrupt_break" = 3006,
    "ALERTS-INFRACTIONS.infraction_type_abrupt_speed" = 3007,
    "TYPES-ALERT.calibration_error" = 162,
    "TYPES-ALERT.no_seatbelt" = 15,
    "TYPES-ALERT.fall" = 16,
    "TYPES-ALERT.low_spo2" = 17,
    "TYPES-ALERT.bradycardia" = 22,
    "TYPES-ALERT.tachycardia" = 23,
    "TYPES-ALERT.rider_detection" = 24,
    "TYPES-ALERT.drowsiness_l1_confirmation" = 25,
    "TYPES-ALERT.drowsiness_l2_confirmation" = 26,
}

export enum AlarmTypeEnumES {
	"TYPES-ALERT.unknown" = 0,
    "TYPES-ALERT.face_missing" = 1,
    "TYPES-ALERT.atten_warning" = 2,
    "TYPES-ALERT.look_around_warning" = 3,
    "TYPES-ALERT.phone_warning" = 4,
    "TYPES-ALERT.change_warning" = 5,
    "TYPES-ALERT.tired" = 6,
    "TYPES-ALERT.yawm_warning" = 7,
    "TYPES-ALERT.cam_coverd_warning" = 8,
    "TYPES-ALERT.smoke_warning" = 9,
    "TYPES-ALERT.impact" = 10,
    "TYPES-ALERT.pedestrian" = 11,
    "TYPES-ALERT.tail_gating" = 12,
    "TYPES-ALERT.swerving" = 13,
    "TYPES-ALERT.over_speed" = 14,
    "ALERTS-INFRACTIONS.infraction_type_over_speed" = 3001,
    "ALERTS-INFRACTIONS.infraction_type_no_seat_belt" = 3004,
    "ALERTS-INFRACTIONS.infraction_type_abrupt_break" = 3006,
    "ALERTS-INFRACTIONS.infraction_type_abrupt_speed" = 3007,
    "TYPES-ALERT.calibration_error" = 162,
    "TYPES-ALERT.no_seatbelt" = 15,
    "TYPES-ALERT.fall" = 16,
    "TYPES-ALERT.low_spo2" = 17,
    "TYPES-ALERT.bradycardia" = 22,
    "TYPES-ALERT.tachycardia" = 23,
    "TYPES-ALERT.rider_detection" = 24,
    "TYPES-ALERT.drowsiness_l1_confirmation" = 25,
    "TYPES-ALERT.drowsiness_l2_confirmation" = 26
}
