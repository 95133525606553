import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Response, Headers, Http } from '@angular/http';
import { ConfigService } from "../config/config.service";
import { AuthenticationService } from 'app/pages/login/authentication.service';
import { environment } from 'environments/environment';

@Injectable()
export class MovimentStatusService {

    private baseUrl: string;

    constructor(
        private http: Http,
        private configService: ConfigService,        
        private auth: AuthenticationService
    ) { 
      this.baseUrl = configService.getApiURI();
    }

    public getMovimentStatus(imeis: string[]): Promise<any>{
        let _ws = this.configService.webServicesV2['getMovimentStatus'];

        return new Promise((resolve, reject) => {

            this.get(_ws.url(`${environment.urlApi}telemetry`, imeis))
                .subscribe(
                    response => { 
                        resolve(response.data); 
                    }, error => reject(error))

        });
    }

    private get(URL: string) {
        return this.http.get(URL, { headers: this.getHttpHeaders()}).pipe(
            map((res: Response) => {
              return res.json();
            }),
            catchError(this.handleError)
          )
    }

    private handleError(error: any) {
        return observableThrowError(error);
    }

    getHttpHeaders() : Headers {
        let headers = new Headers();
    
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', this.auth.getToken());
    
        return headers;
    }
}