import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { User } from '../models';
import { DefaultFilter } from '../models/defaultFilter.entity';
import { FilterAlarms } from '../models/filter.alarms.entity';

@Injectable()
export class ConfigService {

    _apiURI: string;
    _api_Port: string;
    _api_Host: string;
    accessCustomerKey: string = 'CREARE';
    private currentPainelId: number = 0;
    private currentUser: User;

    private mockUserId: number = 1; // testes mockados


    constructor() {
        this._api_Port = '9080';
        if (window.location.hostname.indexOf('localhost') > -1
            || window.location.hostname.indexOf('127.0.0') > -1
            || window.location.hostname.indexOf('192.168.0.24') > -1) {
            this._api_Host = '192.168.0.24' // endereço interno
        } else {
            this._api_Host = '177.135.102.82' // endereço externo
        };

        this._apiURI = environment.urlApi;
    }

    public getCurrentPainelId(): number {
        return this.currentPainelId;
    }

    public setCurrentPainelId(currentPainelId): void {
        this.currentPainelId = currentPainelId;
    }

    public getApiURI() {
        return this._apiURI;
    }

    public getApiHost() {
        return this._apiURI.replace('api/', '');
    }

    public getAccessCustomerKey(): string {
        return this.accessCustomerKey;
    }

    public setAccessCustomerKey(key: string) {
        this.accessCustomerKey = key;
    }

    private wsHeaders = {
        credencial: {
            usuario: 'road'
            , senha: 'road1234'
        }
    }

    /**
     * Converte a data para o formato ISOString sem perder o Fuso.
     * @param dataUTC Data para conversão
     */
    public convertDateToIsoString(dataUTC: Date) {
        return dataUTC.toISOString();
    }

    webServicesV2: any = {
        mediaDownload: {
            url: function (apiUrl) { return `${apiUrl}v2/media/download`}
        },
        v2LastHeartbeat: {
            url: function (apiUrl) { return apiUrl + 'v2/heartbeat/last' }
        },
        checkDeviceByImei: {
            url: function (apiUrl, imei) { return apiUrl + `v2/heartbeat/history?imei=${imei}&limit=30` }
        },
        deleteEquipment: {
            url: function (apiUrl, equipId) { return apiUrl + 'equipments/' + equipId }
        },
        reportTrend: {
            url: function (apiUrl, customersChild, startDate, endDate, alertTypes) { return apiUrl + '/v2/report/trend-alert?startDateS=' + startDate + '&endDateS=' + endDate + '&customersChild=' + customersChild + '&vehicles&drivers&alertTypes=' + alertTypes }
        },
        reportResume: {
            url: function (apiUrl, customersChild, startDate, endDate, alertTypes) { return apiUrl + '/v2/report/resume-alert?startDateS=' + startDate + '&endDateS=' + endDate + '&customersChild=' + customersChild + '&vehicles&drivers&alertTypes=' + alertTypes }
        },
        reportTreatment: {
            url: function (apiUrl, customersChild, startDate, endDate, alertTypes) { return apiUrl + '/v2/report/treatments?startDateS=' + startDate + '&endDateS=' + endDate + '&customersChild=' + customersChild + '&vehicles&drivers&alertTypes=' + alertTypes }
        },
        reportDaysOrHours: {
            url: function (apiUrl, customersChild, startDate, endDate, alertTypes) { return apiUrl + '/v2/report/days-and-hours?startDateS=' + startDate + '&endDateS=' + endDate + '&customersChild=' + customersChild + '&vehicles&drivers&alertTypes=' + alertTypes }
        },
        getCaptureByAsset: {
            url: function (apiUrl, assetId, date) { return `${apiUrl}/capture/assets/${assetId}/date/${date}/` }
        },
        sendCaptureByAsset: {
            url: function (apiUrl, assetId, date) { return `${apiUrl}/capture/send-command/assets/${assetId}/date/${date}/` }
        },
        logout: {
            url: function (apiUrl) { return `${apiUrl}/v2/access-log/logout` }
        },
        getAccessLogByUserIdAndDateInterval: {
            url: (apiUrl, userId, dateStart, dateEnd) => { return `${apiUrl}/v2/access-log/users/${userId}/dateStart/${this.convertDateToIsoString(dateStart)}/dateEnd/${this.convertDateToIsoString(dateEnd)}/` }
        },
        getAccessByEmail: {
            url: function (apiUrl) { return `${apiUrl}/api/users/video-data` }
        },
        getAllAccessControlByCustomerProfileId: {
            url: (apiUrl, customerProfileId, dateStart, dateEnd) => { return `${apiUrl}/v2/access-log/customer-profile/${customerProfileId}/dateStart/${dateStart}/dateEnd/${dateEnd}/` }
        },
        getReportLinks: {
            url: (apiUrl) => `${apiUrl}/v2/report/link`
        },
        getReportsGeneral: {
            url: (apiUrl: string) => `${apiUrl}/v2/powerbi/reports`
        },
        getEmbedInfo: {
            url: (apiUrl: string) => `${apiUrl}/v2/powerbi/embed-info`
        },
        getStreamingsByVehicleId: {
            url: (apiUrl, vehiclesId) => `${apiUrl}/v2/equipment/streaming?vehicles=${vehiclesId.join(',')}`
        },
        getCurrentPositionsByDevices: {
            url: (apiUrl, devicesId) => `${apiUrl}positions/realtime?deviceIdList=${devicesId.join(',')}`
        },
        getCurrentPositionsByDeviceIdList: {
            url: (apiUrl) => `${apiUrl}positions/realtime`
        },
        getCurrentPositionsByAssets: {
            url: (apiUrl, assetsId) => `${apiUrl}positions/realtime?assetIdList=${assetsId.join(',')}`
        },
        getCurrentPositionsByAssetsIdList: {
            url: (apiUrl: string) => `${apiUrl}/positions/realtime`,
        },
        getLastPositionsByDevice: {
            url: (apiUrl, deviceId, limit) => `${apiUrl}positions/last?deviceId=${deviceId}&limit=${limit}`
        },
        getTracking: {
            url: (apiUrl, assetId, startDate, endDate) => `${apiUrl}positions/route-reconstruction?assetId=${assetId}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
        },
        getTrackingByAsset: {
            url: (apiUrl, assetId, startDate, endDate) => `${apiUrl}/positions/route-reconstruction?asset_id=${assetId}&start_date=${startDate.toISOString()}&end_date=${endDate.toISOString()}`
        },
        getTrackingByDevice: {
            url: (apiUrl, deviceId, startDate, endDate) => `${apiUrl}positions/route-reconstruction?deviceId=${deviceId}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
        },
        getEvents: {
            url: (apiUrl) => `${apiUrl}/`
        },
        saveEventAction: {
            url: (apiUrl, eventId) => `${apiUrl}/${eventId}`
        },
        getTagsByDevice: {
            url: (apiUrl) => `${apiUrl}v2/tag/device`
        },
        getDeviceStatus: {
            url: (apiUrl, deviceType, deviceId) => `${apiUrl}/voiceMessage/deviceStatus?deviceType=${deviceType}&deviceId=${deviceId}`
        },
        checkOnlineStatusByCustomersChildAndAssetType: {
            url: (apiUrl, customersChildId, assetType) => `${apiUrl}/assets/checkOnlineStatus?customersChild=${customersChildId.join(',')}&assetType=${assetType}`
        },
        checkOnlineStatusByAssetId: {
            url: (apiUrl, assetId) => `${apiUrl}/assets/checkOnlineStatus?assetId=${assetId}`
        },
        getMovimentStatus: {
            url: (apiUrl, devicesId) => `${apiUrl}/moviment-status?devices_id=${devicesId.join(',')}`
        },
        getAuditMedia: {
            url: (apiUrl, auditId) => `${apiUrl}v2/audit/by-audit/${auditId}`
        },
        setUpSignature: {
            url: (apiUrl) => `${apiUrl}v2/audit/driver-signature`
        },
        sendCommandTask: {
            url: (apiUrl) => `${apiUrl}`
        },
        getTasksLog: {
            url: (apiUrl, deviceId) => `${apiUrl}/logs?device_imei=${deviceId}`
        },
        getTaskLogByCommands: {
            url: (apiUrl, deviceId, commands) => `${apiUrl}/logs?device_imei=${deviceId}&commands=${commands}`
        },
        getCompletedTaskLogByCommands: {
            url: (apiUrl, deviceId, commands) => `${apiUrl}/logs?device_imei=${deviceId}&commands=${commands}&completed_task=true`
        },
        getLastTaskLogByCommands: {
            url: (apiUrl, deviceId, commands) => `${apiUrl}/logs?device_imei=${deviceId}&commands=${commands}&last_task=true`
        },
        getLastAlarmsNoFilter: {
            url: (apiUrl, deviceId, queryLimit) => `${apiUrl}/alarm/${deviceId}/imei?limit=${queryLimit}`
        },
        getPlaybackRoutes: {
            url: (apiUrl: string) => `${apiUrl}v2/playback/files`
        },
        getPlaybackUrl: {
            url: (apiUrl: string) => {
                return `${apiUrl}v2/playback/streaming/url`;
            }
        },
        // Remover quando CORE Application for implementado!
        getFrotaJSessionId: {
            url: (apiUrl) => apiUrl + 'users/frota-jsession'
        },
        getOperationframe: {
            url: (apiUrl) => apiUrl + 'v1/get_filtered_vehicles'
        },
        assetsBySecheduledTrips: {
            url: (apiUrl) => apiUrl + '/operacional-map/filter'
        },
        positionsByFance: {
            url: function (apiUrl, customerId, fenceSpeed) {
                if(fenceSpeed != null) {
                    return apiUrl + `positions/by-fence?customer_id=${customerId}&speed_limit=${fenceSpeed}` 
                } else {
                    return apiUrl + `positions/by-fence?customer_id=${customerId}`
                }
            } 
        },
        getVehiclesWithTypesByCustomerChildIds: {
            url: function (apiUrl, customerChildIdList, vehicleTypeIdList) { return apiUrl + 'v2/vehicle/by-customer-child-ids?customerChildIds=' + customerChildIdList + '&types=' + vehicleTypeIdList + '&active=true'}
        }
    }

    public webServices: any = {
        assetsIdByCustomersChildAndEquipType: {
            url: (apiUrl, equipmentType) => `${apiUrl}assets/customersChild?equipmentTypeId=${equipmentType}`
        },
        repositoryDataByFilter: {
            mock: false // true
        },
        repositoryAlarmDataByAlarmId: {
            mock: false // true
        },
        repositoryDriverDataByDriverId: {
            mock: false // true
        },
        repositoryAssetDataByAssetId: {
            mock: false // true
        },
        workspace: {
            id: 'workspace'
            , header: this.wsHeaders
            , url: function (apiUrl, _userId) { return apiUrl + '/users/workspace/' + _userId }
            , mockCurrentUserId: this.mockUserId
            , mock: false // true
        },
        workspaceByLogin: {
            id: 'workspaceByLogin'
            , header: this.wsHeaders
            , url: function (apiUrl, _userId) { return apiUrl + 'users/' + _userId + '/workspace' }
            , mockCurrentUserId: this.mockUserId
            , mock: false // true
        },
        alarmById: {
            id: 'alarmById'
            , header: this.wsHeaders
            , url: function (apiUrl: string, _alarmId: number) {
                return apiUrl + 'alarmById' + '/' + _alarmId;
            }
            , urlMock: ''
            , method: 'getAlarmById'
            , mock: false // true
        },
        updatedAlarmsByAuditId: {
            id: 'updatedAlarmsByAuditId'
            , header: this.wsHeaders
            , url: (apiUrl: string, _auditId: number) => `${apiUrl}alarmsByAudit/updateRiskRating/${_auditId}`
            , urlMock: ''
            , mock: false // true
        },
        alarmsByFilter: {
            id: 'alarmsByFilter'
            , header: this.wsHeaders
            , url: function (apiUrl: string, _filter: FilterAlarms) {
                if (_filter
                    //&&_filter.selectedCustomers
                    && _filter.selectedDrivers
                    && _filter.selectedDrivers.length > 0
                    && _filter.selectedDateStart
                    && _filter.selectedDateEnd) {
                    return apiUrl + 'alarmsByDateInterval'
                        + '/drivers/' + _filter.selectedDrivers
                        + '/' + _filter.selectedDateStart.toISOString()
                        + '/' + _filter.selectedDateEnd.toISOString()
                } else if (_filter
                    && _filter.selectedCustomers
                    && _filter.selectedCustomers.length > 0
                    && _filter.selectedDateStart
                    && _filter.selectedDateEnd) {
                    return apiUrl + 'alarmsByDateInterval'
                        + '/customers/' + _filter.selectedCustomers
                        + '/' + _filter.selectedDateStart.toISOString()
                        + '/' + _filter.selectedDateEnd.toISOString()
                } else if (_filter
                    && _filter.selectedDrivers
                    && _filter.selectedDrivers.length > 0
                    && _filter.selectedDateStart
                    && _filter.selectedDateEnd
                    && _filter.selectedAlarmTypes) {
                    return apiUrl + 'alarmsByDateInterval'
                        + '/drivers/' + _filter.selectedDrivers
                        + '/' + _filter.selectedDateStart.toISOString()
                        + '/' + _filter.selectedDateEnd.toISOString()
                        + '/alarmTypes/' + _filter.selectedAlarmTypes.join(',')
                } else if (_filter
                    && _filter.selectedCustomers
                    && _filter.selectedCustomers.length > 0
                    && _filter.selectedDateStart
                    && _filter.selectedDateEnd
                    && _filter.selectedAlarmTypes) {
                    return apiUrl + 'alarmsByDateInterval'
                        + '/customers/' + _filter.selectedCustomers
                        + '/' + _filter.selectedDateStart.toISOString()
                        + '/' + _filter.selectedDateEnd.toISOString()
                        + '/alarmTypes/' + _filter.selectedAlarmTypes.join(',');
                } else {
                    return null;
                }
            }
            , urlMock: '/assets/mock/alarm.type.json'
            , method: 'getAlarmsTypes'
            , mock: false // true
        },
        alarmTypes: {
            id: 'alarmTypes'
            , header: this.wsHeaders
            , url: function (apiUrl: string) { return apiUrl + 'alarms' }
            , urlMock: '/assets/mock/alarm.type.json'
            , method: 'getAlarmsTypes'
            , mock: true // true
        },
        alarms: {
            id: 'alarms'
            , header: this.wsHeaders
            , url: function (apiUrl) { return apiUrl + 'alarms' }
            , urlMock: '/assets/mock/alarm.json'
            , method: 'getAllAlarms'
            , mock: true // true
        },
        allCustomerProfiles: {
            id: 'allCustomerProfiles'
            , header: this.wsHeaders
            , url: function (apiUrl) { return apiUrl + 'v2/customersProfile/?active=true' }
            , urlMock: ''
            , method: 'allCustomerProfiles'
            , mock: false
        },
        allCustomerProfilesAntigo: {
            id: 'allCustomerProfilesAntigo'
            , header: this.wsHeaders
            , url: function (apiUrl) { return apiUrl + 'customersProfile' }
            , urlMock: ''
            , method: 'allCustomerProfilesAntigo'
            , mock: false
        },
        alarmTypesByFilterDefault: {
            id: "alarmTypesByFilterDefault",
            header: this.wsHeaders,
            url: (apiUrl: string, filter: DefaultFilter) => {
                if (filter.custIds && filter.custIds.length > 0) {
                    return apiUrl + "alarmsByDateInterval/customers"
                } else {
                    return null;
                }
            },
            urlMock: '/assets/mock/alarm.type.json',
            method: 'getAlarmsTypes',
            mock: false
        },
        alarmTypesByFilter: {
            id: 'alarmTypes'
            , header: this.wsHeaders
            , url: (apiUrl: string, _filter: FilterAlarms) => {
                if (_filter
                    && _filter.selectedDrivers
                    && _filter.selectedDrivers.length > 0
                    && _filter.selectedDateStart
                    && _filter.selectedDateEnd
                    && _filter.selectedAlarmTypes
                    && _filter.selectedAlarmTypes.length > 0) {
                    return apiUrl + 'alarmsByDateInterval'
                        + '/drivers/' + _filter.selectedDrivers
                        + '/' + this.convertDateToIsoString(_filter.selectedDateStart)
                        + '/' + this.convertDateToIsoString(_filter.selectedDateEnd)
                        + '/alarmTypes/' + _filter.selectedAlarmTypes.join(',');
                } else if (_filter
                    && _filter.selectedVehicles
                    && _filter.selectedVehicles.length > 0
                    && _filter.selectedDateStart
                    && _filter.selectedDateEnd
                    && _filter.selectedAlarmTypes
                    && _filter.selectedAlarmTypes.length > 0) {
                    return apiUrl + 'alarmsByDateInterval'
                        + '/vehicles/' + _filter.selectedVehicles
                        + '/' + this.convertDateToIsoString(_filter.selectedDateStart)
                        + '/' + this.convertDateToIsoString(_filter.selectedDateEnd)
                        + '/alarmTypes/' + _filter.selectedAlarmTypes.join(',');
                } else if (_filter
                    && _filter.selectedCustomers
                    && _filter.selectedCustomers.length > 0
                    && _filter.selectedDateStart
                    && _filter.selectedDateEnd
                    && _filter.selectedAlarmTypes
                    && _filter.selectedAlarmTypes.length > 0) {
                    return apiUrl + 'alarmsByDateInterval'
                        + '/customers/' + _filter.selectedCustomers
                        + '/' + this.convertDateToIsoString(_filter.selectedDateStart)
                        + '/' + this.convertDateToIsoString(_filter.selectedDateEnd)
                        + '/alarmTypes/' + _filter.selectedAlarmTypes.join(',');
                } else if (_filter
                    && _filter.selectedVehicles
                    && _filter.selectedVehicles.length > 0
                    && _filter.selectedDrivers
                    && _filter.selectedDrivers.length > 0
                    && _filter.selectedDateStart
                    && _filter.selectedDateEnd
                    && _filter.selectedAlarmTypes
                    && _filter.selectedAlarmTypes.length > 0) {
                    return apiUrl + 'alarmsByDateInterval'
                        + '/drivers/' + _filter.selectedDrivers
                        + '/vehicles/' + _filter.selectedVehicles
                        + '/' + this.convertDateToIsoString(_filter.selectedDateStart)
                        + '/' + this.convertDateToIsoString(_filter.selectedDateEnd)
                        + '/alarmTypes/' + _filter.selectedAlarmTypes.join(',');
                } else {
                    return null;
                }
            }
            , urlMock: '/assets/mock/alarm.type.json'
            , method: 'getAlarmsTypes'
            , mock: false // true
        },
        auditByFilterDefault: {
            id: "auditByFilterDefault",
            header: this.wsHeaders,
            url: (apiUrl: string, filter: DefaultFilter) => {
                if ((filter.driverIds && filter.driverIds.length > 0) || (filter.vehicleIds && filter.vehicleIds.length > 0)) {
                    return apiUrl + "alarmsAuditByDateInterval/asset"
                } else if (filter.custIds && filter.custIds.length > 0) {
                    return apiUrl + "alarmsAuditByDateInterval/customers"
                } else if (filter.driverIds && filter.driverIds.length > 0 && filter.vehicleIds && filter.vehicleIds.length > 0) {
                    return apiUrl + "alarmsAuditByDateInterval/allAsset"
                }
            }
        },
        auditAndInfractionsByFilterDefault: {
            id: "auditAndInfractionsByFilterDefault",
            header: this.wsHeaders,

            url: (apiUrl:string, filter: DefaultFilter) => {
                return apiUrl + "infractionAlarmAuditByDateInterval/asset"
            }
        },
        alarmsAndInfractionsDefault: {
            id: "alarmsAndInfracrionsDefault",
            header: this.wsHeaders,
            url: (apiUrl:string, filter: DefaultFilter) => {
                if (filter.vehicleIds && filter.vehicleIds.length > 0) {
                    return apiUrl + "infractionAlarmByDateInterval/vehicles"
                } else if ((filter.custIds && filter.custIds.length > 0) && !(filter.driverIds && filter.driverIds.length > 0) && !(filter.vehicleIds && filter.vehicleIds.length > 0)) {
                    return apiUrl + "infractionAlarmByDateInterval/customers"
                } else if ( (filter.custIds && filter.custIds.length > 0) && (filter.driverIds && filter.driverIds.length > 0)) {
                    return apiUrl + "infractionAlarmByDateInterval/drivers"
                }
            }
        },
        auditByFilters: {
            id: 'auditByFilters'
            , header: this.wsHeaders
            , url: (apiUrl: string, _filter: FilterAlarms) => {
                if (_filter
                    && _filter.selectedVehicles
                    && _filter.selectedVehicles.length > 0
                    && _filter.selectedDateStart
                    && _filter.selectedDateEnd) {
                    return apiUrl + 'alarmsAuditByDateInterval'
                        + '/asset/' + _filter.selectedVehicles
                        + '/' + this.convertDateToIsoString(_filter.selectedDateStart)
                        + '/' + this.convertDateToIsoString(_filter.selectedDateEnd);
                } else if (_filter
                    && _filter.selectedDrivers
                    && _filter.selectedDrivers.length > 0
                    && _filter.selectedDateStart
                    && _filter.selectedDateEnd) {
                    return apiUrl + 'alarmsAuditByDateInterval'
                        + '/asset/' + _filter.selectedDrivers
                        + '/' + this.convertDateToIsoString(_filter.selectedDateStart)
                        + '/' + this.convertDateToIsoString(_filter.selectedDateEnd);
                } else if (_filter
                    && _filter.selectedCustomers
                    && _filter.selectedCustomers.length > 0
                    && _filter.selectedDateStart
                    && _filter.selectedDateEnd) {
                    return apiUrl + 'alarmsAuditByDateInterval'
                        + '/customers/' + _filter.selectedCustomers
                        + '/' + this.convertDateToIsoString(_filter.selectedDateStart)
                        + '/' + this.convertDateToIsoString(_filter.selectedDateEnd);
                } else if (_filter
                    && _filter.selectedVehicles
                    && _filter.selectedVehicles.length > 0
                    && _filter.selectedDrivers
                    && _filter.selectedDrivers.length > 0
                    && _filter.selectedDateStart
                    && _filter.selectedDateEnd) {
                    return apiUrl + 'audit'
                        + '/vehicles/' + _filter.selectedVehicles
                        + '/drivers/' + _filter.selectedDrivers
                        + '/datestart/' + this.convertDateToIsoString(_filter.selectedDateStart)
                        + '/dateend/' + this.convertDateToIsoString(_filter.selectedDateEnd);
                } else {
                    return null;
                }
            }
            , urlMock: '/assets/mock/alarm.type.json'
            , method: 'getAlarmsTypes'
            , mock: false // true
        },
        countAuditGroupDisk: {
            id: 'countAuditGroupDisk'
            , url: (apiUrl) => {
                return `${apiUrl}/v2/audit/count`
            }
        },
        drivers: {
            id: 'drivers'
            , header: this.wsHeaders
            , url: function (apiUrl) { return apiUrl + 'drivers' }
            , urlMock: '/assets/mock/drivers.json'
            , method: 'getAllDrivers'
            , mock: true // true
        },
        driverById: {
            id: 'driverById'
            , header: this.wsHeaders
            , url: function (apiUrl: string, _driverId: number) {
                return apiUrl + '/drivers/driverById' + '/' + _driverId;
            }
            , urlMock: ''
            , method: 'getDriverById'
            , mock: false // true
        },
        customers: {
            id: 'customers'
            , header: this.wsHeaders
            , url: function (apiUrl, paramIds) { return apiUrl + 'customers/' + paramIds }
            , urlMock: '/assets/mock/customers.json'
            , method: 'getAllCustomers'
            , mockCurrentUserId: this.mockUserId
            , mock: false // true
        },
        customersParents: {
            id: 'customersParents'
            , header: this.wsHeaders
            , url: function (apiUrl: string) {
                return apiUrl + 'customers/customerParents?'
            }
            , method: 'getCustomerParentsByIds'
        },
        customerContactsByAssetId: {
            id: 'customerContactsByAssetId'
            , header: this.wsHeaders
            , url: function (apiUrl, assetId) { return apiUrl + 'contacts/asset/' + assetId + '/list?getByAssetCustomerChild=true&recursively=true&onDuty=true' }
            , urlMock: '/assets/mock/contacts.json'
            , method: 'getCustomerContactsByAssetId'
            , mockCurrentUserId: this.mockUserId
            , mock: false
        },
        customerContactsByCustomerChildId: {
            id: 'customerContactsByCustomerChildId'
            , header: this.wsHeaders
            , url: function (apiUrl, customerChildId) { return apiUrl + 'contacts/customer/' + customerChildId + '/list?onDuty=true&recursively=true' }
            , urlMock: '/assets/mock/contacts.json'
            , method: 'getCustomerContactsByCustomerChildId'
            , mockCurrentUserId: this.mockUserId
            , mock: false
        },
        allCustomersByCustomerIds: {
            id: 'allCustomersByCustomerIds'
            , header: this.wsHeaders
            , url: function (apiUrl, customerIds?) { return apiUrl + 'customers/' + customerIds + '/allCustomerChildsCustomer' }
            , urlMock: '/assets/mock/drivers.json'
            , method: 'allCustomerChildsCustomer'
            , mock: false // true
        },
        customersByCustomerIds: {
            id: 'customersByCustomerIds'
            , header: this.wsHeaders
            , url: function (apiUrl, customerIds?) { return apiUrl + 'customers/' + customerIds + '/customerChilds' }
            , urlMock: '/assets/mock/drivers.json'
            , method: 'getCustomerByCustomerIds'
            , mock: false // true
        },
        driversByCustomerIdAndType: {
            id: 'driversByCustomerIdAndType'
            , header: this.wsHeaders
            , url: function (apiUrl, customerIds?) { return apiUrl + 'assets/' + customerIds + '/vehiclesOrDriversByCustomerChild/1000/assetType' }
            , urlMock: '/assets/mock/drivers.json'
            , method: 'getAllDriversByCustomerIdAndType'
            , mock: false
        },
        assetsByCustomerIdAndType: {
            id: 'driversByCustomerIdAndType'
            , header: this.wsHeaders
            , url: function (apiUrl, customerIds?, assetType?) { return apiUrl + 'assets/' + customerIds + '/vehiclesOrDriversByCustomerChild/' + assetType + '/assetType' }
            , urlMock: '/assets/mock/drivers.json'
            , method: 'getAllDriversByCustomerIdAndType'
            , mock: false
        },
        assetByManyCustomerIdAndType: {
            id: 'assetByManyCustomerIdAndType'
            , header: this.wsHeaders
            , url: function (apiUrl, customerIds, assetType) { return apiUrl + 'assets/' + customerIds + '/vehiclesOrDriversByManyCustomerChilds/' + assetType + '/assetType' }
            , urlMock: '/assets/mock/drivers.json'
            , method: 'assetByManyCustomerIdAndType'
            , mock: false
        },
        driversByCustomerIds: {
            id: 'driversByCustIds'
            , header: this.wsHeaders
            , url: function (apiUrl, customerIds?) { return apiUrl + 'drivers/' + 'customers/' + customerIds }
            , urlMock: '/assets/mock/drivers.json'
            , method: 'getAllDrivers'
            , mock: false // true
        },
        assetById: {
            id: 'assetById'
            , header: this.wsHeaders
            , url: function (apiUrl, assetId?: number) { return apiUrl + 'assets/assetById/' + assetId }
            , urlMock: '/assets/mock/asset.json'
            , method: 'getAssetById'
            , mock: true // true
        },
        assetsByCustomerIds: {
            id: 'assetsByCustomerIds'
            , header: this.wsHeaders
            , url: function (apiUrl, customerIds?) { return apiUrl + 'assets/' + customerIds }
            , urlMock: '/assets/mock/assets.json'
            , method: 'getAssetsByCustomerIds'
            , mock: true // true
        },
        userFrotalog: {
            id: 'userFrotalog'
            , header: this.wsHeaders
            , url: function (apiUrl, email?) { return apiUrl + 'users/' + email }
            , urlMock: '/assets/mock/usersfrotalog.json'
            , method: 'getUserFrotalog'
            , mock: false // true
        },
        userFocus: {
            id: 'userFocus'
            , header: this.wsHeaders
            , url: function (apiUrl, user?) { return apiUrl + 'users/' + user }
            , urlMock: '/assets/mock/usersfrotalog.json'
            , method: 'getUserFrotalog'
            , mock: false // true
        },
        authLogin: {
            id: 'authLogin'
            , header: this.wsHeaders
            , url: function (apiUrl) { return apiUrl + 'users/auth-user' }
            , urlMock: '/assets/mock/usersfrotalog.json'
            , method: 'getUserFrotalog'
            , mock: false // true
        },
        setAlarmRead: {
            id: 'setAlarmRead'
            , header: this.wsHeaders
            , url: function (apiUrl: string) { return apiUrl + 'alarm/setAlarmRead' }
            , urlMock: ''
            , method: 'setAlarmRead'
            , mock: false // true
        },
        setTreatmentStarted: {
            id: 'setTreatmentStarted'
            , header: this.wsHeaders
            , url: function (apiUrl: string, startOrStop: boolean) { return apiUrl + 'alarm/setTreatmentStarted/' + startOrStop }
            , urlMock: ''
            , method: 'setTreatmentStarted'
            , mock: false // true
        },
        // ATIVO DEVICE //
        getDevicesByManyEquipmentsAndStatus: {
            id: 'getDevicesByManyEquipmentsAndStatus'
            , header: this.wsHeaders
            , url: function (apiUrl, equipments, status) { return apiUrl + 'devices/equipments/' + equipments + '/status/' + status }
            , urlMock: '/assets/mock/equipments.json'
            , method: 'getAllEquipments'
            , mock: false // true
        },
        equipmentsByCustomerId: {
            id: 'equipmentsByCustomerId'
            , header: this.wsHeaders
            , url: function (apiUrl, customerIds?) { return apiUrl + 'equipments/' + 'customers/' + customerIds }
            , urlMock: '/assets/mock/equipments.json'
            , method: 'getAllEquipments'
            , mock: true // true
        },
        // EQUIPMENTS ATIVO //
        equipmentsByCustomersChildIds: {
            id: 'equipments'
            , header: this.wsHeaders
            , url: function (apiUrl, customerIds?) { return apiUrl + 'equipments/customerChilds/' + customerIds }
            , urlMock: '/assets/mock/equipments.json'
            , method: 'getAllEquipments'
            , mock: false // true
        },
        equipmentsByCustomersChildId: {
            id: 'equipmentsByCustomersChildId'
            , header: this.wsHeaders
            , url: function (apiUrl, customerIds?) { return apiUrl + 'v2/equipment/?isMaster=true&customersChild=' + customerIds }
        },
        equipmentsByAsset: {
            id: 'equipmentsByCustomersChildId'
            , header: this.wsHeaders
            , url: function (apiUrl, assetId) { return apiUrl + 'v2/equipment/?assetId=' + assetId }
        },
        disableEquipmentById: {
            id: 'disableEquipmentById'
            , header: this.wsHeaders
            , url: function (apiUrl: string, id: number) { return apiUrl + 'equipments/' + id + '/disable' }
            , urlMock: ''
            , method: 'setStatus'
            , mock: false // true
        },
        saveNewEquipment: {
            id: 'saveNewEquipment'
            , header: this.wsHeaders
            , url: function (apiUrl: string) { return apiUrl + 'equipments/' }
            , urlMock: ''
            , method: 'saveEquipment'
            , mock: true
        },
        saveEquipment: {
            id: 'saveEquipment'
            , header: this.wsHeaders
            , url: function (apiUrl: string, equipId: number) { return apiUrl + 'equipments/' + equipId }
            , urlMock: ''
            , method: 'saveEquipment'
            , mock: false
        },
        // ATIVO DEVICE //
        saveNewDevice: {
            id: 'saveNewDevice'
            , header: this.wsHeaders
            , url: function (apiUrl: string) { return apiUrl + 'devices/new' }
            , urlMock: ''
            , method: 'saveDevice'
            , mock: false
        },
        devicesByEquipmentIds: {
            id: 'devicesByEquipmentIds'
            , header: this.wsHeaders
            , url: function (apiUrl, equipmentsIds?) { return apiUrl + 'devices/' + 'equipments/' + equipmentsIds }
            , urlMock: '/'
            , method: 'getAllDevices'
            , mock: true
        },
        devicesByStatus: {
            id: 'devicesByStatus'
            , header: this.wsHeaders
            , url: function (apiUrl, status) { return apiUrl + 'devices/' + 'byStatus/' + status }
            , urlMock: '/'
            , method: 'getAllDevices'
            , mock: false
        },
        // ATIVO DEVICE //
        devicesByCustomerChildAndStatus: {
            id: 'devicesByCustomerChildAndStatus'
            , header: this.wsHeaders
            , url: function (apiUrl, customerChilds, status) { return apiUrl + 'devices/' + 'customerChilds/' + customerChilds + '/status/' + status }
            , urlMock: '/'
            , method: 'devicesByCustomerChildAndStatus'
            , mock: false
        },
        // ATIVO ASSET //
        assetsByIds: {
            id: 'assetsByIds'
            , header: this.wsHeaders
            , url: function (apiUrl, assetsIds) { return apiUrl + 'integrationDevices/' + assetsIds }
            , urlMock: '/'
            , method: 'getAssetsById'
            , mock: false
        },
        // NOT //
        assetsByStatus: {
            id: 'assetsByStatus'
            , header: this.wsHeaders
            , url: function (apiUrl, status) { return apiUrl + 'integrationDevices/' + 'byStatus/' + status }
            , urlMock: '/'
            , method: 'getAssetsById'
            , mock: false
        },
        pictureByAlarm: {
            id: 'pictureByAlarm'
            , header: this.wsHeaders
            , url: function (apiUrl, alarmId) { return apiUrl + 'alarms/' + alarmId + '/image' }
            , urlMock: '/'
            , method: 'getPictureByAlarm'
            , mock: false
        },
        validationAlarm: {
            id: 'validationAlarm'
            , header: this.wsHeaders
            , url: function (apiUrl) { return apiUrl + 'validationAlarm/' }
            , urlMock: '/'
            , method: 'validationAlarm'
            , mock: false
        },
        newAudit: {
            id: 'newAudit'
            , header: this.wsHeaders
            , url: function (apiUrl) { return apiUrl + 'audit' }
            , urlMock: '/'
            , method: 'newAudit'
            , mock: false
        },
        getTreatmentByCustomerId: {
            id: 'treatmentByCustomer'
            , header: this.wsHeaders
            , url: function (apiUrl, customerId) { return apiUrl + 'treatment/customer/' + customerId + '/treatmentChilds' }
            , urlMock: '/'
            , method: 'validationAlarm'
            , mock: false
        },
        customerChildsByIds: {
            id: 'customerChildsByIds'
            , header: this.wsHeaders
            , url: function (apiUrl, customerChildIds) { return apiUrl + 'customers/customerChilds' }
            , urlMock: '/'
            , method: 'customerChildsByIds'
            , mock: false
        },
        alarmsByAudit: {
            id: 'alarmsByAudit'
            , header: this.wsHeaders
            , url: function (apiUrl, auditId) { return apiUrl + 'alarmsByAudit/' + auditId }
            , urlMock: '/'
            , method: 'alarmsByAudit'
            , mock: false
        },
        getAuditById: {
            id: 'getAuditById'
            , header: this.wsHeaders
            , url: function (apiUrl, auditId) { return apiUrl + 'audit/' + auditId }
            , urlMock: '/'
            , method: 'getAuditById'
            , mock: false
        },
        // ATIVO ASSET //
        getAssetsByManyCustomerChildAndAssetType: {
            id: 'getAssetsByManyCustomerChildAndAssetType'
            , header: this.wsHeaders
            , url: function (apiUrl, customerChildsId, assetType) { return apiUrl + 'assets/vehiclesOrDriversByManyCustomerChilds' }
            , urlMock: '/'
            , method: 'getAssetsByManyCustomerChildAndAssetType'
            , mock: false
        },
        // ATIVO DEVICE //
        deleteDevice: {
            id: 'deleteDevice'
            , header: this.wsHeaders
            , url: function (apiUrl, deviceId) { return apiUrl + 'devices/' + deviceId }
            , urlMock: '/'
            , method: 'deleteDevice'
            , mock: false
        },
        saveCustomerProfile: {
            id: 'saveCustomerProfile'
            , header: this.wsHeaders
            , url: function (apiUrl) { return apiUrl + 'customersProfile/' }
            , urlMock: '/'
            , method: 'byLastHour'
            , mock: false
        },
        updateCustomerProfile: {
            id: 'saveCustomerProfile'
            , header: this.wsHeaders
            , url: function (apiUrl) { return apiUrl + 'customersProfile/' }
            , urlMock: '/'
            , method: 'byLastHour'
            , mock: false
        },
        customerChildsByCustomerProfile: {
            id: 'customerChildsByCustomerProfile'
            , header: this.wsHeaders
            , url: function (apiUrl, customerProfileId, activeCustomersOnly) { return apiUrl + 'customers/customerProfile/' + customerProfileId + '?activeCustomers=' + activeCustomersOnly }
            , urlMock: '/'
            , method: 'byLastHour'
            , mock: false
        },
        saveNewCustomerChild: {
            id: 'saveNewCustomerChild'
            , header: this.wsHeaders
            , url: function (apiUrl) { return apiUrl + 'customers/' }
            , urlMock: '/'
            , method: 'byLastHour'
            , mock: false
        },
        updateCustomerChild: {
            id: 'updateCustomerChild'
            , header: this.wsHeaders
            , url: function (apiUrl) { return apiUrl + 'customers/' }
            , urlMock: '/'
            , method: 'byLastHour'
            , mock: false
        },
        getCustomerProfileId: {
            id: 'getCustomerProfileId'
            , header: this.wsHeaders
            , url: function (apiUrl, customerProfileId) { return apiUrl + 'users/customer-profile/' + customerProfileId }
            , urlMock: '/'
            , method: 'byLastHour'
            , mock: false
        },
        getUserVideoDataById: {
            id: 'getUserVideoDataById'
            , header: this.wsHeaders
            , url: function (apiUrl, userId) { return apiUrl + 'users/user-video-data/' + userId }
            , urlMock: '/'
            , method: 'byLastHour'
            , mock: false
        },
        crudUser: {
            id: 'crudUser'
            , header: this.wsHeaders
            , url: function (apiUrl) { return apiUrl + 'users/' }
            , urlMock: '/'
            , method: 'byLastHour'
            , mock: false
        },
        getUserSubs: {
            id: 'getUserSubs'
            , header: this.wsHeaders
            , url: function (apiUrl, userId) { return apiUrl + 'subs/user/' + userId }
            , urlMock: '/'
            , method: 'byLastHour'
            , mock: false
        },
        getIdsUsersByCustomerCreare: {
            id: 'getIdsUsersByCustomerCreare'
            , header: this.wsHeaders
            , url: function (apiUrl) { return apiUrl + 'users/ids/creare/' }
            , urlMock: '/'
            , method: 'byLastHour'
            , mock: false
        },
        getCustomerProfilesByIds: {
            id: 'getCustomerProfilesByIds'
            , header: this.wsHeaders
            , url: function (apiUrl, userId) { return apiUrl + 'customersProfile/' + userId }
            , urlMock: '/'
            , method: 'byLastHour'
            , mock: false
        },
        saveSubs: {
            id: 'saveSubs'
            , header: this.wsHeaders
            , url: function (apiUrl) { return apiUrl + 'subs/' }
            , urlMock: '/'
            , method: 'byLastHour'
            , mock: false
        },
        batchSaveSubs: {
            id: 'batchSaveSubs'
            , header: this.wsHeaders
            , url: function (apiUrl) { return apiUrl + 'subs/batch/create' }
            , urlMock: '/'
            , method: 'byLastHour'
            , mock: false
        },
        deleteSubs: {
            id: 'deleteSubs'
            , header: this.wsHeaders
            , url: function (apiUrl, userId, customerChildId) { return apiUrl + 'subs/user/' + userId + '/customerChild/' + customerChildId }
            , urlMock: '/'
            , method: 'byLastHour'
            , mock: false
        },
        batchDeleteSubs: {
            id: 'batchDeleteSubs'
            , header: this.wsHeaders
            , url: function (apiUrl) { return apiUrl + 'subs/batch/delete' }
            , urlMock: '/'
            , method: 'byLastHour'
            , mock: false
        },
        syncDriver: {
            id: 'syncDriver'
            , header: this.wsHeaders
            , url: function (apiUrl, customerChildId) { return apiUrl + 'assets/' + customerChildId + '/synchronizeDriversByCustomerChildId' }
            , urlMock: '/'
            , method: 'byLastHour'
            , mock: false
        },
        syncVehicle: {
            id: 'synchronizeVehiclesByCustomerChildId'
            , header: this.wsHeaders
            , url: function (apiUrl, customerChildId) { return apiUrl + 'assets/' + customerChildId + '/synchronizeVehiclesByCustomerChildId' }
        },
        saveAsset: {
            id: 'saveAsset'
            , header: this.wsHeaders
            , url: function (apiUrl) { return apiUrl + 'assets/' }
            , urlMock: '/'
            , method: 'byLastHour'
            , mock: false
        },
        getAccessControlByUserId: {
            id: 'getAccessControlByUserId'
            , header: this.wsHeaders
            , url: function (apiUrl, userId) { return apiUrl + 'users/' + userId + '/access-control' }
            , urlMock: '/'
            , method: 'byLastHour'
            , mock: false
        },
        crudAccessControl: {
            id: 'crudAccessControl'
            , header: this.wsHeaders
            , url: function (apiUrl, userId, permissionId) { return apiUrl + 'users/' + userId + '/access-control/' + permissionId }
            , urlMock: '/'
            , method: 'byLastHour'
            , mock: false
        },
        updateUserPermissions: {
            id: 'updateUserPermissions',
            header: this.wsHeaders,
            url: function(apiUrl, userId) { return apiUrl + 'users/' + userId + '/access-control'},
            urlMock: '/',
        },
        changePassword: {
            id: 'changePassword'
            , header: this.wsHeaders
            , url: function (apiUrl) { return apiUrl + 'users/changePassword' }
        },
        getDeviceByIMEI: {
            id: 'getDeviceByIMEI'
            , header: this.wsHeaders
            , url: function (apiUrl, imei) { return apiUrl + 'devices/imei/' + imei }
        },
        getRiskRatingByCustomerChildId: {
            id: 'getRiskRatingByCustomerChildId'
            , header: this.wsHeaders
            , url: function (apiUrl, customerChildIds) { return apiUrl + 'risk-rating/customers-child/' + customerChildIds }
        },
        getRiskRatingById: {
            id: 'getRiskRatingById'
            , header: this.wsHeaders
            , url: function (apiUrl, riskRatingId) { return apiUrl + 'risk-rating/' + riskRatingId }
        },
        setOrRemoveRiskRatingInCustomerChild: {
            id: 'setOrRemoveRiskRatingInCustomerChild'
            , header: this.wsHeaders
            , url: function (apiUrl, customerChildId, riskRatingId) { return apiUrl + 'risk-rating/customer-child/' + customerChildId + '/risk-rating/' + riskRatingId }
        },
        saveRiskRatingInCustomerChild: {
            id: 'saveRiskRatingInCustomerChild'
            , header: this.wsHeaders
            , url: function (apiUrl, riskRatingId) { return apiUrl + 'risk-rating/batch-customer-child/' + riskRatingId }
        },
        saveNewOrUpdateRiskRating: {
            id: 'saveNewOrUpdateRiskRating'
            , header: this.wsHeaders
            , url: function (apiUrl) { return apiUrl + 'risk-rating' }
        },
        deleteRiskRating: {
            id: 'deleteRiskRating'
            , header: this.wsHeaders
            , url: function (apiUrl, riskRatingId) { return apiUrl + 'risk-rating/' + riskRatingId }
        },
        treatmentData: {
            id: 'treatmentData'
            , url: function (apiUrl) { return apiUrl + 'treatment' }
        },
        removeTreatment: {
            id: 'removeTreatment'
            , url: function (apiUrl, treatId) { return apiUrl + 'treatment/' + treatId }
        },
        resetPasswordByLogin: {
            id: 'resetPasswordByLogin'
            , url: function (apiUrl, login) { return apiUrl + 'users/resetPassword/' + login }
        },
        getTemplateByCustomerProfileId: {
            id: 'getTemplateByCustomerProfileId'
            , url: function (apiUrl, customerProfileId) { return apiUrl + 'templates/customer-profile/' + customerProfileId }
        },
        getTemplateByCustomerProfileIdAndCategory: {
            id: 'getTemplateByCustomerProfileId'
            , url: function (apiUrl, customerProfileId, category) { return `${apiUrl}templates/customer-profile/${customerProfileId}?category=${category}` }
        },
        sendAlarmAuditNotificationByEmail: {
            id: 'sendAlarmAuditNotificationByEmail'
            , url: function (apiUrl, auditId) { return  `${apiUrl}audit/send-audit-alarm-notification-by-email/${auditId}` }
        },
        getEmailLog: {
            id: 'getEmailLog'
            , url: function (apiUrl, alarmId) { return  `${apiUrl}v2/alarm-email-log/alarm/${alarmId}` }
        },
        getAlarmByEmail: {
            id: 'getAlarmByEmail'
            , url: function (apiUrl, emailLogId) { return  `${apiUrl}v2/alarm/linked-by-email-log/${emailLogId}` }
        },
        getVariableByCategoryAndStatus: {
            id: 'getVariableByCategoryAndStatus'
            , url: function (apiUrl, category, status) { return `${apiUrl}/template-variable/status/${status}/category/${category}` }
        },
        createTemplateExportable: {
            id: 'createTemplateExportable'
            , url: function (apiUrl) { return apiUrl + '/templates' }
        },
        createTemplateVariable: {
            id: 'createTemplateVariable'
            , url: function (apiUrl) { return apiUrl + '/template-variable/' }
        },
        createTemplateJunction: {
            id: 'createTemplateJunction'
            , url: function (apiUrl) { return apiUrl + '/templates/variables' }
        },
        updateTemplateExportable: {
            id: 'updateTemplateExportable'
            , url: function (apiUrl) { return apiUrl + '/templates/' }
        },
        enableCustomerProfileFaceRecog: {
            id: 'enableCustomerProfileFaceRecog'
            , url: function (apiUrl, customerProfileId, hasFaceRecog, createCollection) {
                let params = [`enableRecog=${hasFaceRecog}`];

                if (createCollection) {
                    params.push(`createCollections=${createCollection}`);
                }

                return apiUrl + `/v2/customersProfile/${customerProfileId}/faceRecognition?${params.join('&')}`
            }
        },
        recognition: {
            id: 'recognition'
            , url: function (apiUrl, alertType, customerProfileId) {
                return apiUrl + `/v2/driver/recognition?alertType=${alertType}&customerProfileId=${customerProfileId}`;
            }
        },
        gas: {
            id: 'gas'
            , url: function (apiUrl, customerProfileId) {
                return apiUrl + `/v2/customersProfile/${customerProfileId}`
            }
        },
        getTreatmentsByCustomerProfile: {
            id: 'getTreatmentsByCustomerProfile'
            , url: function (apiUrl, customerProfileId) {
                return apiUrl + `/treatment/?customerProfileId=${customerProfileId}`
            }
        },
        createAudit: {
            id: 'createAudit'
            , url: function (apiUrl, driverFromFaceRecog) {
                if (typeof driverFromFaceRecog !== 'undefined') {
                    return apiUrl + `/v2/audit/?isIdentification=${driverFromFaceRecog}`
                } else {
                    return apiUrl + `/v2/audit/`
                }
            }
        },
        createAuditsInBatch: {
            id: 'createAuditsInBatch',
            url: (apiUrl: string) => {
                return apiUrl + `/v2/audit/in-batch/`
            }
        },
        uploadImageToDriver: {
            id: 'uploadImageToDriver'
            , url: function (apiUrl, driverId, alertType, isIdentification) {
                return apiUrl + `v2/driver/${driverId}/images?alertType=${alertType}&isIdentification=${isIdentification}`
            }
        },
        novaRevisao: {
            id: 'novaRevisao'
            , url: function (apiUrl) {
                return apiUrl + `v2/audit/workflow`
            }
        },
        readByCustomer: {
            id: 'readByCustomer'
            , url: function (apiUrl, alarmId) {
                return apiUrl + `v2/alarm/read-by-customer/${alarmId}`
            }
        },
        deleteImgSignature: {
            id: 'deleteImgSignature'
            , url: function (apiUrl) {
                return apiUrl
            }
        },
        buscaRevisoesAuditoria: {
            id: 'buscaRevisoesAuditoria'
            , url: function (apiUrl, alarmId) {
                return apiUrl + `v2/audit/workflow?alarmId=${alarmId}`
            }
        },
        getAlarmAuditHistories: {
            id: 'getAlarmAuditHistories',
            url: (apiUrl, alarmId) => {
                return apiUrl + `v2/audit-history/alarm/${alarmId}`
            }
        },
        lastAlarm: {
            id: 'lastAlarm',
            url: (apiUrl) => {
                return apiUrl + `lastAlarm`
            }
        },
        lastValidAlarm:{
            id: 'lastValidAlarm',
            url: (apiUrl) => {
                return apiUrl + `lastValidAlarm`
            }
        },
        getDataForChartLastVelocitiesAndAlarms: {
            id: 'getDataForChartLastVelocitiesAndAlarms',
            url: (apiUrl: string, vehicleId: number, driverId: number, startDate: Date, endDate: Date) => {
                let url = new URL(`${apiUrl}v2/chart/last-velocities-and-alarms`)

                url.searchParams.set('vehicleId', vehicleId.toString())
                if (driverId) {
                    url.searchParams.set('driverId', driverId.toString())
                }
                url.searchParams.set('startDate', startDate.toISOString())
                url.searchParams.set('endDate', endDate.toISOString())

                return url;
            }
        },
        setAlarmReadByCustomer: {
            id: 'setAlarmReadByCustomer',
            url: (apiUrl: string) => {
                const url = new URL(`${apiUrl}alarmCustomerRead`)
                    console.log(url)
                return url
            }
        },
        getLastTicketsByStatus: {
            id: 'getLastTicketsByStatus',
            url: (baseUrl: string) => new URL(`${baseUrl}v2/tickets/get-last-tickets-by-status`)
        },
        getEmailListByAssetId: {
            id: 'getEmailListByAssetId',
            url: (baseUrl: string, assetId: number) => new URL(`${baseUrl}v2/audit/email-list-by-vehicle?vehicleId=${assetId}`)
        },
        getRecursiveAssetListFromCustomerChildId: {
            id: 'getRecursiveAssetListFromCustomerChildId',
            url: function (apiUrl, customerChildId, assetType) {
                return apiUrl + `assets/${customerChildId}/recursiveAssetListFromCustomerChild/${assetType}/assetType`
            }
        },
        getAssetListByCustomerChildCustomerProfile: {
            id: 'getAssetListByCustomerChildCustomerProfile',
            url: function (apiUrl, customerChildId, assetType) {
                return apiUrl + `assets/${customerChildId}/assetListFromCustomerChildCustomerProfile/${assetType}/assetType`
            }
        },
        getOrSaveListUserCustomerFilters: {
            id: 'getOrSaveListUserCustomerFilters',
            url: function (apiUrl) {
                return apiUrl + `user/user-filters`
            }
        },
        getDefaultListUserFilterCustomerIdList: {
            id: 'getDefaultListUserFilterCustomerIdList',
            url: function (apiUrl) {
                return apiUrl + `user/user-filters/default`
            }
        },
        userFilterById: {
            id: 'userFilterById',
            url: function (apiUrl, filterId) {
                return `${apiUrl}user/user-filters/${filterId}`
            }
        },
        getLinkedAlarms: {
            id: 'getLinkedAlarms',
            url: function (apiUrl, alarmIdList) {
                return `${apiUrl}alarm/linkedAlarms?alarmIdList=${alarmIdList.join(',')}`
            }
        }
    }

    public wsServicesUrl(id: string, param1?: string): string {
        let url;
        if (this.webServices[id].mock) {
            url = this.webServices[id].urlMock;
        } else {
            url = this.webServices[id].url(this.getApiURI(), param1);
        }
        return url;
    }

}
