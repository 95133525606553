export const mockDevicesData = [
    {
        id: 1,
        name: "Dispositivo 99",
        identification: "dispositivo-99",
        integration: "focus_1",
        typeId: 1,
        status: 0,
        equipId: 2
    },
    {
        id: 2,
        name: "Dispositivo 989",
        identification: "dispositivo-989",
        integration: "focus_2",
        typeId: 1,
        status: 1,
        equipId: null
    },
    {
        id: 15, 
        name: "Dispositivo 1", 
        identification: "dispositivo-1", 
        integration: "focus_15",
        typeId: 1,
        status: 1,
        equipId: null
    },
    {
        id: 17, 
        name: "Dispositivo 3", 
        identification: "dispositivo-3", 
        integration: "focus_17",
        typeId: 1,
        status: 1,
        equipId: null
    },
    {
        id: 16, 
        name: "Dispositivo 2", 
        identification: "dispositivo-2", 
        integration: "focus_16",
        typeId: 1,
        status: 0,
        equipId: 2
    }
]