import { Injectable } from '@angular/core';
import { Headers, Http, Response } from '@angular/http';
import { AuthenticationService } from 'app/pages/login/authentication.service';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SelectItem } from '../../../../node_modules/primeng/api';
import { ConfigService } from '../config/config.service';
import { Audit } from '../models';
import { Treatment, TreatmentData } from '../models/treatment.entity';
import { ValidationHistory } from "../models/validationHistory.entity";


@Injectable()
export class TreatmentService {

    private baseUrl;

    constructor(
        private http: Http,
        private configService: ConfigService,
        protected auth: AuthenticationService
    ){
        this.baseUrl = configService.getApiURI();
    }

    getTreatmentByCustomerId(customerId: number): Promise<TreatmentData[]> {
        const URL_ENDPOINT = this.configService.webServices['getTreatmentByCustomerId'].url(this.baseUrl, customerId);
        const HEADERS = this.auth.getHttpHeaders();

        return this.http.get(URL_ENDPOINT, { headers : HEADERS })
            .toPromise()
            .then(res => res.json())
            .catch(err => {
                return Promise.reject((err.message) ? err.json() : err.text()  || 'Server error');
            })
    }

    saveNewTreatmentData(treatment: TreatmentData): Observable<TreatmentData> {
        const URL_ENDPOINT = this.configService.webServices['treatmentData'].url(this.baseUrl);
        const HEADERS = this.auth.getHttpHeaders();

        let data = JSON.stringify({
            "name": treatment.name,
	        "customer_id": treatment.customer_id
        });

        return this.http.post(URL_ENDPOINT, data, {headers: HEADERS}).pipe(
            map((res: Response) => {
                return res.json();
          }));
    }

    public getTreatments(customerId: number): Observable<TreatmentData[]> {
        let _ws = this.configService.webServices['getTreatmentByCustomerId'];

        return this.http.get(_ws.url(this.baseUrl, customerId), {headers: this.getHttpHeaders()}).pipe(
            map((res: Response) => {
                return res.json();
          }),
          catchError(this.handleError),);
    }

    public validationAlarm(validation: ValidationHistory): Observable<any> {
        let _ws = this.configService.webServices['validationAlarm'];

        let string = JSON.stringify({
            "alarm": validation.alarm,
            "driver": validation.driver,
            "level": validation.level,
            "ownerUser": validation.ownerUser,
            "purpose": validation.purpose,
            "type": validation.type,
            "vehicle": validation.vehicle
        });

        return this.http.put(_ws.url(this.baseUrl), string, {headers: this.getHttpHeaders()}).pipe(
            map((res: Response) => {
                res['id'] = validation.alarm;
                return;
        }),
        catchError(this.handleError),);
    }

    validationAlarms(validationHistoryList: ValidationHistory[]): Promise<{id: number, success: boolean}[]> {
        let _ws = this.configService.webServices['validationAlarm'];

        return Promise.all(
            validationHistoryList.map(vh => {
                return this.http
                    .put(_ws.url(this.baseUrl), JSON.stringify(vh), {headers: this.getHttpHeaders()})
                    .toPromise()
                    .then(() => { return {id: vh.alarm, success: true}})
                    .catch(() => { return {id: vh.alarm, success: false}})
            })
        )
    }

    public newAudit(treatment : Treatment): Observable<any> {
        let _ws = this.configService.webServices['newAudit'];

        let string = JSON.stringify({
            "alarmIds": treatment.alarmIds,
            "comments": treatment.treatmentContent,
            //"ownerUser": this.workspace.currentUserId,
            "treatment": treatment.treatmentId,
            "create_date": new Date()
        });

        return this.http.post(_ws.url(this.baseUrl), string, {headers: this.getHttpHeaders()}).pipe(
            map((res: Response) => {
                return res;
        }),
        catchError(this.handleError),);
    }

    public getAuditById(auditId : number): Observable<Audit> {
        let _ws = this.configService.webServices['getAuditById'];

        return this.http.get(_ws.url(this.baseUrl, auditId), {headers: this.getHttpHeaders()}).pipe(
            map((res: Response) => {
                return res.json();
        }),
        catchError(this.handleError),);
    }

    public convertToSelectItem(treatment : TreatmentData[]) : SelectItem[] {
        let result : SelectItem[] = [];
        treatment.forEach((treat : TreatmentData) => {
            if(treat.status == 1) {
                result.push({label: treat.name, value: treat.id});
            }
        })
        return result;
    }

    private handleError(error: any) {
        let errMsg = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
        console.error('Erro:', error);
        return observableThrowError(errMsg);
    }

    getHttpHeaders() : Headers {
        let headers = new Headers();

        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', this.auth.getToken());

        return headers;
    }

    updateTreatment(treatment: TreatmentData): Observable<TreatmentData> {
        const URL_ENDPOINT = this.configService.webServices['treatmentData'].url(this.baseUrl);
        const HEADERS = this.auth.getHttpHeaders();

        let data = JSON.stringify({
            "id": treatment.id,
            "name": treatment.name,
            "status": treatment.status,
	        "customer_id": treatment.customer_id
        });

        return this.http.put(URL_ENDPOINT, data, {headers: HEADERS}).pipe(
            map((res: Response) => {
                return res.json();
          }));
    }

    removeTreatment(treatmentId: number): Observable<string> {
        const URL_ENDPOINT = this.configService.webServices['removeTreatment'].url(this.baseUrl, treatmentId);
        const HEADERS = this.auth.getHttpHeaders();

        return this.http.delete(URL_ENDPOINT, {headers: HEADERS}).pipe(
            map((res: Response) => {
                return res.text();
          }));
    }

    public getTreatmentsByCustomerProfile(customerProfileId: number): Observable<TreatmentData[]> {
        let _ws = this.configService.webServices['getTreatmentsByCustomerProfile'];

        return this.http.get(_ws.url(this.baseUrl, customerProfileId), {headers: this.getHttpHeaders()}).pipe(
            map((res: Response) => {
                return res.json();
          }),
          catchError(this.handleError),);
    }

    public createAudit(audit : Audit): Observable<Audit> {
        let _ws = this.configService.webServices['createAudit'];

        return this.http.post(_ws.url(this.baseUrl, audit.driverFromFaceRecog), JSON.stringify(audit), {headers: this.getHttpHeaders()}).pipe(
            map((res: Response) => {
                return res.json();
        }),
        catchError(this.handleError),);
    }

    public createAuditsInBatch(audits: Array<Audit>, comments: string): Observable<Array<Audit>> {
        let _ws = this.configService.webServices['createAuditsInBatch'];

        return this.http.post(
            _ws.url(this.baseUrl),
            JSON.stringify({
                audits,
                comments
            }),
            { headers: this.getHttpHeaders() }
        ).pipe(
            map((res: Response) => {
                return res.json();
            }),
            catchError(this.handleError));
    }
}
