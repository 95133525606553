import { Injectable } from "@angular/core";
import { Http, Headers } from "@angular/http";
import { ConfigService } from "../config/config.service";
import { AuthenticationService } from "app/pages/login/authentication.service";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import * as moment from "moment";

@Injectable()
export class CaptureService {
    private baseUrl;

    constructor(
        private http: Http,       
        private configService: ConfigService,
        private auth: AuthenticationService     
    ) { 
      this.baseUrl = configService.getApiURI();
    }

    public getByAssetId(assetId : number[]) : Observable<any> {
        let _ws = this.configService.webServicesV2['getCaptureByAsset'];

        if(this.itHasCapturePermition()){
            // envia para o REDIS
        }

        return this.http.get(_ws.url(this.baseUrl, assetId.join(','), this.configService.convertDateToIsoString(new Date())), { headers: this.getHttpHeaders() }).pipe(
            map((res: Response) => {
                return res.json();
            })
        );
    }

    sendCommandByAssetIds(assetId: number[]) : Observable<any> {
        let _ws = this.configService.webServicesV2['sendCaptureByAsset'];

        return this.http.get(_ws.url(this.baseUrl, assetId.join(','), this.configService.convertDateToIsoString(new Date())), { headers: this.getHttpHeaders() }).pipe(
            map((res: Response) => {
                return res.json();
            })
        );
    }

    private getHttpHeaders() : Headers {
        let headers = new Headers();

        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', this.auth.getToken());

        return headers;
    }

    public setDateCapture() {
        let date = moment(new Date()).add(10, 'minutes');

        window.localStorage.setItem('datePermitCapture', date.toString());
    }

    public itHasCapturePermition() {
        let date = new Date(window.localStorage.getItem('datePermitCapture'));
        let dateNow = new Date();

        if(dateNow > date){
            return true;
        }else{
            return false;
        }
    }

    public getDatePermitionCapture() {
        return window.localStorage.getItem('datePermitCapture');
    }
}