import { Injectable } from "@angular/core";
import { Http } from "@angular/http";
import { AuthenticationService } from "app/pages/login/authentication.service";
import { ConfigService } from "../config/config.service";


import {throwError as observableThrowError,  Observable } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { Response, Headers } from '@angular/http';
import { HttpClient } from '@angular/common/http';

import { SelectItem } from 'primeng/api';
import { environment } from "environments/environment";

@Injectable()
export class IframeService {

    constructor(
        private http: Http,       
        private configService: ConfigService,
        private auth: AuthenticationService 
    ){}


    verifyPermission(userName, endpoint){
        return this.http.get(environment.urlCoreApi+endpoint+'/'+userName, {headers: this.getHttpHeaders()}).pipe(
            map((res: Response) => {
                return res.json();
            }),
            catchError(this.handleError),
        );
    }

    getHttpHeaders() : Headers {
        let headers = new Headers();

        headers.append('Content-Type', 'application/json');
        headers.append('API_KEY', environment.apikey);
        headers.append('Authorization', this.auth.getToken());

        return headers;
    }

    private handleError(error: any) {
        let errorMsg = (error.message) ? error.json() : error.text();
        return observableThrowError(errorMsg);
    }

}