import { TranslateModule, TranslateService} from '@ngx-translate/core';
import { APP_BASE_HREF } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { CoreModule } from './@core/core.module';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ThemeModule } from './@theme/theme.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GlobalState } from './global.state';

import { NbLoginComponent2 } from './pages/login/login.component';
import {NbAuthService} from './pages/login/auth.service' ;
import { NbTokenService} from './pages/login/token.service' ;
import {NbDummyAuthProvider} from './pages/login/dummy-auth.provider';
import { ConfigService } from './@core/config/config.service'
import { WorkspaceService } from './@core/data/workspace.service'
import { MockService } from './@core/data/mock.service';
import { LoadingAnimateService } from './@theme/components/ngxLoading';
import { DataService } from './@core/data/data.service';
import { TreatmentService } from './@core/data/treatment.service';
import { AuthenticationService } from './pages/login/authentication.service';
import { AuthInterceptor } from './@core/guards/auth-interceptor.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { AccessLogUserService } from './@core/data/access-log-user.service';
import { ModalModule } from 'ngx-bootstrap';
import { NotificationCenterService } from './@theme/components/notification/notification-center.service';
import { EquipmentService } from './@core/data/equipment.service';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { PopUpsTelemetryComponent } from './pages/ui-alarms/telemetry/src/popups-events.component';
import { MovimentStatusService } from './@core/data/moviment-status.service';
import { CustomerContactService } from './@core/data/customer-contact.service';
import { IframeService } from './@core/data/iframe.service';
import { DriverService } from './@core/data/driver.service';
import { AssetService } from './@core/data/asset.service';
import { TranslateJsonService } from './@core/data/translate-json.service';
import { FilterV2Module } from './@theme/components/filter-v2/filter-v2.module';

@NgModule({
  declarations: [AppComponent, NbLoginComponent2],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpModule,
    AppRoutingModule,
    NgbModule.forRoot(),
    ThemeModule.forRoot(),
    CoreModule.forRoot(),
    ModalModule.forRoot(),
    TranslateModule.forRoot(),
    RecaptchaModule,
    RecaptchaFormsModule,
    FilterV2Module
  ],
  bootstrap: [AppComponent],
  providers: [
    { 
      provide: APP_BASE_HREF, 
      useValue: '/' 
    }, 
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    NbAuthService,
    NbTokenService,
    AuthenticationService,
    NbDummyAuthProvider,
    GlobalState,
    ConfigService,
    MockService,
    WorkspaceService,    
    LoadingAnimateService,
    DataService,
    TreatmentService,
    CustomerContactService,
    MessageService,
    AccessLogUserService,
    NotificationCenterService,
    EquipmentService,
    TranslateService,
    MovimentStatusService,
    DriverService,
    AssetService,
    TranslateJsonService
  ],
  exports: [
    ModalModule
  ]
})
export class AppModule {
}
