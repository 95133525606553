   let agora = new Date()
    , ontem = new Date()
    , hoje1hAtras = new Date()
    , hoje2hAtras = new Date()
    , hojeDaquiMeiaHora = new Date()
    , hojeDaqui1h = new Date()
    , hojeDaqui4h = new Date();


ontem.setHours(ontem.getHours() - 24);
hoje1hAtras.setHours(hoje1hAtras.getHours() - 1);
hoje2hAtras.setHours(hoje2hAtras.getHours() - 2);
hojeDaquiMeiaHora.setMinutes(hojeDaquiMeiaHora.getMinutes() + 30);
hojeDaqui1h.setHours(hojeDaqui1h.getHours() + 1);
hojeDaqui4h.setHours(hojeDaqui4h.getHours() + 4);

export const mockAlarmsData =[
  {
    "id": 43,
    "assetId": 1,
    "driverId": 1,
    "datetime": new Date(),
    "version": 0,
    "imei": "5303023004006170",
    "speed": 0.072228,
    "picture": null,
    "type": "Atten-Warning",
    "level": 1,
    "latitude": -29.887411,
    "longitude": -51.158833,
    "groupAlarmsChild": []
  }
]