import {Status} from '../enum/status.enum';
import {AuditType} from '../enum/audit.type.enum';
import { Alarm } from './alarm.entity';

export class Audit {
  	 public id: number;
  	 public customerChildId?: number;
     public name?: string;
     public description?: string;
     public identification?: string;
     public integration?: string;
     public picture?: string;
     public icon?: string;
     public typeId?: AuditType;
     public status?: Status;

    public comments: string;
    public ownserUsername?: string;
    public create_date?: Date;
    public treatmentname?: string;

    public createDate?: Date;
    public treatment?: number;
    public alarms?: Alarm[];
    public auditStatus?: number;

    public driverFromFaceRecog?: boolean;
    public auditAutor?: string;

    public maskCOVID?: boolean;
    public noSeatbelt?: boolean;
    public groupRisk?: number;

    public signature?: string;
    public driverName?: string;
    public signatureDate?: string;
}
