//import {Alarm, User, Driver, Asset, Device, Equipament, FilterAlarms} from '../models';
import { Alarm } from './alarm.entity';
import { User } from './user.entity';
import { Driver } from './driver.entity';
import { Asset } from './asset.entity';
import { Device } from './device.entity';
import { Equipament } from './equipament.entity';
import { FilterAlarms } from './filter.alarms.entity';

export class WorkspaceProfileData {
	public currentUserId: number;
	public currentUser?: User;

	// user customer list complet
	public selectedDrivers: Driver[];

	// user customer list ids 
	public userCustomerChildIds: number[];

	// user filter profile
	public selectedFilterAlams?: FilterAlarms = new FilterAlarms();	
}


