import { Component, Output, EventEmitter, Input } from "@angular/core";
import { CustomerService } from "app/@core/data/customer.service";
import { CustomerProfile } from "app/@core/models/customerProfile.entity";
import { TreeInputModel } from "app/@core/models/treeInputModel.entity";
import { DataService } from "app/@core/data/data.service";
import { TranslateJsonService } from "app/@core/data/translate-json.service";

@Component({
    selector: 'focus-is-user-creare',
    styleUrls: ['./is-user-creare.component.scss'],
    templateUrl: './is-user-creare.component.html',
})
export class IsUserCreareComponent {
    @Output('selectedCustomersChild') selectedCustomersChild: EventEmitter<number[]> = new EventEmitter();
    @Output('selectedCustomerProfile') selectedCustomerProfile: EventEmitter<number[]> = new EventEmitter();
    
    @Input('onlyCustomerProfile') onlyCustomerProfile: boolean = false;
    @Input('activeCustomersOnly') activeCustomersOnly: boolean = false;

    loadedCustomersProfile: boolean = false;
    loadedCustomersChild: boolean = false;

    customerProfileList: TreeInputModel[] = [];
    customerChildList: TreeInputModel[] = [];

    customerProfileSelected: number[] = [];

    constructor(
        private customerService: CustomerService,
        private dataService: DataService,
        public translateJsonService: TranslateJsonService,
    ){
        this.loadCustomersProfile();
    }

    loadCustomersProfile() {
        this.customerService.getCustomerProfiles().subscribe((result) => {            
            result.forEach((cusProf : CustomerProfile) => {
                this.customerProfileList.push({id: cusProf.id, name: cusProf.name, parentId: null});
            })
            this.loadedCustomersProfile = true;
        });
    }

    loadCustomersChild(customerProfileId: number[]) {
        this.loadedCustomersChild = false;
        this.customerProfileSelected = customerProfileId; 

        if(customerProfileId.length > 0){
            if (this.selectedCustomerProfile) {
                this.selectedCustomerProfile.emit(customerProfileId)
            }

            this.customerService.getCustomerChildsByCustomerProfile(customerProfileId[0], this.activeCustomersOnly).subscribe((result) => {                
                this.dataService.registerCustomerChilds(result);                                                                                                      
                this.customerChildList = this.customerService.convertCustomerChildsToTreeInputModel(result);
                this.loadedCustomersChild = true;
            })
        }        
    }
}