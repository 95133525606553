import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-download-button',
    styleUrls: ["./download-button.component.scss"],
    templateUrl: "./download-button.component.html"
})
export class DownloadButtonComponent {
    @Input("value") value: any;
    @Input("isDownloading") isDownloading: boolean;
    @Output("download") download = new EventEmitter<any>();

    handleDownload() {
        this.download.emit(this.value);
    }
}