import {Status} from '../enum/status.enum';
import {EquipamentType} from '../enum/equipament.type.enum';
import {Device} from './device.entity';

export class Equipament {
     public id: number;
     public assetId: number;
     public asset?: string;
     public assetName?: string;
     public assetIdentification?: string;
     public assetIdentificationAndName?: string;
     public type: EquipamentType;
     public name: string;
     public customerName: string;
     public customerChildName: string;
     public customerChildId: number;
     public identification: string;
     public integration: string;
     public picture?: string;
     public icon?: string;
     public status: Status;
     public devices: Device[];
     public deviceImei: string;
     public heartBeat?: number;
     public heartBeatInfo?: string;
     public firmware?: string;

     public createdAt?: Date;
     public equipmentTypeName?: string;
     public statusLabel?: string;
     public label?:string;
}


