// dados do roaddefend
//import {Alarm, User, Driver, Asset, Device, Equipament, WorkspaceProfileData, Customer, Audit, AlarmType} from '../models';
import { Alarm } from './alarm.entity';
import { User } from './user.entity';
import { Driver } from './driver.entity';
import { Asset } from './asset.entity';
import { Device } from './device.entity';
import { Equipament } from './equipament.entity';
import { WorkspaceProfileData } from './workspace.profile.data.entity';
import { Customer } from './customer.entity';
import { Audit } from './audit.entity';
import { AlarmType } from './alarm.type.entity';
import { Workspace } from './workspace.data.entity';
import { CustomerChild } from './customerChild.entity';
import { RiskRating } from './risk-rating.entity';
import { Infraction } from './infraction.entity';

export class RoadDefendData { 
  // user filtered data
  public filteredAlarmList:       Alarm[]        = new Array<Alarm>();
  public filteredInfractionList: Infraction[] = new Array<Infraction>()

  // repository data
  public customerList:   		  Customer[]  			= new Array<Customer>();
  public alarmList:   			  Alarm[]  				= new Array<Alarm>();
  public infractionList: Infraction[] = new Array<Infraction>();
  public alarmTypeList:   		AlarmType[]  		    = new Array<AlarmType>();
  public assetList:   			  Asset[]  				= new Array<Asset>();
  public deviceList:   			  Device[]  				= new Array<Device>();
  public vehicleList:        Asset[]           = new Array<Asset>();
  public driverList:   			  Driver[]  				= new Array<Driver>();  
  public equipamentList:   	  Equipament[]  			= new Array<Equipament>();  
  public userList:   	        User[]  		    	= new Array<User>();
  public auditList:				    Audit[]					= new Array<Audit>();
  public riskRatingList: RiskRating[] = new Array<RiskRating>();

  public workspaceProfile: WorkspaceProfileData = new WorkspaceProfileData();
  public workspace: Workspace = new Workspace();
  public customerChildList: CustomerChild[] = new Array<CustomerChild>();
}
