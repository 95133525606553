import { TranslateService } from '@ngx-translate/core';
import { Component, ViewChild, OnDestroy, AfterViewInit, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { FilterAlarms, WorkspaceProfileData, Customer, Driver, Alarm, AlarmDto } from '../../../@core/models';
import { Unsubscribable as AnonymousSubscription, Subscription } from "rxjs";
import { Observable } from 'rxjs/Rx';

import { GridOptions} from "ag-grid";
import { SelectItem } from 'primeng/primeng';
import { DataService } from '../../../@core/data/data.service';
import { WorkspaceService } from '../../../@core/data/workspace.service';
import { NotificationUtilService} from '../../../@core/utils/notification.util.service'
import { CustomerService } from '../../../@core/data/customer.service';
import { DriverService } from '../../../@core/data/driver.service';
import { GlobalState } from '../../../global.state';
import { CheckAlarmOptions } from '../../../@core/interfaces/interfaceAlarmOptions';
import { mockCustomerData } from '../../../@core/mock/data/customers.mock.data';
import { Workspace, customerChildsPermissions } from '../../../@core/models/workspace.data.entity';
import { CustomerChild } from '../../../@core/models/customerChild.entity';
import { ITreeOptions, TreeNode } from 'angular-tree-component';
import { TranslateJsonService } from 'app/@core/data/translate-json.service';

@Component({
  selector: 'focus-filter',
  styleUrls: ['./filter.component.scss'],
  templateUrl: './filter.component.html',
})
export class FilterComponent implements OnDestroy, OnInit {
  public treeOptions = {
    useCheckbox: false,
    animateExpand: false,
    animateSpeed: 1,
    scrollOnActivate: false,
    useTriState: false,
    animateAcceleration: 1.2
  }

  public options: ITreeOptions = {
    useCheckbox: true
  };

  @ViewChild("treeCustomers") treeCustomers : TreeNode;
  @ViewChild("treeDrivers") treeDrivers : TreeNode;

  @Input("report") report: boolean;
  @Input("carregadoCharts") carregadoCharts: boolean;
  @Output() generateReport: EventEmitter<any> = new EventEmitter();
  @Output() generatePDF: EventEmitter<any> = new EventEmitter();
  @Output() submitFilterEmit: EventEmitter<any> = new EventEmitter();

  public allCustomers;
  public allDrivers: TreeNode[] = [];
  public selectedCustomers: number[];
  public selectedDrivers: number[];

  public subs: Subscription;
  public subsdrivers: Subscription;
  public subsworkspace: Subscription;
  public subscustomers: Subscription;
  public subsonline: Subscription;

  public selItemDrivers: SelectItem[];
  public selItemCustomers: SelectItem[];
  public selItemAlarmTypes: SelectItem[];

  public dateStart: Date;
  public dateEnd: Date;
  public ptBr: any;

  public loadedCustomer: boolean = false;
  public loadedDrivers: boolean = false;

  public isOnlineChecked: boolean;
  public filterSelectedData: FilterAlarms = new FilterAlarms();
  public centerAlarm: AlarmDto;
  public checkAlarmOptions: CheckAlarmOptions = <CheckAlarmOptions> {
      isTiredChecked: true,
      isFaceMissingChecked: true,
      isAttenWarningChecked: true,
      isChangeWarningChecked: true,
      isLookAroundChecked: true,
      isPhoneWarningChecked: true};

  public workspace: Workspace;

  constructor(
        private _state: GlobalState,
        private themeService: NbThemeService,
        private dataService: DataService,
        private workspaceService: WorkspaceService,
        private notificationService: NotificationUtilService,
        private customerService: CustomerService,
        private driverService: DriverService,
        private translateJsonService: TranslateJsonService,
        protected translateService: TranslateService,
  ){
    this.traducaoCalendario();
  }

  ngOnInit(): void {
    this.loadUserWorkspace();
  }

  ngOnDestroy(): void {
    if(this.subsworkspace) this.subsworkspace.unsubscribe();
    if(this.subscustomers) this.subscustomers.unsubscribe();
    if(this.subsdrivers) this.subsdrivers.unsubscribe();
    if(this.subsonline) this.subsonline.unsubscribe();

    this.filterSelectedData.selectedDrivers = null;
    this.registerFilterInWorkspace();
  }

  // Altera as Datas para as últimas 24 Horas
  public changeOnlineInterval() {
    let today: Date = new Date()
      , today24hLast: Date = new Date()
      , today24hForward: Date = new Date();

      today24hLast.setHours(today.getHours() - 1*24);
      today24hForward.setHours(today.getHours());
      this.filterSelectedData.selectedDateStart = today24hLast;
      this.filterSelectedData.selectedDateEnd = today24hForward;
  }

  // Tradução do Calendário
  public traducaoCalendario(){
    this.ptBr = {
      firstDayOfWeek: 0,
      dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
      dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
      dayNamesMin: ["Do","Sg","Te","Qa","Qi","Sx","Sa"],
      monthNames: [ "Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro","Novembro","Dezembro" ],
      monthNamesShort: [ "Jan", "Fev", "Mar", "Abr", "Mai", "Jun","Jul", "Ago", "Set", "Out", "Nov", "Dez" ],
      today: 'Hoje',
      clear: 'Limpar'
    };
  }

  // Carrega os filtros já setados no Workspace para uma variavel local
  private getFilterAlarms(): FilterAlarms {
    let _filterData: FilterAlarms = new FilterAlarms();

    let kp: Workspace  = this.dataService.getWorkspace();
    this.workspace = kp;

    if (kp) {

      if (kp.selectedFilterAlams) {
        this.filterSelectedData = kp.selectedFilterAlams;
      }else{
        this.changeOnlineInterval();
      }

      if(this.filterSelectedData.selectedCustomers.length == 0){
        this.filterSelectedData.selectedCustomers = kp.userCustomerChildIds.map((customerChildsPermissions : customerChildsPermissions) => customerChildsPermissions.customer)
      }

      if(this.filterSelectedData.selectedAlarmTypes.length == 0){

        // ToDo: Utilizar o Workspace criado para cada usuário
        if(kp.customerProfileId == 3){
          this.filterSelectedData.selectedAlarmTypes = [1,2,3,4,6,7,8,9];
        }else{
          this.filterSelectedData.selectedAlarmTypes = [1,2,3,4,6,7,8,9];
        }
      }

      this.selectedCustomers = this.filterSelectedData.selectedCustomers;

      if(this.report) {
        this.selItemAlarmTypes = this.dataService.alarmTypesNumber;
        this.filterSelectedData.selectedAlarmTypes = [1,2,3,4,6,7,8,9];
      }
    }

    this.registerFilterInWorkspace();
    this.workspaceService.setFilterLoaded(true);

    this.loadCustomers();

    return _filterData;
  }

  // Carrega o Workspace, caso não exista, Cria
  public loadUserWorkspace(): void {
    if (!this.dataService.getWorkspace() || !this.dataService.getWorkspace().currentUserId) {
        this.subsworkspace = this.workspaceService.getWorkspace()
          .subscribe((_workspace: Workspace) => {
            this.dataService.registerWorkspace(_workspace);
            this.getFilterAlarms();
          },
          error => {
            this.notificationService.printErrorMessage(this.getTranslateSubs('FILTER-GLOBAL.msg_notresult') + error);
          });
      } else {
        this.getFilterAlarms();
      }
  }

  // Carrega as Empresas no select
  private loadCustomers(): void {
    let customerChildsUser: number[] = [];
    this.workspace.userCustomerChildIds.forEach((customerChildsPermissions : customerChildsPermissions ) => {
      customerChildsUser.push(customerChildsPermissions.customer);
    })

    if (!this.dataService.getAllCustomerChilds() || this.dataService.getAllCustomerChilds().length < 1) {
      if(customerChildsUser.length > 0){
        this.subscustomers = this.customerService.getCustomerChildsByIds(customerChildsUser).subscribe((_customers: CustomerChild[]) => {
            this.allCustomers = this.customerService.convertTreeCustomerChilds(_customers);
            this.dataService.registerCustomerChilds(_customers);
            this.loadedCustomer = true;
        })
      }
    }else{
      this.allCustomers = this.customerService.convertTreeCustomerChilds(this.dataService.getAllCustomerChilds());
      this.loadedCustomer = true;
      this.loadedDrivers = true;
      //this.loadSelectedDrivers();
      //setTimeout(()=>{ this.okLoadedDrivers(); }, 9000);
    }
  }

  /**
   * Adicionada um Node para cada Filial selecionada em Customers. Para cada Filial, procura os
   * motoristas que a fazem parte. Converte para o formato TreeNode e adiciona a árvore.
   */
  private loadSelectedDrivers(): void {
    this.allDrivers = [];
    let allDriversSingle = [];

    if(this.filterSelectedData && this.filterSelectedData.selectedCustomers.length > 0) {
      let selecteds = this.treeCustomers.treeModel.getActiveNodes();

      selecteds.forEach((treeSelec) => {
        treeSelec.data.children = [];
        this.allDrivers.push(treeSelec.data);

        this.subsdrivers = this.driverService.getDriversByCustomerIds([treeSelec.data.id])
        .subscribe((_drivers: Array<Driver>) => {
          let _node = this.treeDrivers.treeModel.getNodeById(treeSelec.data.id);

          _drivers.forEach((driver) => {
            allDriversSingle.push(driver);
            _node.data.children.push({id: driver.id, name: driver.name, child: true});
          })

          this.treeDrivers.treeModel.update();
          this.dataService.registerDrivers(allDriversSingle);
        },
        error => {
            console.info(this.getTranslateSubs('FILTER-GLOBAL.err_driver_register') , error);
        });
      })

      this.loadedDrivers = true;
    }else{
      this.loadedDrivers = false;
    }
  }

  // Caso o filtro esteja apenas recarregando informações já salvas
  private okLoadedDrivers(): void {

    let selecteds = this.treeCustomers.treeModel.getActiveNodes();
    //console.log(selecteds);

    selecteds.forEach((treeSelec) => {
      treeSelec.data.children = [];
      this.allDrivers.push(treeSelec.data);

      /*let _node = this.treeDrivers.treeModel.getNodeById(treeSelec.data.id);
      this.dataService.getAllDrivers().forEach((driver : Driver) => {
        if(driver.customerChildId == treeSelec.data.id){
          _node.data.children.push({id: driver.id, name: driver.name, child: true});
        }
      })*/

      this.treeDrivers.treeModel.update();
    })
  }

  // Ação ao clicar em pesquisar.
  public submitFilter(){
    this.workspaceService.filtrarData(true);
    this.submitFilterEmit.emit();
  }

  // Ação ao alterar uma Data
  public onChangeDates(): void {
    this.isOnlineChecked = false;
    this.workspaceService.setBotaoOnline(false);
    this.registerFilterInWorkspace();
  }

  /*
  * Registra no Workspace os filtros setados
  */
  public registerFilterInWorkspace() {
      let kp: Workspace  = this.dataService.getWorkspace();
      if (kp) {
          let _filter:FilterAlarms = kp.selectedFilterAlams;

          if (_filter) {
            _filter.selectedCustomers = this.filterSelectedData.selectedCustomers;
          } else {
            kp.selectedFilterAlams = this.filterSelectedData;
          }
      }
  }

  /*
  * Ação chamada sempre que um Node (Filial) é selecionado na Tree.
  * Recupera todas as Nodes da Tree e insere no filterSelectedData.
  */
  public selectedNode(node){
    if(node){
      this.selectedCustomers = node.treeModel.getActiveNodes().map((node) => node.id);
    }else{
      this.selectedCustomers = [];
    }
    this.filterSelectedData.selectedCustomers = this.selectedCustomers;
    this.registerFilterInWorkspace();
    this.loadedDrivers = true;
  }

  /**
  * Ação chamada sempre que um Node (Driver) é selecionado na Tree.
  * Recupera todas as Nodes da Tree e insere no filterSelectedData.
  */
  public selectedNodeDriver(){
    this.selectedDrivers = this.treeDrivers.treeModel.getActiveNodes().map((node) => node.id);
    this.filterSelectedData.selectedDrivers = this.selectedDrivers;
    this.registerFilterInWorkspace();
  }

  /*
  * Quando clicado no botão Customers este método habilita o checkbox de todas
  * as Nodes (Filiais) que o usuário possuí visão.
  */
  public activeNode(){
    this.dataService.getWorkspace().userCustomerChildIds.forEach((customerId: customerChildsPermissions) => {
      let nodeZin: TreeNode = this.treeCustomers.treeModel.getNodeById(customerId.customer);

      if(nodeZin){
        if(this.filterSelectedData.selectedCustomers.includes(customerId.customer)){
          nodeZin.treeModel.setActiveNode(nodeZin, true, true);
        }
      }

    })
  }

  /**
   * Os códigos abaixo realizam a função de fazer o 'tri-state' do checkbox da Árvore de Empresas
   * @param node
   * @param $event
   */
  public checked(node, $event) {
    this.updateChildNodesCheckBox(node, $event.target.checked);
    this.updateParentNodesCheckBox(node.parent);
    this.selectedNode(node);
  }

  public updateChildNodesCheckBox(node, checked) {
    node.treeModel.setActiveNode(node, checked, true);
    if (node.children) {
      node.children.forEach((child) => this.updateChildNodesCheckBox(child, checked));
    }
  }

  public updateParentNodesCheckBox(node) {
    if (node && node.level > 0 && node.children) {
      let allChildChecked = true;
      let noChildChecked = true;
      for (let child of node.children) {
        if (!child.isActive) {
          allChildChecked = false;
        } else if (child.isActive) {
          noChildChecked = false;
        }
      }

    if (allChildChecked) {
      node.treeModel.setActiveNode(node, true, true);
      node.data.indeterminate = false;
    } else if (noChildChecked) {
      node.treeModel.setActiveNode(node, false, true);
      node.data.indeterminate = false;
    } else {
      node.treeModel.setActiveNode(node, true, true);
      node.data.indeterminate = true;
    }

    this.updateParentNodesCheckBox(node.parent);
    }
  }

  public filterTree(name: string)
  {
    if(name.length>0)
      this.treeCustomers.treeModel.filterNodes(name);
    else
      this.treeCustomers.treeModel.collapseAll();
  }

  public loger(log: any){
    console.log(log);
  }

  private getTranslateSubs(key : string) {
    let translated;
    this.translateService.get(key).subscribe((res : string) => { translated = res; });
    return translated;
}

    //#region Métodos do TreeNode não utilizados
    //console.log(tree.treeModel.getActiveNodes());
    //console.log(this.treeCustomers);
    //let nodeZin: TreeNode = this.treeCustomers.treeModel.getNodeById(106);
    //let nodeZin2: TreeNode = this.treeCustomers.treeModel.getNodeById(107);
    //this.treeCustomers.treeModel.;
    //nodeZin.treeModel.setActiveNode(nodeZin, true, true);
    //tree.treeModel.setIsHidden(nodeZin2, true);
    //tree.treeModel.setSelectedNode(nodeZin, true);
    //console.log(nodeZin);
    //#endregion

    //#region
     /*if (!this.dataService.getAllCustomerChilds() || this.dataService.getAllCustomerChilds().length < 1) {
        this.subscustomers = this.customerService.getUserAllCustomers()
            .subscribe((_customers: CustomerChild) => {
                //this.allCustomers = this.customerService.getCustomerChildsMenu(_customers);
                this.loadedCustomer = true;
                this.dataService.registerCustomerChilds(_customers);

                this.loadSelectedDrivers();
        },
        error => {
            console.info('Falha na leitura do cadastro de empresas. ' , error);
        });
    } else {
      this.allCustomers = this.customerService.getCustomerChildsMenu(this.dataService.getAllCustomerChilds()[0]);
      this.loadedCustomer = true;

      //this.okLoadedDrivers();
    }*/
    //#endregion

}