import {Status} from '../enum/status.enum';

export class Customer {
   	 public id: number;   	 
     public customerId?: number;
     public customerChildId: number;
     public customerName: string;
     public customerChildName: string;
     public identification: string;     
     public integration: string;
     public email: string;
     public picture?: string;
     public icon?: string;
     public status: Status;
     public address: string;
}


