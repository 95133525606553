import { Component, OnInit } from "@angular/core";
import { AnalyticsService } from "./@core/utils/analytics.service";
import { Router } from "@angular/router";
import { AuthenticationService } from "./pages/login/authentication.service";
import { environment } from "environments/environment";
import { IdleService } from "./@core/utils/idle.service";
import { DataService } from "./@core/data/data.service";
import { Workspace } from "./@core/models/workspace.data.entity";

@Component({
  selector: "ngx-app",
  template: "<router-outlet></router-outlet>",
})
export class AppComponent implements OnInit {

  constructor(
    private analytics: AnalyticsService,
    private router: Router,
    private authService: AuthenticationService,
    private idleService: IdleService,
    private dataService: DataService
  ) {}

  ngOnInit(): void {
    this.analytics.trackPageViews();

    this.idleService
      .startWatching(environment.idleTimeOutMinutes)
      .subscribe((isUserInactive) => {
        const ws: Workspace = JSON.parse(localStorage.getItem('userWorkspace'));
        const hasCentralPermission = ws.accessControlDesc.some(permission => permission.permissions === 31);
        const centralPages = [
          '/pages/ui-alarms/bi#BI',
          '/pages/ui-alarms/fatigue-v2',
          '/pages/f/fatigue'
        ];

        const userLastName = this.dataService.userName.split(" ").pop();
        const isUserTreatment =
          environment.userTypeTreatment.includes(userLastName);

          if (!isUserTreatment) {
            const isSkipPath = this.idleService.isSkipPath(this.router.url);
  
            if (isUserInactive && !isSkipPath) {
              if(hasCentralPermission && !centralPages.includes(this.router.url)){
                this.idleService.resetTimer();
                this.authService.destroySession("cc=true&exp=true");
              }else if(!hasCentralPermission){
                this.idleService.resetTimer();
                this.authService.destroySession("cc=true&exp=true");
              }
          }
        }
      });
  }
}
