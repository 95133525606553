import { Marker } from 'leaflet';

export class ModelMarkers {
  id?: number;
  name: string;
  enabled: boolean;
  category: number;
  type: number;
  layer?: Marker;
  latitude: number;
  longitude: number;
  driverName?: string;
  plateAndPrefix?: string;
  alarmType?: string;
  alarmRisk?: number;
  isAlarm?: boolean;
  isInfraction?: boolean;
  infractionInfo?: string;
  
  description?: string;
  speed?: number;
  speed_unit?: string;
  datetime?: string;
  direction?: number;
}