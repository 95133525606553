import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Observable } from "rxjs";
import { CommandVoice } from "app/@core/models/command-voice.entity";
import { CommandVoiceService } from "app/@core/data/command-voice.service";
import { TranslateJsonService } from "app/@core/data/translate-json.service";

@Component({
  selector: 'voice-command',
  templateUrl: 'voice-command.component.html',
  styleUrls: ['voice-command.component.scss'],
})
export class VoiceCommandComponent {
  @Input('imei') imei: string;
  @Input('customerChildId') customerChildId: number;
  @Input('isLoading') isSending: boolean;
  
  @Output('sendMessage') sendMessage: EventEmitter<CommandVoice> = new EventEmitter();

  enabledCommandVoices$: Observable<CommandVoice[]>;
  selectedCommandVoice: CommandVoice;

  error: boolean = false;

  loadingMsg: string;
  selectMsg: string;

  constructor(
    public commandVoiceService: CommandVoiceService,
    public translateJsonService: TranslateJsonService
  ){
    this.enabledCommandVoices$ = this.commandVoiceService.getCommandsVoice$();

    this.selectMsg = translateJsonService.getTranslateSubs('COMMAND-VOICE.select');
    this.loadingMsg = translateJsonService.getTranslateSubs('COMMAND-VOICE.load');
  }

  ngOnInit() {
    this.commandVoiceService
      .loadCommandsVoiceFiltered([this.customerChildId], true)
      .subscribe(commandVoices => {
        this.commandVoiceService.setCommandsVoice(commandVoices);
        this.commandVoiceService.isUpdating$.next(false);
      }, err => {
        this.error = err;
        this.commandVoiceService.isUpdating$.next(false);
      });
  }

  sendingCommand() {
    this.sendMessage.emit(this.selectedCommandVoice);
    this.selectedCommandVoice = null;
  }
}