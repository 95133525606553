import {Injectable} from '@angular/core'

@Injectable()
export class NotificationCenterService {

  notReaded = 0;

  private _notifications = [
    {
      name: 'Vlad',
      text: 'Vlad posted a new article.',
      time: '1 min ago'
    },
    {
      name: 'Kostya',
      text: 'Kostya changed his contact information.',
      time: '2 hrs ago'
    },
    {
      image: 'assets/img/shopping-cart.svg',
      text: 'New orders received.',
      time: '5 hrs ago'
    },
    {
      name: 'Andrey',
      text: 'Andrey replied to your comment.',
      time: '1 day ago'
    },
    {
      name: 'Nasta',
      text: 'Today is Nasta\'s birthday.',
      time: '2 days ago'
    },
    {
      image: 'assets/img/comments.svg',
      text: 'New comments on your post.',
      time: '3 days ago'
    },
    {
      name: 'Kostya',
      text: 'Kostya invited you to join the event.',
      time: '1 week ago'
    }
  ];

  private _messages = [
    {
      name: 'Nasta',
      text: 'After you get up and running, you can place Font Awesome icons just about...',
      time: '1 min ago'
    },
    {
      name: 'Vlad',
      text: 'You asked, Font Awesome delivers with 40 shiny new icons in version 4.2.',
      time: '2 hrs ago'
    },
    {
      name: 'Kostya',
      text: 'Want to request new icons? Here\'s how. Need vectors or want to use on the...',
      time: '10 hrs ago'
    },
    {
      name: 'Andrey',
      text: 'Explore your passions and discover new ones by getting involved. Stretch your...',
      time: '1 day ago'
    },
    {
      name: 'Nasta',
      text: 'Get to know who we are - from the inside out. From our history and culture, to the...',
      time: '1 day ago'
    },
    {
      name: 'Kostya',
      text: 'Need some support to reach your goals? Apply for scholarships across a variety of...',
      time: '2 days ago'
    },
    {
      name: 'Vlad',
      text: 'Wrap the dropdown\'s trigger and the dropdown menu within .dropdown, or...',
      time: '1 week ago'
    }
  ];

  public getMessages():Array<Object> {
    return this._messages;
  }

  public getNotifications():Array<Object> {
    return this._notifications;
  }

  hideLowAlertToast(event){    
    window.localStorage.setItem('hideLowAlertToast', event.currentTarget.checked);
  }

  getHideLowAlertToast(){
    const hideLowAlertToast = window.localStorage.getItem('hideLowAlertToast');

    if(hideLowAlertToast){
      return JSON.parse(hideLowAlertToast);
    }else{
      window.localStorage.setItem('hideLowAlertToast', 'true');
      return true;
    }
  }

  hideMediumAlertToast(event){    
    window.localStorage.setItem('hideMediumAlertToast', event.currentTarget.checked);
  }

  getHideMediumAlertToast(){
    const hideMediumAlertToast = window.localStorage.getItem('hideMediumAlertToast');

    if(hideMediumAlertToast){
      return JSON.parse(hideMediumAlertToast);
    }else{
      window.localStorage.setItem('hideMediumAlertToast', 'true');
      return true;
    }
  }

  hideHighAlertToast(event){    
    window.localStorage.setItem('hideHighAlertToast', event.currentTarget.checked);
  }

  getHideHighAlertToast(){
    const hideHighAlertToast = window.localStorage.getItem('hideHighAlertToast');

    if(hideHighAlertToast){
      return JSON.parse(hideHighAlertToast);
    }else{
      window.localStorage.setItem('hideHighAlertToast', 'true');
      return true;
    }
  }

  autoCloseAlertToast(event){
    window.localStorage.setItem('autoCloseAlertToast', event.currentTarget.checked);
  }

  getAutoCloseAlertToast(){
    const autoCloseAlertToast = window.localStorage.getItem('autoCloseAlertToast');

    if(autoCloseAlertToast){
      return JSON.parse(autoCloseAlertToast);
    }else{
      window.localStorage.setItem('autoCloseAlertToast', 'false');
      return false;
    }
  }

  setNotReaded(count){
    console.log(count);
    this.notReaded = count;
  }

  getSoundVoice(){
    const soundVoice = window.localStorage.getItem('soundVoice');

    if(soundVoice){
      return JSON.parse(soundVoice);
    }else{
      window.localStorage.setItem('soundVoice', 'true');
      return true;
    }
  }

  soundVoice(event){
    window.localStorage.setItem('soundVoice', event.currentTarget.checked);
  }
}
