﻿export class FilterAlarms {
    selectedCustomers: number[] = []; 
    selectedAlarmTypes?: number[] = [];
    selectedInfractionTypes?: number[] = [];    
    selectedDrivers?: number[] = []; 
    selectedVehicles?: number[] = [];
    selectedMessages?: number[] = [];
    selectedDateStart: Date;
    selectedDateEnd: Date;
    selectedOnline: boolean;
    selectedImei?: string;
    selectedVehicleName?: string;
    selectedEquipmentType?: number;

    public isValidFilter() : boolean {
        if (this.selectedDateStart && this.selectedDateEnd) {
            return true;
        }else if (this.selectedCustomers && this.selectedDateStart && this.selectedDateEnd) {
        	return true;
        }else if (this.selectedDrivers && this.selectedDateStart && this.selectedDateEnd) {
            return true;
        }else if (this.selectedCustomers && this.selectedDrivers && this.selectedDateStart && this.selectedDateEnd) {
            return true;
        }else if (this.selectedCustomers && this.selectedDrivers && this.selectedVehicles && this.selectedDateStart && this.selectedDateEnd) {
            return true;
        }else if(this.selectedCustomers && this.selectedVehicles && this.selectedDateStart && this.selectedDateEnd) {
            return true;
        }else {
        	return false;
        }
    }
}

