
import {throwError as observableThrowError, of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { Http, Response, Headers } from '@angular/http';

import { ConfigService } from '../config/config.service';
//import { DataService } from '../data/data.service';
import {Driver, Customer, WorkspaceProfileData, AlarmType, Asset, Alarm, FilterAlarms, Device, Equipament} from '../models';
import {mockCustomerData} from '../mock/data/customers.mock.data';
import {mockWorkspaceData} from '../mock/data/workspace.mock.data';
import {mockDriversData} from '../mock/data/drivers.mock.data';
import {mockAlarmTypesData} from '../mock/data/alarm.type.mock.data';
import {mockAssetsData} from '../mock/data/assets.mock.data';
import {mockAlarmsData} from '../mock/data/alarms.mock.data';
import { mockDevicesData } from '../mock/data/device.mock.data';
import { mockEquipmentData } from '../mock/data/equipment.mock.data';

@Injectable()
export class MockService {

  private alarmArray: any[];
  private baseUrl;

  constructor(
    private http: Http, 
    private configService: ConfigService,
    ) { 
      this.baseUrl = configService.getApiURI();
  }

   public getAssetByAssetId(assetId: number): Asset {
      return <Asset>mockAssetsData.find((asset: Asset) => asset && asset.id === assetId);
   }


   public getDriverByDriverId(driverId: number): Driver {
      return <Driver>mockDriversData.find((driver: Driver) => driver && driver.id === driverId);
   }



   public getAlarmByAlarmId(alarmId: number): Alarm {
      return <Alarm>mockAlarmsData.find((alarm: Alarm) => alarm && alarm.id === alarmId);
   }


   public getAsyncAlarmByAlarmId(alarmId: number): Observable<Alarm> {
      let _result: Alarm = <Alarm>mockAlarmsData.find(
      (alarm: Alarm) => alarm && alarm.id === alarmId);
      return  observableOf(_result);   
   }

  public getAssetById(assetId: number): Observable<Asset> {
    console.info('Mock: getAssetById');
    let _result: Asset = <Asset>mockAssetsData.find(
    (asset: Asset) => asset.id === assetId);
    return  observableOf(_result);
  }

  public getAssetsByIds(assetsIds: number[]): Observable<Asset[]> {
    console.info('Mock: getAssetsByIds');

    let _result: Asset[] = [];
    assetsIds.forEach((assetId)=>{
      _result.push(<Asset>mockAssetsData.find((_asset: Asset) => _asset.id === assetId));
    });

    return  observableOf(_result);
  }

  public getAllAssets(customerChildId: number): Observable<Asset[]> {
    let _result: Asset[] = mockAssetsData.filter(
      (asset: Asset) => asset.customerChildId === customerChildId && asset.status === 2);
      return  observableOf(_result);
  }

  public getEquipmentById(equipId: number): Observable<Equipament>{
    console.log("Mock: getEquipmentById");
    let _result: Equipament = <Equipament>mockEquipmentData.find((equip: Equipament)=>equip.id==equipId)
    return  observableOf(_result);
  }

  public getAllEquipment(): Observable<Equipament[]> {
    console.log("Mock: getAllEquipment");
    let _equipments: Equipament[] = <Equipament[]>mockEquipmentData;
    return observableOf(_equipments);
  }

  public getCustomerByDriverId(driverId: number): Customer {
  let _result: Customer
  , _driver:Driver = mockDriversData.find((driver: Driver) => driver.id === driverId)

  if (_driver) {
      _result = <Customer>mockCustomerData.find(
        (customer: Customer) => customer.customerChildId === _driver.customerChildId
      );    
  }
    return  _result;
  }

  public getAlarmTypes() : Observable<AlarmType[]>  {
    //let _result: AlarmType[] = <AlarmType[]>mockAlarmTypesData;
    let _result: AlarmType[];
    return  observableOf(_result);
  }

  /*public getAlarmTypeByType(type: string): AlarmType {
    return mockAlarmTypesData.find(((alarmType: AlarmType) => alarmType.integration === type));
  }*/


  public getWorkspaceUser(currentUserId: number) :Observable<WorkspaceProfileData>  {
    let _result : WorkspaceProfileData;
    let _workspaces: WorkspaceProfileData = mockWorkspaceData.find((workspace: WorkspaceProfileData) => 
      workspace.currentUserId === currentUserId
    );
    _result = _workspaces;
    return observableOf(_result);
  }

  public getUserCustomers(customerChildIds: number[]):Observable<Customer[]>  {
    let _customers: Customer[] = mockCustomerData.filter(customer => 
       customerChildIds && customerChildIds.find((customerChildId: number) => customerChildId === customer.customerChildId)
     );

    return observableOf(_customers);
  }



  public getAlarmsByFilter(_filter: FilterAlarms): Alarm[] {
      return this.filterApplyAlarms(_filter, mockAlarmsData);
  }


  public getCustomersByCustIds(customerChildIds: number[]):Customer[]  {
    return mockCustomerData.filter(customer => 
       customerChildIds && customerChildIds.find((customerChildId: number) => customerChildId === customer.customerChildId)
     );
  }

  public getAssetsByCustIds(customerChildIds: number[]):Asset[]  {
    return mockAssetsData.filter(asset => 
       customerChildIds && customerChildIds.find((customerChildId: number) => customerChildId === asset.customerChildId)
     );
  }

  public getDriversByCustIds(customerChildIds: number[]):Driver[]  {
    return mockDriversData.filter(driver => 
       customerChildIds && customerChildIds.find((customerChildId: number) => customerChildId === driver.customerChildId)
     );
  }

  
  public getDriversByCustomerIds(customerChildIds: number[]):  Observable<Driver[]>  {
    let _drivers: Driver[] = mockDriversData.filter(driver => 
       customerChildIds && customerChildIds.find((customerChildId: number) => customerChildId === driver.customerChildId)
     );
    return observableOf(_drivers);
  }

  public getAllDevicesByStatus(status: number): Observable<Device[]>{
    console.log("Mock: getAllDevicesByStatus");
    let _devices: Device[] = mockDevicesData.filter(devices => 
      devices.status == status
    );
    return observableOf(_devices);
  }

  public getDevicesByEquipment(equipIds: number[]): Observable<Device[]>{
    console.log("Mock: getDevicesByEquipment");
    let _devices: Device[] = [];
    equipIds.forEach((equip)=>{
      mockDevicesData.map((devices)=>{
        if(devices.equipId == equip){
          _devices.push(devices);
        }
      })
    })
    return observableOf(_devices);
  }

  public getDevicesByEquipmentAndStatus(equip: number, status: number): Observable<Device[]>{
    let _devices: Device[] = mockDevicesData.filter(devices => 
      devices.status == status || devices.equipId == equip
    );
    return observableOf(_devices);
  }

  public filterApplyAlarms(_filter: FilterAlarms, alarms: Alarm[]): Alarm[] {
     let _result = new Array<Alarm>();
     _result = alarms.filter(alarm => {
       let _result_: boolean = false,
       customer: Customer = this.getCustomerByDriverId(alarm.driverId);
       
       if (customer && _filter.selectedCustomers && 
           _filter.selectedCustomers.find(customerChildId => customerChildId === customer.customerChildId)) {
         _result_ = true;
       };

       if (_filter.selectedDrivers && _filter.selectedDrivers.length > 0) {
         if (_filter.selectedDrivers.find(driverId => driverId === alarm.driverId)) 
           _result_ = true;
         else 
           _result_ = false;
       }


       /*if (_result_ && _filter.selectedAlarmTypes && _filter.selectedAlarmTypes.length > 0) {
         //let alarmType: AlarmType = this.getAlarmTypeByType(alarm.type);
         if (_filter.selectedAlarmTypes.find(alarmTypeId => alarmTypeId === alarmType.id)) 
           _result_ = true;
         else 
           _result_ = false;
       }*/

       if (_result_ && _filter.selectedDateStart && _filter.selectedDateEnd) {
         _result_ = (alarm.datetime >= _filter.selectedDateStart && alarm.datetime <= _filter.selectedDateEnd);
       }
       
       return _result_;
     });
     return _result;
  }
  
    private handleError(error: any) {
      let errMsg = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
      console.error('Erro:', error);
      return observableThrowError(errMsg);
    }  

}
