import { Component } from '@angular/core';
import { Subscription } from 'rxjs';

import { DataService } from 'app/@core/data/data.service';

import { IFilterOutletChild } from 'app/@core/interfaces/IFilterOutletChild';

@Component({
    selector: 'filters-outlet',
    template: `
    <nb-card>
        <focus-filter-v2 
            (loadedFilter)="onLoadedFilter()" 
            (submitFilter)="onSubmitFilter()"
            [milisecondsLimitDateIntervalFilter]="dateIntervalLimit"
            [requestInProgress]="requestInProgress">
        </focus-filter-v2>
    </nb-card>
    <router-outlet (activate)="onActivate($event)"></router-outlet>
    `,
})
export class FiltersOutlet {
    private childComponent: IFilterOutletChild;
    
    public dateIntervalLimit = 86400000 * 2;
    public requestInProgress: boolean = false;
    public subRequestInProgress: Subscription;
    
    constructor(private dataService: DataService) {}

    public onActivate(componentChildRef: IFilterOutletChild) {
        this.childComponent = componentChildRef;

        if(this.childComponent) {
            this.onLoadWorkspace();
            this.onLoadedFilter();
            this.subscribeToRequestInProgress();

            const { dateIntervalLimit } = this.childComponent;

            if (dateIntervalLimit) {
                this.dateIntervalLimit = dateIntervalLimit
            }
        }
    }

    public onLoadedFilter() {
        if(this.childComponent && this.childComponent.onLoadedFilter) {
            this.childComponent.onLoadedFilter();
        }
    }

    public onSubmitFilter() {
        if(this.childComponent && this.childComponent.onSubmitFilter) {
            this.childComponent.onSubmitFilter();
        }
    }

    private onLoadWorkspace() {
        const ws  = this.dataService.getWorkspace();

        if(ws.selectedFilterAlams) {
            this.childComponent.filterSelectedData = ws.selectedFilterAlams;
        }
    }

    private subscribeToRequestInProgress(): void {
        if(this.subRequestInProgress) {
            this.subRequestInProgress.unsubscribe();
        }

        if(this.childComponent && this.childComponent.requestInProgress) {
            this.subRequestInProgress = this.childComponent.requestInProgress.subscribe((value: boolean) => this.requestInProgress = value);
        }
    }
}