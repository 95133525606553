export const environment = {
  appTitle:'Foco na Via',
  isGoawake:false,
  companyName:'Foco na Via',
  creareName:'Foco na Via',
  userNameCreare:'Creare',
  urlCreareWebsite: ' ',
  logo:'assets/assets-third/foconavia/images/LOGO-FOCO-VIA.png',
  urlApi:'https://api.foconavia.com.br/api/',
  urlCommandsApi:'undefined',
  urlFocusRedis: 'http://focusredis.foconavia.com.br',
  orFrotalog:' ',
  domain:'foconavia.com.br',
  defaultUserImg:'assets/assets-third/foconavia/images/user_default.png',
  notFoundImg:'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grkocmhf7oyf/b/GoAwakePublicObjects/o/image-not-found.png',
  jsonLink: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grkocmhf7oyf/b/GoAwakePublicObjects/o/ABRANGENCIA_PERIMETRO_SVAA_16-06-2020.geojson',
  recaptchaSitekey:'6LcDFKcaAAAAAEccDbfhLLGNqJpPzfiYcv_I3CV-',
  production:true,
  nodeEnv: 'production',
  urlTelemetry: 'https://api.foconavia.com.br/telemetry',
  urlEvents: '',
  alarmTypesRequiredFatigueMedias:'1,2,3,4,5,6,7,8,9',
  emailEvents: '',
  loginRoute: 'foconavia',
  loginUrl: 'https://login.foconavia.com.br/',
  allowedAccessList: '',
  satelliteMapApi: 'https://tiles.mapquest.com/render/latest/satellite/{z}/{x}/{y}/256/png',
  defaultMapApi: 'https://osm.crearecloud.com.br/tiles/{z}/{x}/{y}.png',
  baseUrl: 'https://www.foconavia.com.br',
  idleTimeOutMinutes: 30,
  idleSkipPaths: [""],
  userTypeTreatment: ["(Conclusion)"],
  fenceApiUrl: 'undefined',
  fenceApiKey: 'undefined',
  urlCoreApi: 'https://api-qa.crearecloud.com.br/core/',
  urlCoreFront: 'https://foconavia.com.br/core/',
  apikey: 'QXBpIEtleSBUZWxlbWV0cnk=',
  customTemplateEmailCustomerIds: [" "],
  equipmentsTypeIdWithoutMedia: '5,9' ,
  ambiparDealerId: 'undefined',
  operationMapUrl: 'undefined',
  vecchCodelcoApiUrl: 'undefined',
  gobusProgVsRealBff: 'undefined',
  urlBusRoute: 'undefined',
  googleMapsKey: 'undefined',
  coreTripsFrontendUrl: 'undefined',
  customersWithCustomTranslation: 'undefined',
  streamingExpirationTime: '60000',
  playbackIntervalHours: 6,
  playbackDownloadsLimitInMonths: 12,
};
