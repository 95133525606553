import { User } from "./user.entity";
import { FilterAlarms } from "./filter.alarms.entity";

export class Workspace {
    public currentUser?: User;
    public accessControl: number[];
    public accessControlDesc: AccessControlDesc[];
    public currentUserId: number;
    public userCustomerChildIds?: customerChildsPermissions[]; 
    public selectedFilterAlams?: FilterAlarms = new FilterAlarms();	
    public customerProfileId: number;
    public dealerLogo?: string;
    public dealerName?: string;
    public isDealer: boolean;
    public isPlatformAdmin: boolean;
    public dealerId: number;
    public onlineTimeInterval: number;
}

export class customerChildsPermissions {
    public permissions: number;
    public user: number;
    public customer: number;
    public customerProfile?: number;
}

export class AccessControlDesc {
    public id: number;
    public permissions: number;
    public permissionDesc: string;
}