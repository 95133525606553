import { Injectable } from "@angular/core";
import { Headers, Http } from "@angular/http";
import { ConfigService } from "app/@core/config/config.service";
import { Tag } from "app/@core/models/tag.entity";
import { AuthenticationService } from "app/pages/login/authentication.service";
import { environment } from "environments/environment";
import { Observable, throwError as observableThrowError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { EventResponseModel } from "./models/event-response.model";
import { EventModel } from "./models/event.model";
import { TelemetryPositionsModel } from "./models/telemetry-positions.model";
import { TelemetryResponseModel } from "./telemetry-response.model";

@Injectable({ 
    providedIn: 'root'
})
export class TelemetryService {

    private baseUrl;
    constructor(
        private http: Http,
        private configService: ConfigService,
        private auth: AuthenticationService
    ) {
        this.baseUrl = configService.getApiURI();
    }

    public getCurrentPositions(assetsId: string[]): Promise<TelemetryPositionsModel[]> {
        return new Promise((resolve, reject) => {             
            const _ws = this.configService.webServicesV2['getCurrentPositionsByAssets'];

            this.get(_ws.url(environment.urlApi, assetsId))
                .subscribe((response: TelemetryResponseModel) => resolve(response.data.positions), error => reject(error))
        })
    }

    public getCurrentPositionsByAssetsIdList(assetsId: string[]): Promise<TelemetryPositionsModel[]> {
        return new Promise((resolve, reject) => {             
            const _ws = this.configService.webServicesV2['getCurrentPositionsByAssetsIdList'];

            this.post(_ws.url(environment.urlApi, assetsId), {assets_id: assetsId.join(',')})
                .subscribe((response: TelemetryResponseModel) => 
                    resolve(response.data.positions), error => reject(error)
            )
        })
    }

    public getCurrentPositionsByDeviceId(devicesId: string[]): Promise<TelemetryPositionsModel[]> {
        return new Promise((resolve, reject) => {             
            const _ws = this.configService.webServicesV2['getCurrentPositionsByDevices'];

            this.get(_ws.url(environment.urlApi, devicesId))
                .subscribe((response: TelemetryResponseModel) => resolve(response.data.positions), error => reject(error))
        })
    }

    public getCurrentPositionsByDeviceIdList(devicesId: string[]): Promise<TelemetryPositionsModel[]> {
        return new Promise((resolve, reject) => {             
            const _ws = this.configService.webServicesV2['getCurrentPositionsByDeviceIdList'];
        
            this.post(_ws.url(environment.urlApi), {devices_id: devicesId.join(',')})
                .subscribe((response: any) => 
                    resolve(response.data.positions), error => reject(error)
                )
            })
    }  


    public getTrackingByAssetId(assetId: string, startDate: Date, endDate: Date): Promise<TelemetryPositionsModel[]> {
        return new Promise((resolve, reject) => {
            const _ws = this.configService.webServicesV2['getTrackingByAsset'];

            this.get(_ws.url(environment.urlApi, assetId, startDate, endDate))
                .subscribe((response: TelemetryResponseModel) => resolve(response.data.positions), error => reject(error))
        })
    }
    
    public getTrackingByDeviceId(deviceId: string, startDate: Date, endDate: Date): Promise<TelemetryPositionsModel[]> {
        return new Promise((resolve, reject) => {
            const _ws = this.configService.webServicesV2['getTrackingByDevice'];

            this.get(_ws.url(environment.urlApi, deviceId, startDate, endDate))
                .subscribe((response: TelemetryResponseModel) => resolve(response.data.positions), error => reject(error))
        })
    }

    public getEvents(customerChildId: string[],vehiclesId: string[], driverId: string[], startDate: Date, endDate: Date, displayRead: boolean, allTypes: boolean): Promise<EventModel[]> {
        return new Promise((resolve, reject) => {
            const _ws = this.configService.webServicesV2['getEvents'];
            const TYPES_FIND = (allTypes) ? [] : ['911', '911-CANCEL', 'HELP', 'HELP-CANCEL', 'PANIC_BUTTON'];
            const body = {
                vehicles: vehiclesId.join(','),
                drivers: driverId.join(','),
                customers: customerChildId.join(','),
                start_date: startDate.toISOString(),
                end_date: endDate.toISOString(),
                display_read: displayRead,
                types: TYPES_FIND.join(',')
            }
            this.post(_ws.url(environment.urlEvents), body)
                .subscribe((response: EventResponseModel) => resolve(response.data), error => reject(error))
        })
    }

    public saveAction(action: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const _ws = this.configService.webServicesV2['saveEventAction'];

            this.put(_ws.url(environment.urlEvents, action.id), action).subscribe(result => resolve(result), error => reject(error));
        })
    }

    public getLastPositionsByDevice(deviceId: string, limit: number): Promise<TelemetryPositionsModel[]> {
        return new Promise((resolve, reject) => {
            const _ws = this.configService.webServicesV2['getLastPositionsByDevice']

            this.get(_ws.url(environment.urlApi, deviceId, limit))
                .subscribe((response: TelemetryResponseModel) => resolve(response.data.positions), error => reject(error))
        })
    }

    private get(URL: string):Observable<any> {
        return this.http.get(URL, { headers: this.getHttpHeaders()}).pipe(
            map((res: Response) => {
              return res.json();
            }),
            catchError(this.handleError)
          )
    }

    private post(URL: string, body: any) {
        return this.http.post(URL, body, { headers: this.getHttpHeaders() }).pipe(map((res: Response) => {
            return res.json();
        }), catchError(this.handleError));
    }

    private put(URL: string, body: any) {
        return this.http.put(URL, body, { headers: this.getHttpHeaders() }).pipe(map((res: Response) => {
            return res;
        }), catchError(this.handleError));
    }

    private handleError(err: any) {
        let error = err.json();
    
        return observableThrowError( (error.message) ? error.message : error );
    }

    private getHttpHeaders() : Headers {
        let headers = new Headers();

        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', this.auth.getToken());

        return headers;
    }

    public getTagsByDevices(deviceIdList: String[], startDate: Date, endDate: Date, tagTypeIdList: Number[] = []): Observable<Tag[]> {
        const _ws = this.configService.webServicesV2['getTagsByDevice'];
        const body = {
            deviceIdList,
            tagTypeIdList,
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString()
        }
        return this.post(_ws.url(this.baseUrl), body) as Observable<Tag[]>;

    }
}