import { AlarmAndInfraction } from './../../../@core/models/alarmAndInfraction.entity';
import { TrackPlayBackEvent } from './../../components/leaflet-map/playback/playback-event.model';
import { Injectable } from "@angular/core";
import { AssetTelemetryStatus } from "app/@core/enum/asset-telemetry-status.enum";
import { Alarm, Asset } from "app/@core/models";
import { CustomerChild } from "app/@core/models/customerChild.entity";
import { ModelMarkers } from "app/pages/components/leaflet-map/marker-model";
import * as moment from "moment";
import { BehaviorSubject, Observable } from "rxjs";
import { EventModel } from "./models/event.model";
import { TelemetryPositionsModel } from "./models/telemetry-positions.model";
import { Infraction } from 'app/@core/models/infraction.entity';
import { AssetType } from 'app/@core/enum/asset-type.enum';

@Injectable()
export class TelemetryState {

  protected updating$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  protected customersChild$: BehaviorSubject<CustomerChild[]> = new BehaviorSubject<CustomerChild[]>([]);
  protected vehicles$: BehaviorSubject<Asset[]> = new BehaviorSubject<Asset[]>([]);
  protected events$: BehaviorSubject<EventModel[]> = new BehaviorSubject<EventModel[]>([]);
  protected hidePositionIcons$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  protected tracking$: BehaviorSubject<EventModel[]> = new BehaviorSubject<any[]>([]);
  protected trackingPlay$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  protected trackingInfos$: BehaviorSubject<TrackPlayBackEvent> = new BehaviorSubject<TrackPlayBackEvent>({ curTime: 0, startTime: 0, endTime: 0, speed: 0 });
  protected trackingAlerts$: BehaviorSubject<ModelMarkers[]> = new BehaviorSubject<ModelMarkers[]>([]);
  protected trackingEvents$: BehaviorSubject<ModelMarkers[]> = new BehaviorSubject<ModelMarkers[]>([]);
  protected trackingCurTime$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  protected trackingPlaySpeed$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  protected isReconstructingRoute$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
  ) { }

  isUpdating$() {
    return this.updating$.asObservable();
  }

  setUpdating(updating: boolean) {
    this.updating$.next(updating);
  }

  getVehicles$() {
    return this.vehicles$.asObservable();
  }

  getVehicles() {
    return this.vehicles$.getValue();
  }

  setVehicles(vehicles: Asset[]) {
    vehicles.forEach(vehicle => {
      if (!vehicle.status) {
        vehicle.telemetryStatus = AssetTelemetryStatus.Offline
      }
    })

    this.vehicles$.next(vehicles);
  }

  getCustomersChild$() {
    return this.customersChild$.asObservable();
  }

  setCustomersChild(customersChild: CustomerChild[]) {
    this.customersChild$.next(customersChild);
  }

  getEvents$() {
    return this.events$.asObservable();
  }

  setEvents(events: EventModel[]) {
    this.events$.next(events);
  }

  getEvents() {
    return this.events$.getValue();
  }

  updateEvents(events: EventModel[]): void {
    const old: Asset[] = this.vehicles$.getValue();

    old.forEach(asset => {
      const historyEvents = (asset.events) ? asset.events.filter(e => e.fromHistory) : [];

      asset.events = [];
      let finded = [];
      if (asset.assetType == AssetType.driver) {
        finded = events.filter(e => e.driver.id == asset.id.toString());
      } else {
        finded = events.filter(e => e.vehicle.id == asset.id.toString());
      }

      if(finded) {
        asset.events.push(...finded);
      }

      asset.events.push(...historyEvents);
    })

    this.vehicles$.next(old);
    this.setEvents(events);
  }

  addEvents(events: EventModel[]): void {
    const old: Asset[] = this.vehicles$.getValue();
    old.forEach(asset => {
      let newEventsForAsset = []
      if (asset.assetType == AssetType.driver) {
        newEventsForAsset = events.filter(e => e.driver.id == asset.id.toString());
      } else {
        newEventsForAsset = events.filter(e => e.vehicle.id == asset.id.toString());
      }

      if (newEventsForAsset && newEventsForAsset.length > 0) {
        newEventsForAsset.forEach(newEvent => {
          if (asset.events) {
            const exists = asset.events.find(e => e._id == newEvent._id);

            if(!exists) {
              asset.events.push(newEvent);
            }
          }else{
            asset.events = [newEvent];
          }          
        })
      }
    })

    this.vehicles$.next(old);
  }

  removeEvents(events: EventModel[]): void {
    const old: Asset[] = this.vehicles$.getValue();

    old.forEach(asset => {
      let deleteEventsForAsset = [];
      if (asset.assetType == AssetType.driver) {
        deleteEventsForAsset = events.filter(e => e.driver.id == asset.id.toString());
      } else {
        deleteEventsForAsset = events.filter(e => e.vehicle.id == asset.id.toString());
      }

      if (deleteEventsForAsset && deleteEventsForAsset.length > 0) {
        const filteredEvents = asset.events.filter(e => !deleteEventsForAsset.map(v => e._id).includes(e._id));
        asset.events = filteredEvents;
      }
    })

    this.vehicles$.next(old);
  }

  updateTelemetryStatus(telemetryPositionsModel: TelemetryPositionsModel[]): void {
    const old: Asset[] = this.vehicles$.getValue();

    old.forEach(vehicle => {
      const finded = telemetryPositionsModel.find(t => t.asset_id == vehicle.id.toString());

      if (finded) {
          vehicle.telemetryStatus = AssetTelemetryStatus.Online;
          vehicle.telemetry = finded;
      }
    })

    this.vehicles$.next(old);
  }

  updateAlarms(alarms: Alarm[]): void {
    const old: Asset[] = this.vehicles$.getValue();

    old.forEach(vehicle => {
      vehicle.alerts = [];
      let biggerRisk = 0;

      alarms.forEach(alarm => {
        if(alarm.vehicleId == vehicle.id) {
          vehicle.alerts.push(alarm);

          if(alarm.groupRisk > biggerRisk) {
            biggerRisk = alarm.groupRisk;
          }
        }
      })

      if(vehicle.alerts.length < 1) {
        vehicle.riskGroup = null;
      }else{
        vehicle.riskGroup = biggerRisk.toString();
        vehicle.driverName = vehicle.alerts[0].driver_firstname;
      }
    })

    this.vehicles$.next(old);
  }

  updateInfractions(infractionInformationList) {
    const old: Asset[] = this.vehicles$.getValue()

    infractionInformationList.forEach(infractionInformation => {
      if(infractionInformation.infractionList && infractionInformation.infractionList.length > 0) {
        old.forEach(vehicle => {
          vehicle.infractions = [];
          infractionInformation.infractionList.forEach(infraction => {
            if(infraction.assetId == vehicle.id) {
              vehicle.infractions.push(infraction);
            }
          })
        })
        this.vehicles$.next(old);
      }
    })
  }

  arrayMax(arr) {
    return arr.reduce(function (p, v) {
      return ( p > v ? p : v );
    });
  }

  addTracking(tracking) {
    this.tracking$.next(tracking);
  }

  getTracking() {
    return this.tracking$.asObservable();
  }

  trackPlay() {
    this.trackingPlay$.next(true);
  }

  trackStop() {
    this.trackingPlay$.next(false);
  }

  getTrackPlayed() {
    return this.trackingPlay$.asObservable();
  }

  getTrackingInfos$() {
    return this.trackingInfos$.asObservable();
  }

  setTrackingInfos(trackInfos) {
    this.trackingInfos$.next(trackInfos);
  }

  setTrackingAlerts(marker: ModelMarkers[]) {
    this.trackingAlerts$.next(marker);
  }

  setTrackingEvents(marker: ModelMarkers[]) {
    this.trackingEvents$.next(marker);
  }

  getTrackingAlerts$(): Observable<ModelMarkers[]> {
    return this.trackingAlerts$.asObservable();
  }

  getTrackingEvents$(): Observable<ModelMarkers[]> {
    return this.trackingEvents$.asObservable();
  }

  setHidePositionIcons(hide: boolean) {
    this.hidePositionIcons$.next(hide)
  }

  getHidePositionsIcons$(): Observable<boolean> {
    return this.hidePositionIcons$.asObservable();
  }

  getHidePositionsIcons(): boolean {
    return this.hidePositionIcons$.getValue();
  }

  getTrackingCurTime$() {
    return this.trackingCurTime$.asObservable();
  }

  setTrackingCurTime(curTime: number) {
    this.trackingCurTime$.next(curTime);
  }

  getTrackingPlaySpeed$() {
    return this.trackingPlaySpeed$.asObservable();
  }

  setTrackingPlaySpeed(newSpeed: number) {
    this.trackingPlaySpeed$.next(newSpeed);
  }

  public getIsReconstructingRoute(): Observable<boolean> {
    return this.isReconstructingRoute$.asObservable();
  }

  public setIsReconstructingRoute(value: boolean): void {
    this.isReconstructingRoute$.next(value);
  }

  public clearTracking(): void {
    this.addTracking([]);
    this.setTrackingAlerts([]);
    this.setTrackingEvents([]);
    this.setHidePositionIcons(false);
  }

}