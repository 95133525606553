import {throwError as observableThrowError, of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import {catchError, map} from 'rxjs/operators';

import {User} from '../models';
import { AuthenticationService } from 'app/pages/login/authentication.service';
import { ConfigService } from '../config/config.service';
import { UserSubs } from '../models/user-subs.entity';
import { AccessControl } from '../models/accessControl.entity';
import { environment } from 'environments/environment';

let counter = 0;

@Injectable()
export class UserService {

  private users = {
    creare: { name: environment.userNameCreare, picture: 'assets/images/creare.png' },
    nick: { name: 'Nick Jones', picture: 'assets/images/nick.png' },
    eva: { name: 'Eva Moor', picture: 'assets/images/eva.png' },
    jack: { name: 'Jack Williams', picture: 'assets/images/jack.png' },
    lee: { name: 'Lee Wong', picture: 'assets/images/lee.png' },
    alan: { name: 'Alan Thompson', picture: 'assets/images/alan.png' },
    kate: { name: 'Kate Martinez', picture: 'assets/images/kate.png' },
  };

  private userArray: any[];
  private baseUrl: string;

  constructor(
    protected configService: ConfigService,
    protected http: Http,
    protected auth: AuthenticationService
  ){
    this.baseUrl = configService.getApiURI();
  }

  getHttpHeaders() : Headers {
    let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', this.auth.getToken());

    return headers;
  }

  private handleError(error: any) {
    let errorMsg = (error.message) ? error.json() : error.text();

    return observableThrowError(errorMsg);
  } 

  /**
   * Cadastra um novo Usuário Focus
   * @param user Objeto Usuário
   */
  createUser(user : User): Observable<string> {
    let _ws = this.configService.webServices['crudUser'];

    return this.http.post(_ws.url(this.baseUrl), user, {headers: this.getHttpHeaders()}).pipe(
      map((res : Response) => {
        return res.text();
      }),catchError(this.handleError),);     
  }

  /**
   * Atualiza um Usuário Focus
   * @param user Objecto Usuário
   */
  updateUser(user : User): Observable<string> {
    let _ws = this.configService.webServices['crudUser'];

    return this.http.put(_ws.url(this.baseUrl), user, {headers: this.getHttpHeaders()}).pipe(
      map((res : Response) => {
        return res.text();
      }),catchError(this.handleError)
    );
  }

  getUserSubs(userId : number): Observable<UserSubs[]> {
    let _ws = this.configService.webServices['getUserSubs'];

    return this.http.get(_ws.url(this.baseUrl, userId), {headers: this.getHttpHeaders()}).pipe(
      map((res : Response) => {
        return res.json();
      }), catchError(this.handleError)
    );
  }

  deleteSubs(userId : number, customerChildId: number): Observable<any> {
    let _ws = this.configService.webServices['deleteSubs'];

    return this.http.delete(_ws.url(this.baseUrl, userId, customerChildId), {headers: this.getHttpHeaders()}).pipe(
      map((res : Response) => {
        return res;
      }), catchError(this.handleError)
    )
  }

  saveSubs(customerChild : number, user: number): Observable<any> {
    let _ws = this.configService.webServices['saveSubs'];

    let string = {      
      "permissions": 4,
      "user": user,
      "customer": customerChild
    }

    return this.http.post(_ws.url(this.baseUrl), string, {headers: this.getHttpHeaders()}).pipe(
      map((res : Response) => {
        return res;
      }), catchError(this.handleError)
    )
  }

  getAccessControlByUserId(userId : number): Observable<AccessControl[]> {
    let _ws = this.configService.webServices['getAccessControlByUserId'];

    return this.http.get(_ws.url(this.baseUrl, userId), {headers: this.getHttpHeaders()}).pipe(
      map((res : Response) => {
        return res.json();
      }), catchError(this.handleError)
    );
  }

  getIdsUsersByCustomerCreare(): Observable<number[]> {
    let _ws = this.configService.webServices['getIdsUsersByCustomerCreare'];

    return this.http.get(_ws.url(this.baseUrl), {headers: this.getHttpHeaders()}).pipe(
      map((res : Response) => {
        return res.json();
      }), catchError(this.handleError)
    );
  }

  saveAccessControl(userId: number, permissionId: number): Observable<any> {
    let _ws = this.configService.webServices['crudAccessControl'];

    return this.http.post(_ws.url(this.baseUrl, userId, permissionId), null, {headers: this.getHttpHeaders()}).pipe(
      map((res : Response) => {
        return res;
      }), catchError(this.handleError)
    )
  }

  saveUserAccessControl(userId: number, permissionsIds: number[]) {
    const _ws = this.configService.webServices['updateUserPermissions'];
    const url = _ws.url(this.baseUrl, userId);
    const body = permissionsIds;
    const headers ={ headers: this.getHttpHeaders() };

    return this.http
      .post(url, body, headers)
      .pipe(
        map(res => res),
        catchError(this.handleError)
      );
  }

  deleteAccessControl(userId: number, permissionId: number): Observable<any> {
    let _ws = this.configService.webServices['crudAccessControl'];

    return this.http.delete(_ws.url(this.baseUrl, userId, permissionId), {headers: this.getHttpHeaders()}).pipe(
      map((res : Response) => {
        return res;
      }), catchError(this.handleError)
    )
  }

  currentUser(): User {
    let user: User = JSON.parse(window.localStorage.getItem('currentUser'));     
    return user;
  }

  getUsers(): Observable<any> {
    let result: Observable<any>
        , user: User = this.currentUser();
    if (user && user.name) {
      let _nome:string;

      if (user.name.indexOf(' ') > -1) {
        _nome = user.name.substring(0, user.name.indexOf(' '));
      } else {
        _nome = user.name;
      }
      let _picture;
      if (user.picture) {
        _picture = user.picture;
      } else {
        _picture = `${environment.defaultUserImg}`;
      }
      result = observableOf({
        creare: { name: _nome, picture: _picture }
      });
    } else {
      result = observableOf(this.users);
    }

    return result;
  }

  getUserArray(): Observable<any[]> {
    return observableOf(this.userArray);
  }

  getUser(): Observable<any> {
    counter = (counter + 1) % this.userArray.length;
    return observableOf(this.userArray[counter]);
  }

  getUsersByCustomerProfileId(customerProfileId : number): Observable<User[]> {
    let _ws = this.configService.webServices['getCustomerProfileId'];

    return this.http.get(_ws.url(this.baseUrl, customerProfileId), {headers: this.getHttpHeaders()}).pipe(
      map((res : Response) => {
        return res.json();
      }),catchError(this.handleError),);     
  }

  changePassword(jsonChangePass : string): Observable<any> {
    let _ws = this.configService.webServices['changePassword'];

    return this.http.put(_ws.url(this.baseUrl), jsonChangePass, {headers: this.getHttpHeaders()}).pipe(
      map((res : Response) => {
        return res.text();
      }),catchError(this.handleError))
  }

  resetPasswordByLogin( login: string ): Promise<string> {
    const URL_TO_FETCH = this.configService.webServices['resetPasswordByLogin'].url(this.baseUrl, login);
    const HEADERS = this.auth.getHttpHeaders();

    return this.http.post(URL_TO_FETCH, null)
      .toPromise()
      .then(res => res.text())
      .catch(err => {
          return Promise.reject((err.message) ? err.json() : err.text()  || 'Server error');
      })
  }

  getUserVideoDataById( id: number ): Observable<any>{

    let _ws = this.configService.webServices['getUserVideoDataById'];

    return this.http.get(_ws.url(this.baseUrl, id), {headers: this.getHttpHeaders()}).pipe(
      map((res : Response) => {
        return res.json();
      }),catchError(this.handleError),);     

  }

  batchCreateUserSubs(userId: number, customersIds: number[]): Observable<any> {
    let _ws = this.configService.webServices['batchSaveSubs'];

    const url = _ws.url(this.baseUrl);
    const body = {
      userId,
      customersIds,
    };
    const options = {
      headers: this.getHttpHeaders()
    };

    return this
      .http
      .post(url, body, options)
      .pipe(
        map((res : Response) => {
          return res;
        }), catchError(this.handleError)
      )
  }

  batchDeleteUserSubs(userId: number, customersIds: number[]): Observable<any> {
    let _ws = this.configService.webServices['batchDeleteSubs'];

    const url = _ws.url(this.baseUrl);
    const body = {
      userId,
      customersIds,
    };
    const options = {
      headers: this.getHttpHeaders()
    };

    return this
      .http
      .post(url, body, options)
      .pipe(
        map((res : Response) => {
          return res;
        }), catchError(this.handleError)
      )
  }
}