import {AuditRelation} from '.';
import {Status} from '../enum/status.enum';

export class AlarmType {     
     public id?: number;
     public name?: string;
     public identification?: string;     
     public integration?: string;
     public picture?: string;
     public type?: string;
     public status?: Status;

     public label: string;
     public value: number;
     public icon: string;
     public switch?: boolean = true;
}
