import { AuditRelation } from '.';

export class Alarm {
     public equipmentTypeId?: number;
     public id: number;
     public assetId?: number;
     public driverId?: number;
     public datetime?: Date;
     public version?: number;
     public speed?: number;
     public imei?: string;
     public type?: string;
     public picture?: string;
     public movie?: string;
     public level?: number;
     public latitude?: number;
     public longitude?: number;
     public audit?: AuditRelation;
     public typeAlarm?: number;
     public typeAlarmTranslate?: string;
     public originalTypeAlarm?:number;
     public isReclassified?:boolean;
     public originalGroupRisk?:number;
     public typeAlarmDescription?: string;
     public assetIdentification?: string;
     public assetPrefix?: string;
     public alarmRisk?: number;
     public alarmName?: string;
     public iconPath?:string;
     public confirmation?:number;
     
     public auditAlarm?: number;
     public auditDriver?: number;
     public auditAutor?: string;
     public auditComment?: string;
     public auditCreate_date?: Date;
     public auditTreatmentName?: string;

     public alarmEmailsSentDate?: string;
     public alarmEmailsSentHour?: string;
     public alarmEmailsSentRecipients?: string;

     public vehicleId?: number;
     public vehicle_plates?: string;
     public vehicle_prefix?: string;
     public customer_child_name?: string;
     public customer_profile_name?: string;
     public driver_firstname?: string;
     public driver_lastname?: string;
     
     public alarmRead?: boolean;
     public alarmReadByUserId?: number;
     public alarmReadByUsername?: string;
     public alarmReadAt?: string;
     
     public timeRead?: String;
     public delayDate?: String;
     public timeToTreat?: String;
     public validation?: boolean;

     public groupRisk?: number;
     public groupAlarm?: number;
     public groupAlarmsChild?: Alarm[] = [];

     public customer_profile_id?: number;
     public customer_child_id?: number;
     public selected?: boolean = false;
     public hasFaceRecog?: boolean;
     public insertDate?: Date;
     public availableMediaAt?: Date;
     public treatmentStartedAt?: Date | string;

     public updatedDriver?: boolean = false;
     public updatedAlarmType?: boolean = false;
     public driverHasFaceRecog?: boolean = false;

     public signature?: string
     public signatureDriverName?: string
     public signatureId?: number
     public signatureDate?: string

     public alarmToFiltering?: number;

     public auditWorkflowDtoList?: { 
          auditId: number,
          auditLabel: string,
          createAt: Date,
          statusId: number,
          statusLabel: string,
          userId: number,
          userName: string,
          obs: string
     }[];

     public isEditable?: boolean;

     public revisado?: boolean;

     public urlPictures?: string[];
     public urlMovies?: string[];

     public auditHistories?: { 
          alarmTypeId: number,
          alarmTypeName: string,
          auditId: number,
          teatmentDate: Date,
          treatmentId: number,
          treatmentName: string,
          treatmentObservation: string,
          userTreatmentId: number,
          userTreatmentName: string,
          driverName: string
     } [];

     public isSelected?: boolean = false;
     public assetMovimentStatus?: { 
          initialDate: Date, 
          status: string, 
          duration: string,
          inMoviment: boolean 
     };

     public recalcRiskByRecurrence?: boolean = false;
     public previousRecurrentAlarmIDs?: number[];
     public firstAuditCreateDate?: Date;
     public updateFirstTreatmentDate?: boolean;

     public driverRecognitionAttempt?: string;
     public readByCustomer?: boolean;
}
