import { Injectable } from "@angular/core";
import { AuthenticationService } from "app/pages/login/authentication.service";
import { ConfigService } from "../config/config.service";
import { Http, Headers } from "@angular/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ReportLinkResponseEntity } from "../models/report-link-response.entity";
import { ReportLinkGeneralEntity } from "../models/report-link-general.entity";

@Injectable()
export class BiService {

  private baseUrl: string;

  constructor(
    private http: Http,
    private configService: ConfigService,
    private auth: AuthenticationService
  ){
    this.baseUrl = this.configService.getApiURI();
  }

  public getLinks() : Observable<ReportLinkResponseEntity> {
    let _ws = this.configService.webServicesV2['getReportLinks'];

    return this.http.get(_ws.url(this.baseUrl), { headers: this.getHttpHeaders() }).pipe(
        map((res: Response) => {
            return res.json();
        })
    );
  }

  public getEmbedReportPowerBi(selectedReportGeneral) : Observable<any> {
    const _ws = this.configService.webServicesV2['getEmbedInfo'];
    const headers = this.getHttpHeaders();
    const { reportId, workspaceId, name, hasMonitoringCenter } = selectedReportGeneral;

    const body = {
      reportId,
      workspaceId,
      name,
      hasMonitoringCenter
    };

    return this.http.post(_ws.url(this.baseUrl), body, {headers}).pipe(
        map((res: Response) => {
            return res.json();
        })
    );
  }

  public getEmbed(): Observable<any> {
    let _ws = this.configService.webServicesV2['getEmbedInfo'];

    return this.http.get(_ws.url(this.baseUrl), { headers: this.getHttpHeaders() }).pipe(
        map((res: Response) => {
          return res.json();
        })
    );
  }

  public getReportsGeneral(): Observable<any> {
    const _ws = this.configService.webServicesV2['getReportsGeneral'];
    const headers = this.getHttpHeaders();
    
    return this.http.get(_ws.url(this.baseUrl), {headers}).pipe(
        map((res: Response) => {
            return res.json();
        })
    );
  }

  private getHttpHeaders() : Headers {
    let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', this.auth.getToken());

    return headers;
  }

}
