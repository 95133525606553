import { InfractionData } from "./InfractionData.entity";

export class Infraction {

    public id: number;
    public deviceId?: string;
    public assetId?: number;
    public driverId?: number
    public deviceDatetime?: Date;
    public deviceDatetimeEnd?: Date;
    public receptionDatetime?: Date;
    public consumptionDatetime?: Date;
    public infractionTypeId?: number;
    public infractionType?: string;
    public latitude?: string;
    public longitude?: string;
    public heading?: number;
    public location?: string;
    public latitudeEnd?: string;
    public longitudeEnd?: string;
    public infractionData?: InfractionData;
    
}