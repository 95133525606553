import { Component, Input } from '@angular/core';

@Component({
    selector: 'spinnerLoader',
    template: `
    <div class="boxLoader">
        <div class="spinnerLoader">
            <svg
                style="cursor: pointer"
                class="icon"
                [style.width.px]="width"
                [style.height.px]="height"
                viewBox="0 0 24 24"
                stroke-width="3"
                stroke-linecap="round"
            >
                <circle
                    cx="12" cy="12" r="5"
                    fill="none"
                    stroke="rgba(48,62,77,0.2)"
                ></circle>
                <circle
                    cx="12" cy="12" r="5"
                    fill="none"
                    stroke="rgba(127, 134, 143, 1)"
                    stroke-dasharray="12 32"
                ></circle>
            </svg>
        </div>
    </div>
    `,
    styles: [`
    .icon {
        animation: spin 0.8s linear infinite
    }
    
    @keyframes spin {
        0% { transform: rotate(0deg) }
        100% { transform: rotate(359.99deg) }
    }
    .boxLoader {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    `]
})
export class SpinnerLoaderComponent {
    @Input('width') width: number = 50;
    @Input('height') height: number = 50;
}