
import {throwError as observableThrowError, forkJoin as observableForkJoin, of as observableOf,  Observable, Observer } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import { Http } from '@angular/http';
import * as moment from 'moment';

import 'rxjs/Rx';

// configuracao
import { ConfigService } from '../config/config.service';
import { ItemsUtilService } from '../utils/items.util.service';

// mock
import { MockService } from './mock.service';
import { DriverService } from './driver.service';
import { AssetService } from './asset.service';

import { Alarm, AlarmDto, Asset, RoadDefendData, Driver, User, AlarmType, Customer, WorkspaceProfileData, FilterAlarms, Equipament, Device}  from '../models';
import { EquipamentType } from '../enum/equipament.type.enum';
import { Status } from '../enum/status.enum';
import { Workspace, AccessControlDesc, customerChildsPermissions } from '../models/workspace.data.entity';
import { CustomerChild } from '../models/customerChild.entity';
import { TranslateJsonService } from './translate-json.service';
import { RiskRating } from '../models/risk-rating.entity';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';
import { InfractionDto } from '../models/infraction.dto';
import { Infraction } from '../models/infraction.entity';

@Injectable()
export class DataService {
  public userName = environment.userNameCreare;

  private roadDefendData : RoadDefendData = new RoadDefendData();
  private _baseUrl: string = '';

  public notFoundImage = environment.notFoundImg;


  
  public alarmTypesNumber = [
    {label: this.translateJsonService.getAlarmTypesTranslate(2), value: 2, icon: 'assets/images/sleep.png'},
    {label: this.translateJsonService.getAlarmTypesTranslate(6), value: 6, icon: 'assets/images/sleep.png'},
    {label: this.translateJsonService.getAlarmTypesTranslate(7), value: 7, icon: 'assets/images/yawning.png'},
    {label: this.translateJsonService.getAlarmTypesTranslate(1), value: 1, icon: 'assets/images/ause.png'},
    {label: this.translateJsonService.getAlarmTypesTranslate(9), value: 9, icon: 'assets/images/cigarette.png'},
    {label: this.translateJsonService.getAlarmTypesTranslate(4), value: 4, icon: 'assets/images/cell.png'},
    {label: this.translateJsonService.getAlarmTypesTranslate(8), value: 8, icon: 'assets/images/camCovered.png'},
    {label: this.translateJsonService.getAlarmTypesTranslate(3), value: 3, icon: 'assets/images/faceSide.png'},
    {label: this.translateJsonService.getAlarmTypesTranslate(5), value: 5, icon: 'assets/images/exchangeDriver.png'},
    {label: this.translateJsonService.getAlarmTypesTranslate(10), value: 10, icon: 'assets/images/impact.png'},
    {label: this.translateJsonService.getAlarmTypesTranslate(11), value: 11, icon: 'assets/images/pedestrian.png'},
    {label: this.translateJsonService.getAlarmTypesTranslate(12), value: 12, icon: 'assets/images/tail_gating.png'},
    {label: this.translateJsonService.getAlarmTypesTranslate(13), value: 13, icon: 'assets/images/swerving.png'},
    {label: this.translateJsonService.getAlarmTypesTranslate(14), value: 14, icon: 'assets/images/over_speed.png'},
    {label: this.translateJsonService.getAlarmTypesTranslate(15), value: 15, icon: 'assets/images/no_seatbelt.png'},
    {label: this.translateJsonService.getAlarmTypesTranslate(16), value: 16, icon: 'assets/images/fall.png'},
    {label: this.translateJsonService.getAlarmTypesTranslate(17), value: 17, icon: 'assets/images/low_spo2.png'},
    {label: this.translateJsonService.getAlarmTypesTranslate(22), value: 22, icon: 'assets/images/bradycardia.png'},
    {label: this.translateJsonService.getAlarmTypesTranslate(23), value: 23, icon: 'assets/images/tachycardia.png'},
    {label: this.translateJsonService.getAlarmTypesTranslate(24), value: 24, icon: 'assets/images/rider-detec.png'},
    { label: this.translateJsonService.getAlarmTypesTranslate(25), value: 25, icon: 'assets/images/drowsiness-confirmation.png'},
    { label: this.translateJsonService.getAlarmTypesTranslate(26), value: 26, icon: 'assets/images/drowsiness-confirmation.png' },

  ]

  public infractionTypesNumber =  [
    {label: this.translateJsonService.getAlarmTypesTranslate(3001), value: 3001, icon: ''},
    {label: this.translateJsonService.getAlarmTypesTranslate(3004), value: 3004, icon: ''},
    {label: this.translateJsonService.getAlarmTypesTranslate(3006), value: 3006, icon: ''},
    {label: this.translateJsonService.getAlarmTypesTranslate(3007), value: 3007, icon: ''},
  ]

  constructor(
    private configService: ConfigService,
      private http: Http,
      private itemsService: ItemsUtilService,
      private mockService: MockService,
      private driverService: DriverService,
      private assetService: AssetService,
      private translateJsonService: TranslateJsonService,
      private router: Router
    )
  {
      this._baseUrl = configService.getApiURI();
  }

  public registerRoadDefendData(data: RoadDefendData): RoadDefendData {
    this.roadDefendData = data;
    return this.roadDefendData;
  }

  public registerRiskRating(riskRatingList : RiskRating[]): RiskRating[] {
    let riskRatingListSalved = this.roadDefendData.riskRatingList;

    riskRatingList.forEach((rr) => {
      if(!riskRatingListSalved.includes(rr)){
        riskRatingListSalved.push(rr);
      }
    })

    return riskRatingListSalved;
  }

  public getRiskRating(): RiskRating[] {
    return this.roadDefendData.riskRatingList;
  }

 // *****************************************************************
 // alarmes
 public getAlarmsDiff(_alarms: Alarm[]): Alarm[] {
   let _alarmList: Alarm[] = [];
    _alarms.forEach((alarm) => {
        if (!this.roadDefendData.alarmList.find(_alarm_ => _alarm_ && _alarm_.id === alarm.id)) {
             _alarmList.push(alarm);
        }
      });
   return _alarmList;
 }

  diff(startDate, endDate) {
  let startDateMoment = moment.utc(startDate);
  let endDateMoment = moment.utc(endDate);
  let diffMiliseconds = moment(endDateMoment).diff(startDateMoment);
  let duration = moment.duration(diffMiliseconds);
  let hours = Math.floor(duration.asHours());
  let minutes = moment.utc(diffMiliseconds).format("mm");
  let seconds = moment.utc(diffMiliseconds).format("ss");
  return `${hours}h ${minutes}m ${seconds}s`;
}

  public registerAlarms(_alarmsAndInfractions: any) {
    if (_alarmsAndInfractions.alarmList) {
      _alarmsAndInfractions.alarmList.forEach((alarm) => {

        alarm.availableMediaAt = new Date(alarm.availableMediaAt + ".000-03:00")
  
        const dateNow = new Date();
        const dateAlarm = new Date(alarm.datetime);
        const availableMediaAt = new Date(alarm.availableMediaAt ? alarm.availableMediaAt : alarm.insertDate);
        const dateTreat = new Date(alarm.auditCreate_date);
        const read = this.diff(availableMediaAt, dateNow);
        const delay = (this.diff(dateAlarm, availableMediaAt)).includes('-') ? "0h 00m 00s" : this.diff(dateAlarm, availableMediaAt);
        const timeToTreat = this.diff(availableMediaAt, dateTreat);
  
        alarm.delayDate = delay;
        alarm.timeRead = read;
        alarm.auditCreate_date ? alarm.timeToTreat = timeToTreat : alarm.timeToTreat = null;
  
          if (!this.roadDefendData.alarmList.find(_alarm_ => _alarm_ && _alarm_.id === alarm.id)) {
            this.roadDefendData.alarmList.push(alarm);
          }
      });

      // sort data
      _alarmsAndInfractions.alarmList.sort((e1,e2)=>{if (e1.datetime < e2.datetime) return 1; else if (e1.datetime > e2.datetime) return -1; else return 0});
  
      this.roadDefendData.filteredAlarmList = _alarmsAndInfractions.alarmList;
    } 

    if (_alarmsAndInfractions.infractionInformationList) {

      this.roadDefendData.infractionList = _alarmsAndInfractions.infractionInformationList;
      this.roadDefendData.filteredInfractionList = _alarmsAndInfractions.infractionInformationList;
    }

    return this.roadDefendData.alarmList;
  }

  public setReadFilteredAlarmList(alarmId: number) {
    this.roadDefendData.filteredAlarmList.map((alarm: Alarm)=>{
      if (alarm.id === alarmId) {
        alarm.alarmRead = true;
      }
    });
  }

  public setFilteredAlarmList(filteredAlarmList: Alarm[]) {
    return this.roadDefendData.filteredAlarmList = filteredAlarmList ;
  }

  public getFilteredAlarmList(): Alarm[] {
    return this.roadDefendData.filteredAlarmList;
  }

  public getFilteredAlarmDtoList(): AlarmDto[] {

    return this.roadDefendData.filteredAlarmList.map(
      (alarm: Alarm) => <AlarmDto>this.convertAlarmToAlarmDto(alarm)
    );
  }

  public getFilteredInfractionsDtoList():InfractionDto[] {
    return this.roadDefendData.filteredInfractionList.map(
      (infraction: Infraction) => <InfractionDto>this.convertInfractionDto(infraction)
    )
  }

  public getFilteredAlarmDtoListByDriverAndType(driverId: number, typeAlarm: number): AlarmDto[] {
    let _listFiltered: AlarmDto[] = this.getFilteredAlarmDtoList();
    return _listFiltered.filter((alarm: AlarmDto)=>{
        return alarm.driverId == driverId && alarm.typeAlarm == typeAlarm;
    });
  }

  public getFilteredAlarmDtoListByDriver(driverId: number): AlarmDto[] {
    let _listFiltered: AlarmDto[] = this.getFilteredAlarmDtoList();
    return _listFiltered.filter((alarm: AlarmDto)=>{
      return alarm.driverId == driverId;
  });
  }

  public getFilteredAlarmDtoListByType(type:number): AlarmDto[] {
    let _listFiltered: AlarmDto[] = this.getFilteredAlarmDtoList();
    return _listFiltered.filter((alarm: AlarmDto)=>{
        return alarm.typeAlarm == type;
    });
  }

  public getFilteredAlarmDtoCategoryByDriverType(type:number): AlarmDto[] {
      let _result : AlarmDto[] = new Array<AlarmDto>()
      ,  _alarms : AlarmDto[] = this.getFilteredAlarmDtoListByType(type);

      _alarms.forEach((_alarm: AlarmDto)=>{
        let _alarmByType: AlarmDto = _result.find((alarm: AlarmDto)=> {return _alarm.driverId === alarm.driverId && _alarm.typeAlarm === alarm.typeAlarm});

        if (_alarmByType) {
          // sum quantity
          _alarmByType.totalCategory = _alarmByType.totalCategory + 1;

        } else {
          // push array, count by row
          _alarmByType = Object.assign(<AlarmDto>{}, _alarm);
          _alarmByType.totalCategory = 1;
          _alarmByType.totalCount = _alarms.length;
          _alarmByType.assetIdentification = _alarm.vehicle_plates;
          _alarmByType.driverName = (_alarmByType.driver_firstname)?_alarmByType.driver_firstname:''
          + ' ' + (_alarmByType.driver_lastname)?_alarmByType.driver_lastname:'';
          _result.push(_alarmByType);
        }

      });

       return _result;
  }


  public getFilteredAlarmDtoCategoryByDriverAndType(): AlarmDto[] {
    let _category : AlarmDto[] = new Array<AlarmDto>();

    this.roadDefendData.filteredAlarmList.forEach((_alarm: AlarmDto)=>{
        let _alarmCategory: AlarmDto = _category.find((alarm: AlarmDto)=> {
          return _alarm.driverId === alarm.driverId && _alarm.typeAlarm === alarm.typeAlarm});

        if (_alarmCategory) {
          // sum quantity
          _alarmCategory.totalCount = _alarmCategory.totalCount + 1;
        } else {
          // push array, count by row
          _alarmCategory = Object.assign(_alarm);
          _alarmCategory.totalCount = 1;
          _alarmCategory.assetIdentification = _alarm.vehicle_plates;
          _alarmCategory.driverName = (_alarmCategory.driver_firstname)?_alarmCategory.driver_firstname:''
          + ' ' + (_alarmCategory.driver_lastname)?_alarmCategory.driver_lastname:'';
          _category.push(_alarmCategory);
        }

    });

    return _category;
  }


public getAlarmList(): Alarm[] {
    return this.roadDefendData.alarmList;
  }

  public clearAlarms() {
    this.roadDefendData.alarmList = [];
  }

  public registerAlarmTypes(alarmTypes: AlarmType[]): AlarmType[] {
    this.roadDefendData.alarmTypeList = alarmTypes;
    return this.roadDefendData.alarmTypeList;
  }
  // *****************************************************************
  // repository
  // register all relational alarm data
  private repoDriverById = true;
 public registerAllAlarmsData(_alarms: Alarm[]) {
    let alarmList: Alarm[] = [];
    let _drivers: number[] = [];
    let _assets: number[] = [];
   _alarms.forEach((alarm) => {
        // check alarm
        if (!this.roadDefendData.alarmList.find(_alarm_ => _alarm_ && _alarm_.id === alarm.id)) {
           // request async alarm data in repository
            this.loadRepositoryAlarmDataByAlarmId(alarm.id);
        }

        // check driver
        if (alarm.driverId && !this.roadDefendData.driverList.find(_driver_ => _driver_ && _driver_.id === alarm.driverId)) {
            // request async driver data in repository
            if (!_drivers.find(_driverId => _driverId && _driverId === alarm.driverId)) {
               _drivers.push(alarm.driverId);
               this.loadRepositoryDriverDataByDriverId(alarm.driverId);
            }
        }

        // check asset
        if (alarm.assetId && !this.roadDefendData.assetList.find(_asset_ => _asset_ && _asset_.id === alarm.assetId)) {
            // request async asset data in repository
            if (!_assets.find(_assetId => _assetId && _assetId === alarm.assetId)) {
               _assets.push(alarm.assetId);
               this.loadRepositoryAssetDataByAssetId(alarm.assetId);
            }
        }


      });
 }

 public loadRepositoryDriverDataByDriverId(_driverId: number): Observable<RoadDefendData> {

    let _ws = this.configService.webServices;


    if (_ws['repositoryDriverDataByDriverId'].mock) {
       let _driver: Driver = this.mockService.getDriverByDriverId(_driverId);
      if (_driver) {
          this.roadDefendData.driverList.push(_driver);
      }
      return observableOf(this.roadDefendData);
    } else{

      this.driverService.selectDriverById(_driverId).subscribe(
        (_driver) => {
           //let  _driver: Driver = <Driver>(this.itemsService.getSerialized<Driver>(data[0]));
           if (_driver && !this.roadDefendData.driverList.find(driver => driver && driver.id === _driver.id)) {
              this.roadDefendData.driverList.push(_driver);
              console.info('this.roadDefendData.driverList', this.roadDefendData.driverList);
           }
        });
    }

  }

public loadRepositoryAssetDataByAssetId(_assetId: number): Observable<RoadDefendData> {

    let _ws = this.configService.webServices;

    if (_ws['repositoryAssetDataByAssetId'].mock) {
       let _asset: Asset = this.mockService.getAssetByAssetId(_assetId);
      if (_asset) {
          this.roadDefendData.assetList.push(_asset);
      }
      return observableOf(this.roadDefendData);
    } else{

      /* this.assetService.getAssetById(_assetId).subscribe(
        (_asset) => {
           if (_asset && !this.roadDefendData.assetList.find(asset => asset && asset.id === _asset.id)) {
              this.roadDefendData.assetList.push(_asset);
           }
        }); */
    }

  }

  public loadRepositoryAlarmDataByAlarmId(_alarmId: number): Observable<RoadDefendData> {
    let _ws = this.configService.webServices;
    if (_ws['repositoryAlarmDataByAlarmId'].mock) {
       let _alarm: Alarm = this.mockService.getAlarmByAlarmId(_alarmId);
      if (_alarm) {
          this.roadDefendData.alarmList.push(_alarm);
      }
      return observableOf(this.roadDefendData);
    } else
    return observableForkJoin([
      this.http.get(_ws['alarmById'].url(this._baseUrl, _alarmId)).pipe(map(res => res.json()))
    ]).pipe(
    map((data: any[]) => {
       let  _alarm: Alarm = <Alarm>(this.itemsService.getSerialized<Alarm>(data[0]));
       if (_alarm) this.roadDefendData.alarmList.push(_alarm);
      return this.roadDefendData;
    }),
    catchError(this.handleError),);
  }


  public loadRepositoryDataByFilter(_filter: FilterAlarms): Observable<RoadDefendData> {

    let _ws = this.configService.webServices;
    if (_ws['repositoryDataByFilter'].mock) {
        let _alarms: Alarm[] = this.mockService.getAlarmsByFilter(_filter);
        let  _customers: Customer[] = this.mockService.getCustomersByCustIds(_filter.selectedCustomers);
        let  _drivers: Driver[] = this.mockService.getDriversByCustIds(_filter.selectedCustomers);
        let  _assets: Asset[] = this.mockService.getAssetsByCustIds(_filter.selectedCustomers);
        this.roadDefendData.alarmList = _alarms;
        this.roadDefendData.customerList = _customers;
        this.roadDefendData.driverList = _drivers;
        this.roadDefendData.assetList = _assets;

        return observableOf(this.roadDefendData);
    } else
    return observableForkJoin([
      this.http.get(_ws['alarmsByFilter'].url(this._baseUrl, _filter)).pipe(map(res => res.json())),
      this.http.get(_ws['customersByCustomerIds'].url(this._baseUrl, _filter.selectedCustomers)).pipe(map(res => res.json())),
      this.http.get(_ws['driversByCustomerIds'].url(this._baseUrl, _filter.selectedCustomers)).pipe(map(res => res.json())),
      this.http.get(_ws['assetsByCustomerIds'].url(this._baseUrl, _filter.selectedCustomers)).pipe(map(res => res.json())),
    ]).pipe(
    map((data: any[]) => {
      this.roadDefendData.alarmList = <Alarm[]>(this.itemsService.getSerialized<Alarm[]>(data[0]));
      this.roadDefendData.customerList = <Customer[]>(this.itemsService.getSerialized<Customer[]>(data[1]));
      this.roadDefendData.driverList = <Driver[]>(this.itemsService.getSerialized<Driver[]>(data[2]));
      this.roadDefendData.assetList = <Asset[]>(this.itemsService.getSerialized<Asset[]>(data[3]));

      return this.roadDefendData;
    }),
    catchError(this.handleError),);
  }

  private handleError(error: any) {
    let errMsg = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    return observableThrowError(errMsg);
  }


  // *****************************************************************
  // Workspace

  public registerWorkspace(_workspace: Workspace): Workspace {
    _workspace.currentUser = this.currentUser();

    if(_workspace.isDealer) {
      const DEALER_OBJ = { id: 0, permissions: 4, permissionDesc: 'ROLE_DEALER_TOTAL' };

      if(_workspace.accessControlDesc) {
        _workspace.accessControlDesc.push(DEALER_OBJ);
      }else{
        _workspace.accessControlDesc = [DEALER_OBJ];
      }
    }

    window.localStorage.setItem('userWorkspace', JSON.stringify(_workspace));
    this.roadDefendData.workspace = _workspace;

    return this.roadDefendData.workspace;
  }

  public getWorkspace(): Workspace {
     let wk: Workspace = this.roadDefendData.workspace,
     user: User = this.currentUser();

     if (
      !user ||
      (user && wk && user.id !== wk.currentUserId) ||
      (wk && wk.userCustomerChildIds && wk.userCustomerChildIds.length < 1)
    ) {
      return null;
    } else {

      return wk;
    }
  }

  havePermission(fragment: string, rootFragment?: string){
    let wk: Workspace = this.getWorkspace();
    let findedRootPermission = wk.accessControlDesc.find((ac : AccessControlDesc) => this.getPermissionRole(ac.permissionDesc) == rootFragment);        
    let findedPermission = wk.accessControlDesc.find((ac : AccessControlDesc) => this.getPermissionRole(ac.permissionDesc) == fragment);

    if((rootFragment && !findedRootPermission) || !findedPermission) this.router.navigate(['f/fatigue']);
}

  hasPermission(fragment: string){
    let wk: Workspace = this.getWorkspace();
    let permissionFound = wk.accessControlDesc.find((ac : AccessControlDesc) => this.getPermissionRole(ac.permissionDesc) == fragment);
    
    return permissionFound != null
  }

public currentUser(): User {
    let user: User = JSON.parse(window.localStorage.getItem('currentUser'));
    return user;
  }


  // *****************************************************************
  // AlarmType
  public getAlarmTypeById(alarmTypeId: number) {
    return this.roadDefendData.alarmTypeList.find(alamType => alamType && alamType.id === alarmTypeId);
  }

  public getAlarmTypeByType(alarmType: string) {
    return this.roadDefendData.alarmTypeList.find(alamType => alamType && alamType.identification === alarmType);
  }

  // *****************************************************************
  // customers
  public registerCustomers(customers: Customer[]): Customer[] {
    let result: Customer[] = [];
    try{
          if (customers)
              customers.forEach((customer) => {
                let _customer:Customer;
                  if (!this.roadDefendData.customerList) this.roadDefendData.customerList = [];
                  _customer = this.roadDefendData.customerList.find(mcustomer => mcustomer && mcustomer.id === customer.id);
                  if (!_customer) {
                    this.roadDefendData.customerList.push(customer);
                  }

              });
        result = this.roadDefendData.customerList;
    }catch(e) {
        console.log('registerCustomers Error:' + e);
    }

    return result;
  }

  public getCustomerById(customerId: number): Customer {
    let result : Customer;
    try{
      if (this.roadDefendData.customerList && this.roadDefendData.customerList.length > 0) {
        result = this.roadDefendData.customerList.find(customer => customer && customer.id === customerId)
      }
    } catch(e) {console.error('Error (getCustomerById):', e)}

    return result;
  }

  public getAllCustomers(): Customer[] {
    return this.roadDefendData.customerList;
  }

  public getCustomerByDriverId(driverId: number): Customer {
    let driver: Driver = this.getDriverById(driverId)
    , result: Customer;

    if (driver && this.roadDefendData.customerList.length > 0) {
      result = this.roadDefendData.customerList.find((customer : Customer) => customer.customerChildId === driver.customerChildId);
    }

    return result;
  }


  // *****************************************************************
  // drivers
  public registerDrivers(drivers: Driver[]): Driver[] {
    drivers.forEach((driver) => {
      let _driver:Driver = this.roadDefendData.driverList.find(_driver_ => _driver_ && _driver_.id === driver.id);
      if (!_driver) {
        this.roadDefendData.driverList.push(driver);
      }
    });

    return this.roadDefendData.driverList;
  }

  public getDriverById(driverId: number): Driver {
     let driver : Driver;
     driver = this.roadDefendData.driverList.find(driver => driver && driver.id === driverId);
     return driver;
  }


  public getAllDrivers(): Driver[] {
    return this.roadDefendData.driverList;
  }

  // *****************************************************************
  // Veiculos
  public registerVehicles(vehicles: Asset[]): Asset[] {
    vehicles.forEach((vehicle) => {
      let _vehicle: Asset = this.roadDefendData.vehicleList.find(_vehicle_ => _vehicle_ && _vehicle_.id === vehicle.id);

      if (!_vehicle) {
        this.roadDefendData.vehicleList.push(vehicle);
      }
    });

    return this.roadDefendData.vehicleList;
  }

  public getAllVehicles(): Asset[] {
    return this.roadDefendData.vehicleList;
  }

  // *****************************************************************
  // Equipamentos
  public registerEquipments(equipments: Equipament[]): Equipament[] {
    equipments.forEach((equipment) => {
      let _equipment: Equipament = this.roadDefendData.equipamentList.find(_equipment_ => _equipment_ && _equipment_.id === equipment.id);
      if(!_equipment) {
        equipment.type = <EquipamentType> equipment.type;
        equipment.status = <Status> equipment.status;
        this.roadDefendData.equipamentList.push(equipment);
      }
    });

    return this.roadDefendData.equipamentList;
  }

  public clearEquipments() {
    this.roadDefendData.equipamentList = new Array<Equipament>();
  }

  public clearDevices() {
    this.roadDefendData.deviceList = new Array<Device>();
  }

  public clearVehicles() {
    this.roadDefendData.vehicleList = [];
  }

  // Listar Equipamento por ID
  public getEquipmentById(equipId: number): Equipament {
    let equipment: Equipament;
    equipment = this.roadDefendData.equipamentList.find((equipment) => equipment.id == equipId);
    return equipment;
  }

  // Listar Todos Equipamentos
  public getAllEquipments(): Equipament[] {
    return this.roadDefendData.equipamentList;
  }

  // *****************************************************************
  // Dispositivos
  public registerDevices(devices: Device[]): Device[] {
    devices.forEach((device) => {
      let _device: Device = this.roadDefendData.deviceList.find(_device_ => _device_ && _device_.id === device.id);
      if(!_device) {
        this.roadDefendData.deviceList.push(device);
      }
    });

    return this.roadDefendData.deviceList;
  }

  public getAllDevices(): Device[] {
    return this.roadDefendData.deviceList;
  }

  // *****************************************************************
  // Ativos
  public getAssetsByIds(assetsIds: number[]){

  }

  // *****************************************************************

  public convertAlarmToAlarmDto(alarm :Alarm) : AlarmDto {
    let _alarmDto: AlarmDto = new AlarmDto();

    _alarmDto = <AlarmDto>Object.assign({}, alarm);

    if (!_alarmDto.driverName) {
          let driver: Driver = this.getDriverById(_alarmDto.driverId);
          if (driver) {
            _alarmDto.driverName = driver.name;
            _alarmDto.driverPicture = driver.picture;
          } else {
            // temporario
            if (_alarmDto.driver_firstname) _alarmDto.driverName = _alarmDto.driver_firstname
            if (_alarmDto.driver_lastname) {
              if (_alarmDto.driverName)
                _alarmDto.driverName += ' ' + _alarmDto.driver_lastname;
              else
                _alarmDto.driverName = _alarmDto.driver_lastname;
            }
          }
    }

    if (_alarmDto.driver_firstname) _alarmDto.driverName = _alarmDto.driver_firstname;
    if (_alarmDto.driver_lastname) _alarmDto.driverName += ' ' + _alarmDto.driver_lastname;


    if (!_alarmDto.assetIdentification) {
        let asset: Asset = this.getAssetById(_alarmDto.assetId);
        if (asset) {
          _alarmDto.assetIdentification = asset.identification;
        } else {
          // temporario
          if (_alarmDto.vehicle_plates) _alarmDto.assetIdentification = _alarmDto.vehicle_plates;
          if (_alarmDto.vehicle_prefix) _alarmDto.assetPrefix = _alarmDto.vehicle_prefix;
        }
    }

    if (_alarmDto.vehicle_plates) _alarmDto.assetIdentification = _alarmDto.vehicle_plates;
    if (_alarmDto.vehicle_prefix) _alarmDto.assetPrefix = _alarmDto.vehicle_prefix;

    if (alarm.alarmRead) {
      _alarmDto.leitura = '<img t src="assets/images/checked-symbol.png" style="margin-left: 18px;" />'
    } else {
      _alarmDto.leitura = '<img t src="assets/images/checked-symbol-not.png" style="margin-left: 18px;" />'
    }

    let typeicon = this.alarmTypesNumber.find((alarme) => alarme.value == _alarmDto.typeAlarm);
    if(typeicon) _alarmDto.alarmTypeIcon = typeicon.icon;

    return _alarmDto;
  }

  public convertInfractionDto(infraction :Infraction) : InfractionDto {
    let _infractionDto: InfractionDto = new InfractionDto();

    _infractionDto = <InfractionDto>Object.assign({}, infraction);

    return _infractionDto;
  }


  public getAllAlarmsDtoByDriver(): AlarmDto[] {
    let _alarms: Array<AlarmDto> = <Array<AlarmDto>>this.roadDefendData.alarmList;

   _alarms.map(
      (alarmDto: AlarmDto)=>{
        let driver: Driver = this.getDriverById(alarmDto.driverId);
        if (driver) {
          alarmDto.driverName = driver.name;
          alarmDto.driverPicture = driver.picture;
        }
        let asset: Asset = this.getAssetById(alarmDto.assetId);
        if (asset) {
          alarmDto.assetIdentification = asset.identification;
          alarmDto.assetPrefix = asset.name;
        }
      });
    return _alarms;
  }

  public getAlarmsByDriverId(driverId: number): Alarm[] {
    return this.roadDefendData.alarmList.filter(alarm => alarm.driverId === driverId)
           .sort((o1,o2)=>{if (o1.id > o2.id) return 1; else if (o1.id < o2.id) return -1; else return 0});
  }

  public getAssetById(assetId : number): Asset {
    return this.roadDefendData.assetList.find(asset => asset && asset.id === assetId);
  }

  public getRoadDefendData(): RoadDefendData {
    return this.roadDefendData;
  }

  public async setPictureAlarm(alarm: Alarm, picture: string, movie?: string) {
    let _alarm: Alarm = this.roadDefendData.filteredAlarmList.find((alarmSalved : Alarm) => alarmSalved.id == alarm.id);

    if(_alarm){
      _alarm.picture = picture;

      if(movie) _alarm.movie = movie;
    }
  }

  public readyRelatedCam(alarm: Alarm){
    let allAlarms = this.getFilteredAlarmDtoList();

    let allAlarmsFiltered = allAlarms.filter((selectedcam: AlarmDto)=>{
      return selectedcam.driverId == alarm.driverId && selectedcam.typeAlarm == alarm.typeAlarm
    });

    return allAlarmsFiltered;
  }

  /**
  * Registrar no Roadefend os Customers recuperados do REST
  */
  public registerCustomerChilds(customerChilds: CustomerChild[]) {
    customerChilds.forEach((customerChild : CustomerChild) => {
      let _customerChild: CustomerChild;
      _customerChild = this.roadDefendData.customerChildList.find(mcustomerChild => mcustomerChild.id == customerChild.id);

      if(!_customerChild) {
        this.roadDefendData.customerChildList.push(customerChild);
      }
    })
  }

  public clearCustomerChilds(){
    this.roadDefendData.customerChildList = [];
  }

  /**
   * Recupera os Customers já registrados do Roadefend
  */
  public getAllCustomerChilds(): CustomerChild[] {
    let activeCustomerChildren = this.roadDefendData.customerChildList.filter(customerChild => customerChild.status === 1)
    return activeCustomerChildren
  }

  public existsCustomersChild(): boolean {
    return (this.roadDefendData.customerChildList.length > 0)? true : false;
  }

  /**
   * Verifica se o Usuário gravado no Workspace possui, ou não, permissões de Usuário Creare
   */
  verifyIsCreareUser(): boolean {
    const wk: Workspace = this.getWorkspace();
    let findedPermission = wk.accessControlDesc.find((ac : AccessControlDesc) => this.getPermissionRole(ac.permissionDesc) == 'CREARE');

    return findedPermission || wk.isDealer ? true : false;
  }

  verifyPortalPageAccess(): boolean {
    const wk: Workspace = this.getWorkspace();
    let portalPageAccess = wk.accessControlDesc.find((ac: AccessControlDesc) => this.getPermissionRole(ac.permissionDesc) == 'PORTAL');
    return portalPageAccess ? true : false;
  }

  public getCustomersIdFromPermissions(): number[] {
    let customersUser:number[] = [];

    this.roadDefendData.workspace.userCustomerChildIds.forEach((customerChildsPermissions : customerChildsPermissions) => {
        customersUser.push(customerChildsPermissions.customer);
    })

    return customersUser;
  }

  /**
   * Por padrao, as permissoes tem a descricao no formato {ROLE}__{NOME}__{LEVEL}
   * retorna somente o nome, mesmo que tenha "_"
   * Exemplo: ROLE_CUSTOMER_CHILD_TOTAL retorna CUSTOMER_CHILD
   */
  getPermissionRole(permissionDesc: string) : string
  {
      let permission_split = permissionDesc.split("_"); 
      return permission_split.filter((txt: string, ind: number) => ind > 0 && ind < permission_split.length-1 ).join(); 
  }
}
