import { DeviceType } from "../enum/device.type.enum";

export class Device {
  	 public id: number;
     public name: string;
     public identification: string;     
     public integration: string;
     public typeId: DeviceType;
     public status: number;
     public equipment?: number;
     public integrationDevice?: number;
     public customerChild?: number;
     public customerChildName?: string;
     public customerProfileName?: string;
     public imsi?: string;
}