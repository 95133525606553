import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as moment from 'moment';
import { AlarmTypeEnum, AlarmTypeEnumEN, AlarmTypeEnumES } from "../enum/alarm-type.enum";

@Injectable()
export class TranslateJsonService {
    ptBr;
    en;
    es;

    languages = {
        'ptbr': AlarmTypeEnum,
        'en': AlarmTypeEnumEN,
        'es': AlarmTypeEnumES
    }

    constructor(
        protected translateService: TranslateService
    ){
        this.ptBr = {
            firstDayOfWeek: 0,
            dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
            dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
            dayNamesMin: ["Do","Sg","Te","Qa","Qi","Sx","Sa"],
            monthNames: [ "Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro","Novembro","Dezembro" ],
            monthNamesShort: [ "Jan", "Fev", "Mar", "Abr", "Mai", "Jun","Jul", "Ago", "Set", "Out", "Nov", "Dez" ],
            today: 'Hoje',
            clear: 'Limpar'
          };
  
          this.en = {
              firstDayOfWeek: 0,
              dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
              dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
              dayNamesMin: ["Su","Mo","Tu","We","Th","Fr","Sa"],
              monthNames: [ "January","February","March","April","May","June","July","August","September","October","November","December" ],
              monthNamesShort: [ "Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ],
              today: 'Today',
              clear: 'Clear'
          };
  
          this.es = {
              firstDayOfWeek: 0,
              dayNames: [ "Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
              dayNamesShort: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
              dayNamesMin: ["D", "L", "Ma", "Mi", "J", "V", "S" ],
              monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
              monthNamesShort: [ "En", "Feb", "Mar", "Abr", "May", "Jun","Jul", "Ago", "Sep", "Oct", "Nov", "Dic" ],
              today: 'Hot',
              clear: 'Claro'
          }
    }

    getTranslateSubs(key : string) {
        let translated;
        this.translateService.get(key).subscribe((res : string) => { translated = res; });
        return translated;
    }

    subGetTranslateSubs(key: string) {
        return this.translateService.get(key);
    }

    getTranslateSubsParam(key: string, param: string) {
        let translated;
        this.translateService.get(key, param).subscribe((res : string) => { translated = res; });
        return translated;
    }

    getTranslateSubsParams(key: string, params: Object) {
        let translated = "";
        
        this.translateService.get(key, params).subscribe(res => translated = res);

        return translated
    }

    getCurrentTranslate(): string {
        return this.translateService.currentLang;
    }

    transformDataFormat(data : Date): string {
        if(data != null) {
            return moment(data).format(this.getTranslateSubs('GLOBAL.datetime-format')).toString()
        }else{
            return null;
        }
    }

    getDataFormat(showYear?: boolean): string { 
        if(this.translateService.currentLang === 'en'){
            return "mm/dd";
        }else{
            if(showYear) {
                return "dd/mm/yy";
            }

            return "dd/mm";
        }        
    }

    getAlarmTypesTranslate(alarmTypeID: number): string {   
        let enumAlarmTypes;

        if(localStorage.getItem('lang')){
            enumAlarmTypes = this.languages[localStorage.getItem('lang')];        
        }else{
            const language = 'en';
            localStorage.setItem[language];
            enumAlarmTypes = this.languages[language];
        }
        
        return enumAlarmTypes[alarmTypeID];
    }

    getTranslateCalendar(){        
        const currentLang = this.getCurrentTranslate();

        if(currentLang === 'ptbr'){
            return this.ptBr;
        }

        if(currentLang === 'en'){
            return this.en;
        }

        if(currentLang === 'es'){
            return this.es;
        }
    }

    getLayerControlTranslate() {
        const currentLang = this.getCurrentTranslate();

        if(currentLang === 'ptbr') return 'Mostrar cercas';
        
        if(currentLang === 'en') return 'Show fences';
        
        if(currentLang === 'es')return 'Mostrar geozonas';
    }

    getBoardingPointsLayerControlTranslate() {
        const currentLang = this.getCurrentTranslate();

        if(currentLang === 'ptbr') return 'Pontos de Embarque';
        
        if(currentLang === 'en') return 'Boarding Points';
        
        if(currentLang === 'es')return 'Paraderos';
    }

    transformNumberToString(num: number, decimals: number): string {
        return num.toFixed(decimals);
    }
}
