
import {throwError as observableThrowError,  Observable, Subject } from 'rxjs';
import { Http, Response, Headers } from '@angular/http';
import {map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';

import {SelectItem} from 'primeng/primeng';

import {Driver, Asset} from '../models';
import { ConfigService } from '../config/config.service';
import { MockService } from './mock.service';
import { TreeInputModel } from '../models/treeInputModel.entity';
import { CustomerChild } from '../models/customerChild.entity';
import { AuthenticationService } from 'app/pages/login/authentication.service';

@Injectable()
export class DriverService {
  private baseUrl;

  constructor(
    private http: Http,
    private mockService: MockService,
    private configService: ConfigService,
    protected auth: AuthenticationService
  ) {
      this.baseUrl = configService.getApiURI();
  }

  public convertToSelectItem(asset : Asset[]) : SelectItem[] {
    let result : SelectItem[] = [];
    asset.forEach((asset_ : Asset) => {
        result.push({label: asset_.name, value: asset_.id});
    })
    return result;
  }

  public selectDriverById(_driverId: number):  Observable<Driver>  {
    let _ws = this.configService.webServices['driverById'];

    return this.http.get(_ws.url(this.baseUrl, _driverId), {headers: this.getHttpHeaders()}).pipe(
        map((res: Response) => {
            return res.json();
    }),
    catchError(this.handleError),);
  }

  public getAllDrivers():  Observable<Driver[]>  {
    return this.http.get(this.configService.wsServicesUrl('drivers'), {headers: this.getHttpHeaders()}).pipe(
    map((res: Response) => {
        return res.json();
    }),
    catchError(this.handleError),);
  }

  public getDriversByManyCustomerIds(customerIds: number[]):  Observable<Driver[]>  {
    let _ws = this.configService.webServices['assetByManyCustomerIdAndType'];

    if (_ws.mock) {
      return this.mockService.getDriversByCustomerIds(customerIds)
    } else return this.http.get(_ws.url(this.baseUrl, customerIds.join(','), 1000), {headers: this.getHttpHeaders()}).pipe(
    map((res: Response) => {
        return res.json();
    }),
    catchError(this.handleError),);
  }

  public getDriversByCustomerIds(customerIds: number[]):  Observable<Driver[]>  {
    let _ws = this.configService.webServices['driversByCustomerIdAndType'];

    if (_ws.mock) {
      return this.mockService.getDriversByCustomerIds(customerIds)
    } else return this.http.get(this.configService.wsServicesUrl('driversByCustomerIdAndType',  customerIds.join(',')), {headers: this.getHttpHeaders()}).pipe(
    map((res: Response) => {
        return res.json();
    }),
    catchError(this.handleError),);
  }

  public getDriversSelectItem(_drivers: Driver[]): SelectItem[] {
    let _result: SelectItem[] = [];
    _drivers.forEach((driver:Driver, i) => {
      if(i < 300){
        _result.push({label: driver.name, value: driver.id});
      }
    });
    return _result;
  }

  /**
   * Converte os Drivers para o formato de TreeInputModel
   * @param _drivers Lista de condutores no formato Drivers
   */
  public convertDriversToTreeInputModel(_drivers : Driver[], selectedCustomersChilds: number[], allCustomersChilds: CustomerChild[]): TreeInputModel[] {
    let result: TreeInputModel[] = [];

    _drivers.forEach((driver : Driver) => {
        result.push({id: driver.id, name: driver.name, parentId: null});
    })

    return result;
  }

  public convertAssetToTreeInputModel(_drivers : Asset[]): TreeInputModel[] {
    let result: TreeInputModel[] = [];

    _drivers.forEach((driver : Asset) => {
        result.push({id: driver.id, name: driver.name, parentId: null});
    })

    return result;
  }

  private handleError(error: any) {
    let errMsg = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error('Erro:', error);
    return observableThrowError(errMsg);
  }

  getHttpHeaders() : Headers {
    let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', this.auth.getToken());

    return headers;
  }


  uploadImageToDriver(driverId: number, picture: File, alertType: number, isIdentification: boolean): Observable<any> {
    let body: FormData = new FormData();
    body.append('uploadingFiles', picture);

    let _ws = this.configService.webServices['uploadImageToDriver'];

    let headers = new Headers();
    headers.append('Authorization', this.auth.getToken());

    console.log('cadastrando uma imagem no reconhecimento facial');

    return this.http.post(_ws.url(this.baseUrl, driverId, alertType, isIdentification), body, { headers: headers }).pipe(
        map((res: Response) => {
            return res.json();
    }),
    catchError(this.handleError),);
  }

}
