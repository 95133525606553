
import { Injectable } from '@angular/core';
import { Predicate } from '../interfaces/interfacePredicate'
import { EstiloPopup } from '../enum/estilo-popup.enum';
import alertify from 'alertifyjs';
import 'style-loader!alertifyjs/build/css/alertify.css';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class NotificationUtilService {
    router: Router;

    constructor(
        http: HttpClient, 
        router: Router,
        ) { 
        this.router = router;
    }

    teste() {
       alertify.alert('Cadastro', 'Cadastro realizado com sucesso', function(){ alertify.success('Ok'); });
    }

    
    popup(mensagem: string, estilo :EstiloPopup, delaySeconds?: number) {
        console.log('popuppp')
         switch (estilo) {
           case EstiloPopup.verde: { 
              //statements; 
              console.log('verde');
              //alertify.set('notifier','delay', delaySeconds);
              
              break; 
           }
           case EstiloPopup.vermelho: { 
            console.log('vermelho');
              //statements; 
              alertify.set('notifier','delay', delaySeconds);
              alertify.set('notifier','position', 'top-center');
              alertify.error(mensagem);  // verMELHO
              break; 
           }   

           case EstiloPopup.amarelo: { 
            console.log('amarelo');
              //statements; 
              alertify.set('notifier','delay', delaySeconds);              
              alertify.set('notifier','position', 'top-center');
              alertify.warning(mensagem);  // amarelo
              break; 
           }
           case EstiloPopup.azul: { 
              //statements; 
              alertify.set('notifier','delay', delaySeconds);
              //alertify.set('notifier','position', 'top-center');
              alertify.set('notifier','position', 'top-center');
              //alertify.warning(mensagem);  // amarelo
              alertify.notify('custom message.', 'customCss', 2, function(){console.log('dismissed');}); // custome
              break; 
           }

            default: { 
                //statements; 
                break; 
            } 
         }
         //alertify.set('notifier','delay', delaySeconds);
         //alertify.set('notifier','position', 'top-center');
         //alertify.set('notifier','position', 'top-right');
         //alertify.set('notifier','position', 'top-left');
         // alertify.set('notifier','position', 'bottom-right');
           //alertify.set('notifier','position', 'bottom-center');

           // alertify.set('notifier','position', 'bottom-left');
         
         /*
         alertify.error('Error notification message.');  //vermelho
          alertify.warning('Warning notification message.');  // amarelo

          // .ajs-message.ajs-custom { color: #31708f;  background-color: #d9edf7;  border-color: #31708f; }
          alertify.notify('custom message.', 'customCss', 2, function(){console.log('dismissed');}); // custome

         //alertify.message('Will stay open till clicked.', 0);
         alertify.message('Standard notification message.');

         */
         

    }

     


    /*
        Abre uma dialog de confirma\u00E7\u00E3o
    */
    openConfirmationDialog(titulo:string, message: string, okCallback: () => {}){

        alertify.confirm(titulo, message, 
                  function(){ okCallback(); alertify.success('Comando Processado') }
                , function(){ alertify.error('Cancelado')});

    }

    confirmationDialog(titulo: string, message: string){
        alertify.confirm(titulo, message, function(){ alertify.success('Comando Processado') }, function(){ alertify.error('Cancelado') });
    }

    /*
        Mensagem de sucesso
    */
     printSuccessMessage(message: string) {

        //this._notifier.success(message);
        if(!alertify.myAlert){
            //override defaults para bootstrap
            alertify.defaults.transition = "slide";
            alertify.defaults.theme.ok = "btn btn-creare";
            alertify.defaults.theme.cancel = "btn btn-danger";
            alertify.defaults.theme.input = "form-control";

          //define a new dialog
          alertify.dialog('myAlert',function factory(){
            return{
              main:function(message){
                this.message = message;
              },
              setup:function(){
                  return { 
                    buttons:[{text: "OK", key:27/*Esc*/ 
                            , className: alertify.defaults.theme.ok,}],
                    focus: { element:0 }
                  };
              },
              prepare:function(){
                this.setContent(this.message);
              },
              build:function(){
                        var errorHeader = '<span class="fa fa-check-circle fa-2x" '
                        +    'style="vertical-align:middle;color:#00e100;">'
                        + '</span> Sucesso';
                        this.setHeader(errorHeader);
                    }
          }});
        }
        
        //launch it.
        alertify.myAlert(message);

     }

    /*
        Mensagem de erro
    */
    printErrorMessage(message: string) {
        if(!alertify.errorAlert){
            alertify.dialog('errorAlert',function factory(){
            return{
                    build:function(){
                        let errorHeader = '<span class="fa fa-times-circle fa-2x" '
                        +    'style="vertical-align:middle;color:#e10000;">'
                        + '</span>'
                        this.setHeader(errorHeader);
                    },
                    prepare: function () {
                        const modalElement = this.elements.root.querySelector('.ajs-modal');
                        if (modalElement) {
                            modalElement.style.zIndex = '100001';
                        }
                    }
                };
            },true,'alert');
        }

        alertify
            .errorAlert(message + "<br/><br/><br/>");
    }
    
    /*
        Mensagem de Aviso
    */
   printWarningMessage(message: string) {
        if(!alertify.warningAlert){
            alertify.dialog('warningAlert', function factory(){
                return{
                    build: function(){
                        var warningHeader = '<span class="fa fa-exclamation fa-2x" '
                        +    'style="vertical-align:middle;color:#F3DB05;margin-right:15px;">'
                        + '</span>';
                        this.setHeader(warningHeader);
                    }
                };
            }, true, 'alert');
        }
        
        alertify
            .warningAlert(message + "<br/><br/><br/>");
   }
}
