import { Component, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import { SelectListColumnInterface } from "app/@core/interfaces/interfaceSelectListColumn";
import { Table, TableHeaderCheckbox } from "primeng/table";
import { CaptureService } from "app/@core/data/capture.service";

@Component({
    selector: 'select-list',
    styleUrls: ['./select-list.component.scss'],
    templateUrl: './select-list.component.html',
})
export class SelectListComponent {
    @Input('columns') columns: SelectListColumnInterface[] = [];
    @Input('data') data: any[] = [];
    @Input('rowsSelecteds') rowsSelecteds: any[] = [];

    @Output('onSelecteds') selecteds: EventEmitter<any[]> = new EventEmitter<any[]>();
    @Output('clickedButton') clickedButton: EventEmitter<any[]> = new EventEmitter<any[]>();

    @Input('loadingCaptures') loadingCaptures: boolean = false;
    
    @ViewChild("dt")
    private _table: Table;

    @ViewChild("headerCheckBox")
    private _headerCheckBox: TableHeaderCheckbox;

    lockedBtn: boolean = false;
    

    constructor(
      private captureService: CaptureService
    ){
      this.permissionFrequenceByAttempts();
    }

    onRowSelect(event) {
        this.selecteds.emit(this.rowsSelecteds);
    }

    isRowDisabled(data: any): boolean {
        //console.log(data);
        return !data.online;
    }

    onSelectionChange(selection: any[]) {
        for (let i = selection.length - 1; i >= 0; i--) {
          let data = selection[i];
          if (this.isRowDisabled(data)) {
            selection.splice(i, 1);
          }
        }
        this.rowsSelecteds = selection;
    }

    ngAfterViewInit(): void {
        const orig_updateCheckedState = this._headerCheckBox.updateCheckedState;
        const me = this;
        this._headerCheckBox.updateCheckedState = function () {
          const items: any[] = me._table.filteredValue || me._table.value;
          const selection: any[] = me._table.selection;
          for (const item of items) {
            if (!me.isRowDisabled(item)) {
              const selected = selection && selection.indexOf(item) >= 0;
              if (!selected) return false;
            }
          }
          return true;
        };
    }

    captureImages() {
      this.clickedButton.emit(this.rowsSelecteds.map(row => row.id));
      this.permissionFrequenceByAttempts();
      this.captureService.setDateCapture();
    }

    permissionFrequenceByAttempts(){
        if(this.captureService.itHasCapturePermition()){
          this.lockedBtn = false;
        }else{
          this.lockedBtn = true;
        }        
    }
}