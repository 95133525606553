export const mockWorkspaceData = [
{
    id: 1,
    currentUserId: 1,
    userCustomerChildIds: [1,2],
    selectedDrivers: []
},
{
    id: 2,
    currentUserId: 2,
    userCustomerChildIds: [1,2,3,4],
    selectedDrivers: []
},
{
    id: 3,
    currentUserId: 3,
    userCustomerChildIds: [1,2,3,4],
    selectedDrivers: []
},
];

