export const mockEquipmentData = [
    {
        id: 54, 
        assetId: 1, 
        type: 2, 
        name: "Equipamento 1", 
        customerName: "JSL", 
        customerChildName: "JSL Guaíba", 
        identification: "equipamento-1", 
        integration: "focus_54",
        status: 1, 
        devices: []
    },
    {
        id: 55, 
        assetId: 5, 
        type: 1, 
        name: "Equipamento 2", 
        customerName: "JSL", 
        customerChildName: "JSL PoA", 
        identification: "equipamento-2", 
        integration: "frotalog_23", 
        status: 2, 
        devices: []
    }
]