import {Component,Input, OnInit} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import {NotificationCenterService} from './notification-center.service';
import tts from 'google-tts-api';
import { GlobalState } from '../../../global.state';
import { CheckAlarmOptions } from '../../../@core/interfaces/interfaceAlarmOptions';
import { Observable } from 'rxjs';

@Component({
  selector: 'notification-center',
  providers: [NotificationCenterService],
  styleUrls: ['./notification-center.scss'],
  templateUrl: './notification-center.html'
})
export class NotificationCenterComponent implements OnInit {
  /**
   * Badge text to display
   * @type string
   */
  @Input() badgeText: string;

/**
   * Badge status (adds specific styles):
   * 'primary', 'info', 'success', 'warning', 'danger'
   * @param {string} val
   */
  @Input() badgeStatus: string;


  /**
   * Badge position.
   * Can be set to any class or to one of predefined positions:
   * 'top left', 'top right', 'bottom left', 'bottom right'
   * @type string
   */
  @Input() badgePosition: string;

  /**
   * Specifies a name to be shown on the right of a user picture
   * @type string
   */
  @Input() name: string = 'Anonymous';

  /**
   * Specifies a title (written in a smaller font) to be shown under the **name**
   * @type string
   */
  @Input() title: string;

  /**
   * Absolute path to a user picture. Or base64 image
   * User name initials (JD for John Doe) will be shown if no picture specified
   * @type string
   */
  @Input() set picture(value: string) {
    this.imageBackgroundStyle = value ? this.domSanitizer.bypassSecurityTrustStyle(`url(${value})`) : null;
  }

  
  checkAlarm: CheckAlarmOptions = <CheckAlarmOptions> {  
      isTiredChecked: true,
      isFaceMissingChecked: true,
      isAttenWarningChecked: true,
      isChangeWarningChecked: true,
      isLookAroundChecked: true,
      isPhoneWarningChecked: true
  };
  
  imageBackgroundStyle: SafeStyle;

  private showNameValue: boolean = true;
  private showTitleValue: boolean = true;

  public notifications:Array<Object>;
  public messages:Array<Object>;

 private isMenuShown: boolean = false; 
 private menu = ['teste1', 'teste2']; 
 public pendingCount = 0;

  constructor(private _baMsgCenterService:NotificationCenterService, 
    private domSanitizer: DomSanitizer, 
    private _state: GlobalState) {
    this.notifications = this._baMsgCenterService.getNotifications();
    this.messages = this._baMsgCenterService.getMessages();

    this.getAutoCloseAlertToast();
    this.getHideLowAlertToast();
    this.getHideMediumAlertToast();
    this.getHideHighAlertToast()
    this.getSoundVoice();

  }

  public ngOnInit(): void {
    this.subscribeCountPending();   
  }

  public subscribeCountPending() {
    this._state.subscribe('painel.setCountPendingValue', (value) => {      
           this.pendingCount = value;
    });  
  }
  
   public toggleMenu() {
        this.isMenuShown = !this.isMenuShown;
    }; 


   public hasMenu()  {
        return this.menu && this.menu.length > 0;
   }; 

  public checkNewAlarm() {
    this._state.notifyDataChanged('painel.setCheckNewAlarm', this.checkAlarm);
  }

  private online($event, message) {
    this.checkNewAlarm();
    //console.info($event);
    var y = window.speechSynthesis;
    if (!y) {
      return console.warn('Your browser does not support `speechSynthesis` yet.');
    }
    
    if (!message) return;

    var s = new SpeechSynthesisUtterance(message);
    
    //s.voice = y.getVoices()[1 || 0];
    s.lang = localStorage.getItem('lang');
    y.speak(s);

  };




  hideLowAlertToast(event){    
    this._baMsgCenterService.hideLowAlertToast(event);
  }

  getHideLowAlertToast(){
    return this._baMsgCenterService.getHideLowAlertToast();
  }

  hideMediumAlertToast(event){    
    this._baMsgCenterService.hideMediumAlertToast(event);
  }

  getHideMediumAlertToast(){
    return this._baMsgCenterService.getHideMediumAlertToast();
  }

  hideHighAlertToast(event){    
    this._baMsgCenterService.hideHighAlertToast(event);
  }

  getHideHighAlertToast(){
    return this._baMsgCenterService.getHideHighAlertToast();
  }

  autoCloseAlertToast(event){
    this._baMsgCenterService.autoCloseAlertToast(event);
  }

  getAutoCloseAlertToast(){
    return this._baMsgCenterService.getAutoCloseAlertToast();
  }

  getSoundVoice(){
    return this._baMsgCenterService.getSoundVoice();
  }

  soundVoice(event){
    this._baMsgCenterService.soundVoice(event);
  }
}
