export const mockAssetsData =[
  {
    "id": 1,
    "name": "BBM1234",
    "customerChildId": 1,
    "identification": "BBM1234",
    "integration": "FROTALOG-VEHICLES-0001",
    "status": 1,
    "type": 2,
    "picture": null,  },
  {
    "id": 2,
    "name": "JSL0012",
    "customerChildId": 2,
    "identification": "JSL0012",
    "integration": "FROTALOG-VEHICLES-0002",
    "status": 2,
    "type": 2,
    "picture": null,  },
  {
    "id": 3,
    "name": "JSL0013",
    "customerChildId": 1,
    "identification": "JSL0013",
    "integration": "FROTALOG-VEHICLES-0003",
    "status": 2,
    "type": 2,
    "picture": null,  },
  {
    "id": 4,
    "name": "JSL0014",
    "customerChildId": 1,
    "identification": "JSL0014",
    "integration": "FROTALOG-VEHICLES-0004",
    "status": 2,
    "type": 2,
    "picture": null,  },
  {
    "id": 5,
    "name": "JSL0015",
    "customerChildId": 1,
    "identification": "JSL0015",
    "integration": "FROTALOG-VEHICLES-0005",
    "status": 1,
    "type": 2,
    "picture": null,  }
];

