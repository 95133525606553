import { Pipe, PipeTransform } from "@angular/core";

@Pipe({name: "truncate"})
export class Truncate implements PipeTransform {
    transform(value: any, param: any): string {
        let truncatedValue: string = "";
        let stringValue = new String(value);
        truncatedValue = stringValue.substring(0, stringValue.indexOf(".") + param);
        return truncatedValue;
    }
}