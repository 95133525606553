import { Component, Input, Output, ViewChild, EventEmitter } from "@angular/core";
import { TranslateJsonService } from "app/@core/data/translate-json.service";
import { User } from "app/@core/models";
import { NotificationUtilService } from "app/@core/utils/notification.util.service";
import { environment } from "environments/environment";
import { ModalDirective } from "ngx-bootstrap";
import { EventModel } from "../models/event.model";
import { TelemetryService } from "../telemetry.service";
import { TelemetryState } from "../telemetry.state";

@Component({
    selector: 'popups-telemetry',
    styleUrls: ['./popups-events.component.scss'],
    templateUrl: './popups-events.component.html',
})
export class PopUpsTelemetryComponent {
    @ViewChild('actionModal') public actionModal: ModalDirective;

    @Input('events') events: EventModel[] = [];

    @Output('clickLocation') clickLocation: EventEmitter<{ lat: number, lng: number}> = new EventEmitter();

    public selectedEvent: EventModel;
    public user: User;
    public action: { emails: string, details: string } = { emails: `${environment.emailEvents};`, details: '' };
    public emailEvents = {
        DRIVER_HERO: "dhc@goawake.com;",
        WEARABLE: "gowatch@goawake.com;",
        SPOTXB: "spotx@goawake.com;"
    };

    constructor(
        private telemetryState: TelemetryState,
        private telemetryService: TelemetryService,
        private notificationUtilService: NotificationUtilService,
        private translateJsonService: TranslateJsonService
    ){
        this.user = JSON.parse(localStorage.getItem('currentUser'));

        this.telemetryState.getEvents$().subscribe(events => {
            this.events = [
                ...events.map(e => { return { 
                    _id: e._id, 
                    opened: false, 
                    sos: e.type == '911', 
                    type: e.type, 
                    typeLabel: e.typeLabel,
                    datetime: e.datetime, 
                    details: e.details, 
                    location: e.location, 
                    created_at: e.created_at, 
                    customer_child_id: e.customer_child_id, 
                    device_id: e.device_id,
                    device_type: e.device_type, 
                    timezone: e.timezone, 
                    vehicle: e.vehicle, 
                    driver: e.driver
                } })
            ]
        })
    }

    applyActionInEvent(event) {
        this.action.emails = event.device_type ? this.emailEvents[event.device_type] : "";
        this.selectedEvent = event;
        this.actionModal.show();
    }

    saveAndSendEmail() {
        const update = {
            "id": this.selectedEvent._id,
            "read": {
                    "whoRead": {
                        "id": this.user.id,
                        "name": this.user.name
                    },
                    "action": this.action.details,
                    "emails": this.action.emails
            }
        }

        this.telemetryService.saveAction(update)
            .then(result => { 
                this.notificationUtilService.printSuccessMessage(this.translateJsonService.getTranslateSubs('TELEMETRY-PAGE.operation_performed_successfully')); 
                const events = this.telemetryState.getEvents();
                this.telemetryState.updateEvents(events.filter(e => e._id != this.selectedEvent._id ));
                this.actionModal.hide(); 
            })
            .catch(err => {
                this.notificationUtilService.printErrorMessage(this.translateJsonService.getTranslateSubs('TELEMETRY-PAGE.an_error_occurred_while_performing_the_operation'));
                console.log(err);
            });
    }

    clickedLocation(lat: number, lng: number) {
        this.clickLocation.emit({ lat, lng })
    }

}