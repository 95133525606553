import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, SimpleChanges } from '@angular/core';
import { TreeInputModel } from '../../../@core/models/treeInputModel.entity';
import { TreeNode, ITreeOptions } from '../../../../../node_modules/angular-tree-component/dist/defs/api';
import { DataService } from '../../../@core/data/data.service';
import { TranslateJsonService } from 'app/@core/data/translate-json.service';
import { Workspace } from 'app/@core/models/workspace.data.entity';

@Component({
    selector: 'focus-tree-input',
    styleUrls: ['./treeInput.component.scss'],
    templateUrl: './treeInput.component.html',
})
export class TreeInputComponent implements OnDestroy, OnInit {
    @ViewChild('treeContent') treeContent: TreeNode;
    @ViewChild('overlay') overlay;

    // Properts
    @Input('selectedNodeName') selectedNodeName: string;
    @Input('contentNodes') contentNodes: TreeInputModel[] = [];
    @Input('selectedNodes') selectedNodes: number[] = [];
    @Input('contentName') contentName: string = "Conteúdo";
    @Input('overlayPanelWidth') overlayPanelWidth: string = '450px';
    @Input('overlayPanelHeight') overlayPanelHeight: string = '300px';
    @Input('checkBox') checkBox: boolean = false;
    @Input('triStateChk') triStateChk: boolean = false;
    @Input('multChk') multChk: boolean = false;
    @Input('allChidren') allChidren: boolean = false;
    @Input('treeMode') treeMode: boolean = true;
    @Input('loadedContent') set loadedContent(value : boolean){
        
        this.displayContent = false;
        if(value && this.treeMode){
            setTimeout(()=> {
                if(this.isTripsPage) {
                    this.verifyCustomerProfileIdSelectionForTripsPage()
                }
            })
            this.sortCustomersOrderByName(this.contentNodes);           
            this.allContent = this.convertTreeContent(this.contentNodes);
            this.displayContent = true;
        }
        if(value && !this.treeMode){
            this.displayContent = true;
        }
    }
    @Input('pendingContent') set pendingContent(value : boolean){
        this.lockedContent = true
        if(!value){
            this.lockedContent = false;
        }else{
            this.lockedContent = true;
        }
    }
    @Input() isTelemetryPage: boolean = false;
    @Input('isDisabled') isDisabled: boolean = false;
    @Input('showSelectAll') showSelectAll: boolean = false
    @Input('isTripsPage') isTripsPage: boolean  = false

    // Events
    @Output('changedNodes') changedNodes: EventEmitter<number[]> = new EventEmitter();
    @Output('changedNodeInfo') changedNodeInfo: EventEmitter<any[]> = new EventEmitter();
    @Output('submitCustomerEmit') submitCustomerEmit: EventEmitter<any> = new EventEmitter()

    // Sem uso
    @Input('selectedContent') selectedContent: number;

    public allContent: TreeNode[];
    public options: ITreeOptions = { useCheckbox: this.checkBox };
    public displayContent: boolean = false;
    public lockedContent: boolean = true;

    paramTranslateFilter = {value: this.contentName};

    checkDefaultCustomer

    constructor(
        private dataService: DataService,
        protected translateJsonService: TranslateJsonService
    ){

    }

    ngOnInit(){
        this.showSelectAll = false
    }

    
    ngOnDestroy(){}

    private sortCustomersOrderByName(customers: TreeInputModel[]) {
        customers.sort(function (a, b) {	
            return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);                 
        });
    }

    /**
     * Desseleciona todos os Nodes ativos em Arvores
     */
    public clearAllSelectedNodes(){
        this.treeContent.treeModel.setActiveNode(false, false);
        this.selectedNodes=[];
        this.changedNodes.emit([]);
        this.changedNodeInfo.emit([]);
    }

    /**
     * Desseleciona todos os ITENS ativos em TABELAS
     */
    public clearAllSelectedItens(){
        this.selectedNodes = [];
        this.changedNodes.emit([]);
        this.changedNodeInfo.emit([]);
    }

    preSelectedNodes() {
        this.selectedNodes = []
        this.contentNodes.forEach(node => {
            if(node.parentId && node.parentId == this.checkDefaultCustomer) {
                this.selectedNodes.push(node.id)                
            } else {
                if(node.id === this.checkDefaultCustomer) {
                    this.selectedNodes.push(node.id)
                }
            }

        })
        this.activeSelectedNodes()
        this.submitCustomerEmit.emit(this.selectedNodes)
    }

    public selectAllItems() {
        this.selectedNodes = [];
        this.contentNodes.forEach(node => {
            this.selectedNodes.push(node.id)
        })
    }

    public checkUncheckAll(event) {
        event.target.checked ? this.selectAllItems() : this.clearAllSelectedItens()
    }

    /**
     * Recebe todos itens do componente pai, verifica os que são Node Raiz,
     * e todos os respectivos Node Filhos.
     * @param _content Recebe uma lista de TreeInputModel 
     */
    public convertTreeContent(_content: TreeInputModel[]): TreeNode[] {
        let result: TreeNode[] = [];
  
        _content.forEach((content : TreeInputModel) => {
          if(content.parentId == null){
            result.push(this.buildNode(content, _content));
          }else{
            let parent = _content.find((parentChild : TreeInputModel) => {
                if(parentChild.id == content.parentId){
                    return true
                }else{
                    return false
                }
            });
            
            if(!parent){
              result.push(this.buildNode(content, _content));
            }
          }
        })
  
        return result;
    }

    /**
     * Seleciona automatica os Nodes pré definidos.
     */
    public activeSelectedNodes(){
        if(this.selectedNodes){
            this.selectedNodes.forEach((nodeId : number) => {
                if(this.treeContent){
                    let selectedNode: TreeNode = this.treeContent.treeModel.getNodeById(nodeId);
                    if(selectedNode){
                        selectedNode.treeModel.setActiveNode(selectedNode, true, true);                    
                    }
                }                
            })
        }
    }

    /**
     * Monta o Node no formato TreeNode, além de pesquisar pelos seus respectivos nodes filhos.
     * @param content Node a ser construído
     * @param _content Restante da Arvore para verificar seus filhos.
     */
    public buildNode(content : TreeInputModel, _content : TreeInputModel[]){
        return {id: content.id, name: content.name, children: this.searchChilds(content, _content)};
    }

    /**
     * Recebe um Content e verifica dentro da lista todos os filhos do mesmo.
     * Função Recursiva
     * @param content Node Raiz
     * @param _content Restante da Arvore para verificar seus filhos.
     */
    public searchChilds(content : TreeInputModel, _content : TreeInputModel[]){
        let tree: any[] = [];
        let parentList: TreeInputModel[] = _content.filter((_content_ : TreeInputModel) => _content_.parentId == content.id);

        if(parentList.length > 0){
            parentList.forEach((parentContentChild : TreeInputModel) => {
                tree.push(this.buildNode(parentContentChild, _content));
            })
        }

        return tree;
    }

    /*
    * Ação chamada sempre que um Node (Filial) é selecionado na Tree.
    * Recupera todas as Nodes da Tree.
    */
    public selectedNode(node){
       return this.selectedNodes = node.treeModel.getActiveNodes().map((node) => node.data.id);
    }

    public checkedTable(event, nodeId: number){
        if(!this.selectedNodes) this.selectedNodes = [];

        if(event.target.checked){
            this.selectedNodes.push(nodeId);
        }else{
            let i = this.selectedNodes.indexOf(nodeId);
            this.selectedNodes.splice(i, 1);
        }

        this.changedNodes.emit(this.selectedNodes);
    }

    public checkingOnlyOneNode(node) {
        this.selectedNodeName = node.name;
        this.selectedNodes = [];
        this.selectedNodes.push(node.id);
        this.changedNodes.emit([node.id]);
        this.changedNodeInfo.emit([node]);
        this.overlay.hide();
    }

    public verifyCheckedTable(nodeId: number){
        if(this.selectedNodes){
            if(this.selectedNodes.find((id) => id == nodeId)){
                return true;
            }else{
                return false;
            }
        }else{
            return false;
        }
    }

    verifyCustomerProfileIdSelectionForTripsPage() {
        let ws: Workspace = this.dataService.getWorkspace()
        this.checkDefaultCustomer = ws.currentUser.customerChild
        this.preSelectedNodes()
    }

    /**
     * Os códigos abaixo realizam a função de fazer o 'tri-state' do checkbox da Árvore de Empresas
     * e do multiselect
     * @param node 
     * @param $event 
     */
    public checked(node, $event) {
        if(this.multChk){
            if(this.isTelemetryPage && node.parent.parent == null){
                this.treeContent.treeModel.collapseAll();
                this.clearAllSelectedNodes()
            }
            this.updateChildNodesCheckBox(node, $event.target.checked);
            this.changedNodes.emit(this.selectedNode(node));
            if(this.triStateChk){
                this.updateChildNodesCheckBox(node, $event.target.checked);
                this.updateParentNodesCheckBox(node.parent);
                this.changedNodes.emit(this.selectedNode(node));
            } else {
                node.treeModel.setActiveNode(node, $event.target.checked, true);
                this.changedNodes.emit(this.selectedNode(node));
            }
        } else {
            node.treeModel.setActiveNode(node, $event.target.checked, true);
            this.changedNodes.emit(this.selectedNode(node));
    
            if (!node.hasChildren && !$event.target.checked) {
                node.treeModel.setActiveNode(node, false, true);
                $event.target.checked = false;
                if (this.selectedNode(node).length < 1) {
                    this.changedNodes.emit([]);
                }
            }
        }
    }
    
    public updateChildNodesCheckBox(node, checked) {
        node.treeModel.setActiveNode(node, checked, true);
        if (node.children) {
            node.children.forEach((child) => this.updateChildNodesCheckBox(child, checked));
        }
    }

    public updateParentNodesCheckBox(node) {
        if (node && node.level > 0 && node.children) {
            let allChildChecked = true;
            let noChildChecked = true;
            for (let child of node.children) {
                if (!child.isActive) {
                    allChildChecked = false;
                } else if (child.isActive) {
                    noChildChecked = false;
                }
            }
            
            if (allChildChecked) {
                node.treeModel.setActiveNode(node, true, true);
                node.data.indeterminate = false;
            } else if (noChildChecked) {
                node.treeModel.setActiveNode(node, false, true);
                node.data.indeterminate = false;
            } else {
                node.treeModel.setActiveNode(node, true, true);
                node.data.indeterminate = true;
            }

            this.updateParentNodesCheckBox(node.parent);
        }
    }

    public showPanel() {
        this.overlay.show();
    }

    
  public filterTree(name: string)
  {
    if(name.length>0)
      this.treeContent.treeModel.filterNodes(name);
    else
      this.treeContent.treeModel.collapseAll();
  }

}
