import {Status} from '../enum/status.enum';

export class User {	
	/* constructor(id: number,
              email: string,
              password: string,
              fullName: string) {
		this.id = id;
		this.email = email;
		this.name = fullName;
		this.password = password;
  	}  */

    public id: number;
    public name: string;
    public email: string;
    public password: string;
    public senha: string;
    public customerName: string;
    public customerChild: number;
    public customerChildDesc: string;
    public customer?: number;
    public identification: string;     
    public integration: string;
    public picture: string;
    public icon: string;
    public status: Status;
    public token: string;
    public login: string;
}


