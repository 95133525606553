import {Status} from '../enum/status.enum';

export class Driver {
     public id: number;
     public name: string;
     public customerChildId: number;
     public identification: string;
     public integration: string;
     public picture?: string;
     public icon?: string;
     public status: Status;

     public hasFaceRecog?: boolean;
}

